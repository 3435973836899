import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { OperatorInstance } from '../../../models/operator.model';
import { DeviceInstance } from '../../../models/device.model';
import { TranslateService } from '@ngx-translate/core';

interface SelectEntry {
  id: number;
  val: string;
}


@Component({
  selector: 'app-operator-drawer',
  templateUrl: './operator-drawer.component.html',
  styleUrls: ['./operator-drawer.component.scss']
})
export class OperatorDrawerComponent implements OnInit {

  @Input() operator?: OperatorInstance;
  @Input() devices: DeviceInstance[];
  @Input() operators: OperatorInstance[];
  @Input() isEditing: boolean;

  tmpDevices: SelectEntry[] = [];

  form: FormGroup;

  customValidationDict = {
    required: 'common.inputErrors.required',
    min: 'common.inputErrors.min'
  };

  constructor(private readonly _fb: FormBuilder, private readonly _translate: TranslateService) {
    this.form = this._fb.group({
      id: [null],
      name: [null, [Validators.required]],
      surname: [null, [Validators.required]],
      code: [null, [Validators.required]],
      devices: [null, [Validators.required]],
    });
  }

  get f() {
    return this.form.controls;
  }

  get formValid(): boolean {
    return this.form.valid;
  }

  ngOnInit(): void {
    this.tmpDevices = this.devices.map((device) => this.mapDeviceToUi(device));

    if (this.operator) {
      this.form.reset();
      this.updateForm(this.operator);
    }
  }

  onSubmit() {

    if (this.form.invalid) {
      return;
    }

    const new_operator: OperatorInstance = {
      id: this.form.value.id,
      name: this.form.value.name,
      surname: this.form.value.surname,
      code: this.form.value.code,
      Devices: this.form.value.devices.map((entry: SelectEntry) => {
        return this.devices.find((device) => device.id === entry.id);
      })
    };


    this.tmpDevices = this.devices.map((device) => this.mapDeviceToUi(device));

    return {
      operator: new_operator,
      isEditing: this.isEditing ?? false
    }
  }

  private updateForm(operator?: OperatorInstance) {

    // Devices selected in this order
    const hasDevice = (id: number) => {
      return operator?.Devices?.find((device) => device.id === id) !== undefined;
    };

    const selectedDevices = this.tmpDevices.filter((entry: SelectEntry) => hasDevice(entry.id));

    this.form.patchValue({
      id: operator?.id,
      name: operator?.name,
      surname: operator?.surname,
      code: operator?.code,
      devices: selectedDevices
    });
  }

  private mapDeviceToUi(device?: DeviceInstance): SelectEntry {
    if (device) {
      return {
        id: device.id,
        val: device.label
      };
    } else {
      return null;
    }
  }

}
