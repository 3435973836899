import { Component, Input, OnInit } from '@angular/core';
import { ProcessDataInstance, ValueType } from '../../../models/process-data.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface SelectEntry {
  id: number;
  val: string;
}

@Component({
  selector: 'app-process-data-drawer',
  templateUrl: './process-data-drawer.component.html',
  styleUrls: ['./process-data-drawer.component.scss']
})
export class ProcessDataDrawerComponent implements OnInit {

  @Input() processData?: ProcessDataInstance;
  @Input() valueTypes: {
    id: number;
    name: string;
    value: any
  }[];

  types: SelectEntry[] = [];
  selectedType: {
    id: number;
    name: string;
    value: any
  };

  form: FormGroup;

  customValidationDict = {
    required: 'common.inputErrors.required',
    min: 'common.inputErrors.min'
  };

  constructor(private readonly _fb: FormBuilder) {
    this.form = this._fb.group({
      code: [null, [Validators.required]],
      description: [null, [Validators.required]],
      valueType: [null]
    })
  }

  get formValid(): boolean {
    return this.form.valid;
  }

  ngOnInit(): void {
    if (this.processData) {
      this.form.reset();
      this.updateForm(this.processData)
    }
  }

  onSubmit() {
    if (!this.formValid) {
      return
    }

    const data = {
      ...this.form.value,
      valueType: this.form.value.valueType?.id ?? 0
    }

    if (this.processData) {
      data['id'] = this.processData.id
    }

    return data
  }

  private updateForm(processData: ProcessDataInstance) {
    this.form.patchValue({
      code: processData.code,
      description: processData.description,
      valueType: processData.valueType,
    });

    this.selectedType = this.valueTypes.find((type) => type.id === processData.valueType);
  }
}
