import { ViewEncapsulation, Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceInstance } from '../../../models/device.model';
import { DeviceService } from '../../../services/device.service';
import { PluginService } from '../../../services/plugin.service';
import { PluginInstance } from '../../../models/plugin.model';
import { PageWithLoader } from "../page-with-loader";
import { ThemeService } from "../../../services/theme.service";
import { PluginDrawerComponent } from '../../ui/plugin-drawer/plugin-drawer.component';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { Drawer, FontService, NotificationConfig, NotificationService } from '@vapor/angular-ui';
import { DrawerInstanceInfo } from '@vapor/angular-ui/drawer/drawer-data.model';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';


@Component({
    selector: 'app-plugin',
    templateUrl: './plugin.component.html',
    styleUrls: ['./plugin.component.scss'],
    providers: [
        NotificationService
    ],
    encapsulation: ViewEncapsulation.None
})

export class PluginComponent extends PageWithLoader implements OnInit {
    lockRequest = false;
    devices: DeviceInstance[];
    selectedDevice: DeviceInstance;
    selectedPlugin: PluginInstance;
    columns: TsTreeListColumn[] = [];
    plugins: PluginInstance[] = [];
    tmpPlugins: PluginInstance[] = [];

    pluginListHeight = '100vh';

    @ViewChild('pluginList', { static: false, read: ElementRef }) pluginListRef!: ElementRef;
    @ViewChild('editPluginTemplate', { static: false }) editPluginTemplate: TemplateRef<any>;
    @ViewChild('newPluginTemplate', { static: false }) newPluginTemplate: TemplateRef<any>;
    @ViewChild('pluginDrawerBottomTemplate', { static: false }) pluginDrawerBottomTemplate: TemplateRef<any>;
    @ViewChild('pluginDrawer', { static: false }) pluginDrawer: PluginDrawerComponent;


    constructor(
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _translate: TranslateService,
        private _device: DeviceService,
        private _drawer: Drawer,
        private _plugin: PluginService,
        private _notification: NotificationService,
        private _font: FontService,
        _themeService: ThemeService
    ) {
        super(_themeService);
        this._font.addIcon(faPencil);
    }

    async ngOnInit(): Promise<void> {
        this.lockRequest = true;

        this._translate.stream([
            'plugin.title',
            'plugin.columns.code',
            'plugin.columns.description',
            'plugin.columns.enabled',
            'plugin.columns.isOnSatellite'
        ]).subscribe((translations) => {
            this._navbar.setTitle(translations['plugin.title']);

            setTimeout(() => this._sidebar.setSelected('plugin'));

            const defaultColumn: TsTreeListColumn = {
                dataField: '',
                allowFiltering: false,
                allowEditing: false,
                allowSorting: false,
                alignment: 'left',
                width: '100%'
            };

            this.columns = [{
                ...defaultColumn,
                dataField: 'code',
                dataType: 'string',
                caption: translations['plugin.columns.code'],
            }, {
                ...defaultColumn,
                dataField: 'description',
                dataType: 'string',
                caption: translations['plugin.columns.description'],
            }, {
                ...defaultColumn,
                dataField: 'PluginsDevices.enabled',
                dataType: 'boolean',
                caption: translations['plugin.columns.enabled'],
            }, {
                ...defaultColumn,
                dataField: 'PluginsDevices.isOnSatellite',
                dataType: 'boolean',
                caption: translations['plugin.columns.isOnSatellite'],
            },
            {
                ...defaultColumn,
                caption: '',
                width: 135,
                allowFiltering: false,
                allowSorting: false,
                cellTemplate: 'tplActions',
                fixedPosition: 'right',
                alignment: 'center',
            }];
        });

        const res = await this._device.getDevices();

        this.devices = res.map(d => ({
            ...d,
            customLabel: `${d.label} ${d.Company && '(' + d.Company?.name + ')'}`
        }))

        if (this.devices.length > 0) {
            if (localStorage.getItem('deviceId')) {
                const device = this.devices.find((item) => item.id === Number(localStorage.getItem('deviceId')));
                if (device) {
                    this.selectedDevice = device;
                }
            } else {
                this.selectedDevice = this.devices[0];
            }
            this.deviceChanged(this.selectedDevice);
        }
    }

    ngAfterContentChecked(): void {
        // We do it here because the ngSwitch has already been evaluated
        if (this.pluginListRef) {
            const offsetTop = this.pluginListRef.nativeElement.offsetTop;
            this.pluginListHeight = `calc(100vh - ${offsetTop}px)`;
        }
    }


    async deviceChanged(device: DeviceInstance) {

        this.selectedDevice = device;

        this.plugins = [];
        this.tmpPlugins = [];

        localStorage.setItem('deviceId', this.selectedDevice.id.toString());

        const plugins = await this._plugin.getPlugins(this.selectedDevice.id);

        for (const p of plugins) {
            if (!p.PluginsDevices) {
                p.PluginsDevices = {
                    deviceId: this.selectedDevice.id,
                    pluginId: p.id,
                    enabled: false,
                    isOnSatellite: false
                };
            }
        }

        this.plugins = plugins;
        this.tmpPlugins = JSON.parse(JSON.stringify(this.plugins));
        this.lockRequest = false;
    }

    async createOrUpdatePlugin(plugin?: PluginInstance) {

        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        if (plugin) {
            this.selectedPlugin = plugin;
        }

        this._drawer.open({
            title: this._translate.instant('stopReasons.title'),
            subTitle: '',
            expanded: false,
            marginTop: 0,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: plugin ? this.editPluginTemplate : this.newPluginTemplate,
            bottomBarTemplate: this.pluginDrawerBottomTemplate,
        }).subscribe((drawer: DrawerInstanceInfo) => {
            console.info(`New Error drawer open: ${drawer.drawerId}`);
            this._drawer.expand();
        });
    }

    async savePlugin(data: PluginInstance) {

        let isOk = false;

        try {
            if (data.id) {
                const plugin: PluginInstance = await this._plugin.updatePlugin(this.selectedDevice.id, data);
                isOk = plugin ? true : false;
                if (isOk) {
                    const idx = this.plugins.findIndex(p => p.id === data.id);
                    this.plugins[idx] = plugin;
                    this.tmpPlugins = JSON.parse(JSON.stringify(this.plugins));
                }
            } else {
                this.plugins.shift();
                const plugin: PluginInstance = await this._plugin.createPlugin(this.selectedDevice.id, data);
                isOk = plugin ? true : false;
                if (isOk) {
                    this.plugins.push(plugin);
                    this.tmpPlugins = JSON.parse(JSON.stringify(this.plugins));
                }
            }

            if (isOk) {
                this.showNotification(this._translate.instant('plugin.saved-success'), 'check');
            } else {
                this.showNotification(this._translate.instant('plugin.saved-error'), 'error');
            }
        } catch (error) {
            console.log(error)
            this.showNotification(this._translate.instant('plugin.saved-error'), 'error');

        } finally {
            this._drawer.close();
        }

    }

    onFormSubmit() {
        const res = this.pluginDrawer.onSubmit();
        const { enabled, isOnSatellite, ...restData } = res;

        const plugin_data = {
            ...restData,
            PluginsDevices: {
                deviceId: this.selectedDevice.id,
                enabled: enabled,
                isOnSatellite: isOnSatellite
            }
        }

        this.savePlugin(plugin_data);
    }

    onCancelDrawerClicked() {
        this._drawer.close();
    }

    private showNotification(message: string, style: 'alert' | 'check' | 'error' | 'generic'): void {
        const config: NotificationConfig = {
            content: message,
            type: 'toast',
            style: style,
            timeout: 5000,
            position: 'right',
        }

        this._notification.show(config);
    }
}
