<ts-layout mode="flex" class="selectWrapper" tsFxLayout="column" tsFxLayoutAlign="start start" gap="0px 0px"
           padding="0px 0px">
    <ts-layout-item>
        <ts-h4 weight="600">{{'operators.title' | translate}}</ts-h4>
    </ts-layout-item>
</ts-layout>

<ts-layout class="dataWrapper" mode="flex" tsFxLayout="column" tsFxLayoutAlign="start start" gap="0px 0px">
    <ts-layout-item class="centralInfo" padding="0px 0px">
        <ts-layout mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start" gap="32px 0px" padding="0px 0px">
            <ts-layout-item class="infoItem">
                <ts-sub-rg class="ts-input-container__header__primary__text">{{'users.columns.firstName' | translate}}</ts-sub-rg>
                <span>{{operator.name}}</span>
            </ts-layout-item>

            <ts-layout-item class="infoItem">
                <ts-sub-rg class="ts-input-container__header__primary__text">{{'users.columns.lastName' | translate}}</ts-sub-rg>
                <span>{{operator.surname}}</span>
            </ts-layout-item>
        </ts-layout>
        <ts-layout mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start" gap="32px 0px" padding="0px 0px">
            <ts-layout-item class="infoItem" *ngIf="operator?.code">
                <ts-sub-rg class="ts-input-container__header__primary__text">{{'orders-list.columns.code' | translate}}</ts-sub-rg>
                <span>{{operator.code}}</span>
            </ts-layout-item>

            <ts-layout-item class="infoItem infoItem-devices" *ngIf="operator?.Devices?.length">
                <ts-sub-rg>{{'orders-list.columns.devices' | translate}}</ts-sub-rg>
                <div *ngFor="let device of operator.Devices">
                    <span>{{device.label}}</span>
                </div>
            </ts-layout-item>
        </ts-layout>
    </ts-layout-item>
</ts-layout>