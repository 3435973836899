import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FontService } from '@vapor/angular-ui';
import { faPlusCircle, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Subscription } from 'rxjs';

import { OrderStatus } from '../../../models/order-device.model';
import { OrdersListService } from '../../../services/ordersList.service';
import { OrderListInterface } from '../../../models/orders-groups-view.model';
import { OrderGroupsInstance, QuantityDistribution, TimeDistribution } from '../../../models/order-group.model';
import { OrderCoreInstance } from '../../../models/order-core.model';

interface SelectEntry {
    id: number;
    val: string;
}

interface JsonInfo {
    key: string;
    value: string;
}

@Component({
    selector: 'app-orders-group-editor',
    templateUrl: './orders-group-editor.component.html',
    styleUrls: ['./orders-group-editor.component.scss']
})
export class OrdersGroupEditorComponent implements OnInit, OnChanges {

    @Input() selectedOrders?: OrderListInterface[];
    @Input() group?: OrderListInterface;
    @Input() isEditing?: boolean;

    tabs = [];
    selectedTabId: number = 1;

    statuses = [];

    additionalInfoOpen = true;

    form: FormGroup;

    quantityDistributionItems = [];
    timeDistributionItems = [];

    quantityDistributionValue = -1;
    timeDistributionValue = -1;

    target: number = 0;

    private _subscriptions: Subscription[] = [];

    constructor(
        private readonly _translate: TranslateService,
        private readonly _font: FontService,
        private readonly _fb: FormBuilder,
        private readonly _ordersList: OrdersListService,
    ) {
        this._font.addIcon(faPlusCircle, faTrash);

        this.form = this._fb.group({
            status: [null, [Validators.required]],
            code: [null, [Validators.required]],
            date: [null, [Validators.required]],
            quantityDistribution: [null, [Validators.required]],
            timeDistribution: [null, [Validators.required]],
            jsonInfoArray: this._fb.array([]),
        });
    }

    get formValid(): boolean {
        return this.form.valid;
    }

    get f() {
        return this.form.controls;
    }

    get jsonInfoArray(): FormArray {
        return this.form.get('jsonInfoArray') as FormArray;
    }

    ngOnInit(): void {
        const translationSubscription =
            this._translate.stream([
                'orders-list.data',
                'orders-list.orders',
                'orders-list.status.draft',
                'orders-list.status.planned',
                'orders-list.distribution.equal',
                'orders-list.distribution.proportional',
                'orders-list.distribution.fill',
                'orders-list.distribution.none',
            ]).subscribe((translations) => {
                this.tabs = [{
                    id: 1,
                    text: translations['orders-list.data'],
                    disabled: false,
                }, {
                    id: 2,
                    text: translations['orders-list.orders'],
                    disabled: false,
                }];

                this.statuses = [
                    {
                        id: OrderStatus.draft,
                        val: translations['orders-list.status.draft'],
                    },
                    {
                        id: OrderStatus.planned,
                        val: translations['orders-list.status.planned'],
                    },
                ];

                this.quantityDistributionItems = [
                    translations['orders-list.distribution.equal'],
                    translations['orders-list.distribution.proportional'],
                    translations['orders-list.distribution.fill'],
                    translations['orders-list.distribution.none'],
                ];
                this.timeDistributionItems = [
                    translations['orders-list.distribution.equal'],
                    translations['orders-list.distribution.proportional'],
                ];
            });
        this._subscriptions.push(translationSubscription);

        if (this.group) {
            this.reset();
            this.updateForm(this.group);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedOrders?.currentValue) {
            const selectedOrders = changes.selectedOrders?.currentValue as OrderListInterface[];
            if (selectedOrders.length > 0) {
                // Use the code and date of the first order selected by default,
                // but respect the value entered by the user
                if (!this.form.get('code').dirty) {
                    this.form.patchValue({ code: selectedOrders[0].code });
                }
                if (!this.form.get('date').dirty) {
                    this.form.patchValue({ date: selectedOrders[0].date });
                }

                // Calculate the production target
                this.target = selectedOrders.reduce((accumulator: number, order: OrderListInterface) => {
                    return accumulator + order.target;
                }, 0);
            }
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe all subscriptions to avoid memory leaks
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
            
        });
    }

    reset() {
        this.form.reset();
        this.jsonInfoArray.clear();
        this.selectedTabId = 1;
        this.additionalInfoOpen = true;
    }

    updateForm(group?: OrderListInterface) {
        this.form.patchValue({
            status: this.mapStatusToUi(group?.status),
            code: group?.code,
            date: group?.date,
            quantityDistribution: String((group?.quantityDistribution as number) - 1),
            timeDistribution: String((group?.timeDistribution as number) - 1),
            jsonInfoArray: [],
        });

        this.setJsonInfo(this.createJsonInfoArray(group));

        this.target = group?.target;

        this.selectedOrders =
            group?.Orders?.map((currentOrder: OrderCoreInstance) => {
                return {
                    ...currentOrder,
                    isGroup: false,
                    date: currentOrder.deliveryDate,
                } as OrderListInterface;
            });
    }

    addNewJsonInfo() {
        const entryGroup = this._fb.group({
            key: '',
            value: '',
        });
        this.jsonInfoArray.push(entryGroup);
    }

    deleteJsonInfo(index: number) {
        if (!this.jsonInfoArray.length) {
            return;
        }
        this.jsonInfoArray.removeAt(index);
    }

    onAdditionalInfoToggle(open: boolean) {
        this.additionalInfoOpen = open;
    }

    concatDevices(devices: any[]) {
        return devices.reduce((previous, current) => previous + '\n\r' + current.label, '');
    }

    async create(): Promise<OrderGroupsInstance> {
        if (this.form.valid) {
            const plantId = Number(localStorage.getItem('plantId'));
            const orderIds = this.selectedOrders?.map((order: OrderListInterface) => order.id);
            const code = this.form.get('code')?.value;
            const date = this.form.get('date')?.value;
            const quantityDistribution = this.mapUiToQuantityDistribution();
            const timeDistribution = this.mapUiToTimeDistribution();
            const status = this.form.get('status')?.value.id;
            const jsonInfo = this.createJsonInfoObject();

            return await this._ordersList.createGroup(
                plantId, orderIds, code, date, quantityDistribution,
                timeDistribution, status, jsonInfo
            );
        } else {
            throw new Error('New orders group form is not valid');
        }
    }

    async update() {
        if (this.form.valid && this.group) {
            const code = this.form.get('code')?.value;
            const date = this.form.get('date')?.value;
            const quantityDistribution = this.mapUiToQuantityDistribution();
            const timeDistribution = this.mapUiToTimeDistribution();
            const status = this.form.get('status')?.value.id;
            const jsonInfo = this.createJsonInfoObject();

            return await this._ordersList.editGroup(
                this.group?.id, code, date, quantityDistribution,
                timeDistribution, status, jsonInfo
            );
        } else {
            throw new Error('Edit orders group form is not valid');
        }
    }

    private createJsonInfoObject(): object {
        if (!this.jsonInfoArray?.length) {
            return {};
        }

        let object = {};

        const value = this.jsonInfoArray?.value || [];
        value.forEach((el: JsonInfo) => {
            if (el.key !== "" || el.key) {
                object[el.key] = el.value;
            }
        })

        if (Object.keys(object).length) {
            return object;
        }
        return {};
    }

    private cloneObject(object: any) {
        return Object.assign({}, object);
    }

    private createJsonInfoArray(group?: OrderListInterface): JsonInfo[] {
        if (!group) {
            return [];
        }

        let jsonInfoParse = null;
        if (typeof group.jsonInfo === "object") {
            jsonInfoParse = this.cloneObject(group.jsonInfo);
        } else {
            jsonInfoParse = JSON.parse(group.jsonInfo || null);
        }
        let arr: JsonInfo[] = [];
        if (jsonInfoParse !== "{}" && jsonInfoParse && Object.keys(jsonInfoParse).length) {
            for (let key in jsonInfoParse) {
                arr.push({
                    key: key,
                    value: jsonInfoParse[key]
                })
            }
        }
        return arr;
    }

    private setJsonInfo(entries: JsonInfo[]) {
        entries.forEach((entry: JsonInfo) => {
            const entryGroup = this._fb.group({
                key: entry.key,
                value: entry.value,
            });
            this.jsonInfoArray.push(entryGroup);
        });
    }

    private mapStatusToUi(status?: OrderStatus): SelectEntry {
        return status ? this.statuses.find(entry => entry.id === status) : null;
    }

    private mapUiToQuantityDistribution(): QuantityDistribution {
        const value = this.form.get('quantityDistribution')?.value;
        if (value) {
            return parseInt(value, 10) + 1;
        }
        return undefined;
    }

    private mapUiToTimeDistribution(): TimeDistribution {
        const value = this.form.get('timeDistribution')?.value;
        if (value) {
            return parseInt(value, 10) + 1;
        }
        return undefined;
    }

}
