<form [formGroup]="form" [autocomplete]="false" class="container process-drawer">
    <div class="row">
        <div class="column">
            <ts-text-box primaryText="{{'processData.columns.code' | translate}}"
                         placeholderText="{{'processData.columns.code' | translate}}" formControlName="code"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
        <div class="column">
            <ts-text-box primaryText="{{'processData.columns.description' | translate}}"
                         placeholderText="{{'processData.columns.description' | translate}}"
                         formControlName="description" [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <ts-select primaryText="{{'processData.columns.valueType' | translate}}" [data]="valueTypes"
                       placeholderText="{{'processData.columns.valueType' | translate}}" [clearable]="false"
                       [enableSearch]="true" [showErrors]="true" formControlName="valueType" [value]="selectedType"
                       labelKey="name" [validationDict]="customValidationDict | translateJSON | async"></ts-select>
        </div>
    </div>
</form>