import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderModuleConfigInstance } from '../../../models/order-module-config.model';
import { DeviceModuleInstance } from '../../../models/device-module.model';
import { ModulesService } from '../../../services/modules.service';
import { PlantModuleInstance } from '../../../models/plant-module.model';
import * as _ from "lodash";

@Component({
  selector: 'app-device-module-dialog',
  templateUrl: './device-module-dialog.component.html',
  styleUrls: ['./device-module-dialog.component.scss']
})
export class DeviceModuleDialogComponent implements OnInit {
    label: String;
    deviceModule: DeviceModuleInstance;
    plantModule: PlantModuleInstance;
    plantId: number;
    deviceId: number;
    deviceModuleDefault: DeviceModuleInstance;
    orderModuleConfigDefault: OrderModuleConfigInstance;

  constructor(
      private _ref: MatDialogRef<DeviceModuleDialogComponent>,
      private _modulesService: ModulesService,
      private _changeDetectorRef: ChangeDetectorRef,
      @Inject(MAT_DIALOG_DATA) public data: {
        label: String,
        plantId: number,
        deviceId: number,
        deviceModule?: DeviceModuleInstance
        plantModule?: PlantModuleInstance
      }
  ) {
    this.orderModuleConfigDefault = {
      isOnSatellite: false,
      autoProgress: false,
      validateProgress: false,
      validateProgressQuality: false,
      workProcess: false,
      deltaTarget: false,
      targetScrapsDiff: false,
      showOrderList: false,
      multiOrder: false,
      batch: false,
      batchRawMaterial: false,
      scanMaterial: false,
      pdfDocs: false,
      startOrder: false,
      endOrder: false
    }
    this.deviceModuleDefault = {
      performances: true,
      orders: false,
      qualityControls: false,
      rawMaterial: false,
      parameters: false,
      operatorsLogin: false,
      OrderModuleConfig: this.orderModuleConfigDefault
    }

    if (this.data.deviceId && this.data.deviceId !== -1) {
      this.deviceModuleDefault['deviceId'] = this.data.deviceId;
    }
  }

  async ngOnInit() {
    this.label = this.data.label;
    this.plantId = this.data.plantId;
    this.deviceId = this.data.deviceId;

    if (this.data.deviceModule) {
      this.deviceModule = this.formatObjectValuesFromNumberToBoolean(this.data.deviceModule);
      if (!this.data.deviceModule.OrderModuleConfig) {
        this.deviceModule['OrderModuleConfig'] = this.orderModuleConfigDefault;
      }
    } else {
      // in caso in cui non ci sia la configurazione dei moduli per device, si assegna di default la configurazione del plantModule. 
      if (!this.data.plantModule) {
        let error = " Plant module cannot be null";
        console.log(error);
        this._ref.close({
          msg: "error",
          data: error
        });
        return;
      }
      this.deviceModule = _.cloneDeep(this.formatObjectValuesFromNumberToBoolean(this.data.plantModule));
      if (!this.deviceModule.OrderModuleConfig) {
        this.deviceModule['OrderModuleConfig'] = this.orderModuleConfigDefault;
      }
      delete this.deviceModule.id;
    }
  }

  closeDialog() {
    this._ref.close({
      msg: "cancel",
      data: null
    });
  }

  async save() {
    let deviceModuleComputed: DeviceModuleInstance;
    let orderData: OrderModuleConfigInstance = null;
    try {
      if (this.deviceModule.OrderModuleConfig) {
        orderData = {
          isOnSatellite: this.deviceModule.OrderModuleConfig.isOnSatellite,
          autoProgress: this.deviceModule.OrderModuleConfig.autoProgress,
          validateProgress: this.deviceModule.OrderModuleConfig.validateProgress,
          validateProgressQuality: this.deviceModule.OrderModuleConfig.validateProgressQuality,
          workProcess: this.deviceModule.OrderModuleConfig.workProcess,
          deltaTarget: this.deviceModule.OrderModuleConfig.deltaTarget,
          targetScrapsDiff: this.deviceModule.OrderModuleConfig.targetScrapsDiff,
          showOrderList: this.deviceModule.OrderModuleConfig.showOrderList,
          multiOrder: this.deviceModule.OrderModuleConfig.multiOrder,
          batch: this.deviceModule.OrderModuleConfig.batch,
          batchRawMaterial: this.deviceModule.OrderModuleConfig.batchRawMaterial,
          scanMaterial: this.deviceModule.OrderModuleConfig.scanMaterial,
          pdfDocs: this.deviceModule.OrderModuleConfig.pdfDocs,
          startOrder: this.deviceModule.OrderModuleConfig.startOrder,
          endOrder: this.deviceModule.OrderModuleConfig.endOrder
        }
      }

      let data = {
        deviceData: {
          deviceId: this.deviceId,
          plantId: this.plantId,
          performances: this.deviceModule.performances,
          orders: this.deviceModule.orders,
          qualityControls: this.deviceModule.qualityControls,
          rawMaterial: this.deviceModule.rawMaterial,
          parameters: this.deviceModule.parameters,
          operatorsLogin: this.deviceModule.operatorsLogin,
        },
        orderData
      }

      // update an existing device module
      if (this.deviceModule.id) {
        deviceModuleComputed = await this._modulesService.updateDeviceModule(this.deviceModule.id, data);
        this._ref.close({
          msg: "deviceModuleUpdated",
          data: deviceModuleComputed
        });
      } else {
        deviceModuleComputed = await this._modulesService.createDeviceModule(data);
        this._ref.close({
          msg: "deviceModuleCreated",
          data: deviceModuleComputed,
          deviceId: this.deviceId
        });
      }
    } catch(err) {
      console.log('err:', err);
      this._ref.close({
        msg: "error",
        data: err
      });
    }
  }

  async deleteDeviceModule() {
    if (this.deviceModule.id) {
      try {
        const ris = await this._modulesService.deleteDeviceModule(this.deviceModule.id);
        if (ris) {
          this._changeDetectorRef.detectChanges();
        }
      } catch(err) {
        console.log('err: ', err)
      } finally {
        this._changeDetectorRef.detectChanges();
        this._ref.close({
          msg: "delete",
          data: this.deviceModule.id
        });
      }
    }
  }

  filterReset() {
      this.label = this.data.label,
      this.deviceModule = this.data.deviceModule;
  }

  formatObjectValuesFromNumberToBoolean(object) {
    for ( const property in object ) {
      if (property !== "id" && property !== "deviceId" && property !== "plantId" && property !== "deviceModuleId") {
        if (typeof object[property] === "object") {
          object[property] = this.formatObjectValuesFromNumberToBoolean(object[property]);
        } else {
          object[property] = !!object[property]
        }
      }
    }
    return object;
  }

}
