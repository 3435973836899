import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, of } from 'rxjs';
import { FontService, NotificationConfig, NotificationService, NotificationStyle, OverlaySpinnerService } from '@vapor/angular-ui';
import { OrderAttribute } from '../../../models/order.model';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { faMinus, faPlus, faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { ProductInterface } from '../../../models/product.model';
import { ProductInstance } from '../../../models/product.model';
import { OrderStatus } from '../../../models/order-device.model';
import { DeviceInstance } from '../../../models/device.model';
import { ProductService } from '../../../services/product.service';
import { OrderEditorNewOrderComponent } from '../../ui/order-editor-new-order/order-editor-new-order.component';
import { CompanyInstance } from '../../../models/company.model';
import { PlantInstance } from '../../../models/plant.model';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { CompanyService } from '../../../services/company.service';
import { GenericDialogComponentData, GenericDialogComponentModes } from '../../ui/generic-dialog/generic-dialog.component';
import { DepartmentResource, DeviceResource, ResourceType, WorkCenterResource } from '../../../models/resources.model';
import { WorksPlantsViewInstance } from '../../../models/work.model';
import { ComponentsInstance } from '../../../models/component.model';
import { WorksService } from '../../../services/works.service';
import { ResourcesService } from '../../../services/resources.service';
import { ApiOrderOperationData, OperationImport } from '../../../models/operation.model';
import { OrdersService } from '../../../services/orders.service';
import { OperationsResourcesAttribute } from '../../../models/operationResources.model';
import { VariationsInstance } from '../../../models/variation.model';

interface ResourceInterface {
  id: number;
  val: string;
  type: ResourceType;
}

interface SelectEntry {
  id: number;
  val: string;
}

@Component({
  selector: 'app-order-editor',
  templateUrl: './order-editor.component.html',
  styleUrls: ['./order-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    NotificationService,
  ]
})
export class OrderEditorComponent implements OnInit {

  @ViewChild('orderEditorPage') orderEditorPage!: ElementRef;

  private _subscriptions: Subscription[] = [];
  id: number = undefined;
  editMode: boolean;
  order: OrderAttribute | undefined = undefined;
  showComponentsList: boolean = false;
  @ViewChild('productsList', { static: false, read: ElementRef }) productsListRef!: ElementRef;
  productsListHeight = '100vh';
  products: ProductInterface[];
  selectedPhaseIndex: number = -1;
  componentsListToSave: { id: number, product: ProductInterface, Variation1?: VariationsInstance, sortNumber: number }[] = []; //used in componentList modal
  productsColumns: TsTreeListColumn[] = [];
  selectedCompanyId: number;
  isAdmin: boolean;
  selectedCompany: CompanyInstance;
  plantHasComponentsListModule: boolean; //rawMaterial Module
  unitOptions: any[];
  quantityForm: FormGroup; //used in componentList modal
  modalData: GenericDialogComponentData;
  showSaveModal: boolean = false;
  showCancelModal: boolean = false;
  searchValue: string = "";

  /* OrderData */
  @ViewChild(OrderEditorNewOrderComponent, { static: false }) orderDataComponent: OrderEditorNewOrderComponent;
  @ViewChild('accordion', { static: true, read: ElementRef }) accordion!: ElementRef;
  companyId: number;
  formOrderData: FormGroup;
  additionalInfoForm: FormGroup;
  variation: string;
  additionalInfoOpen: boolean = false;
  infoItems: {description: string, value: string}[] = [];
  phasesList: { id?: number, sequence: number, workName: string, workId: SelectEntry, resourceType: SelectEntry, selectResourceData: {id: number, val: string}[], lastSelectedResourceType: number, resourceIds: ResourceInterface[], setupTime: number, totalWorkTime?: number, target?: number, startDateTime: Date | string, endDateTime: Date | string, notes: string, componentsList: { id: number, product: ProductInterface, quantity: number, Variation1?: VariationsInstance, sortNumber: number }[] }[] = []
  productList: ProductInstance[] = [];
  stepList: { currentStep: string, description: string }[];
  selectStatusOrderData: {id: number, val: string} [] = [];
  

  /* WorkCycleData */
  formCycleData: FormGroup;
  selectResourceTypeData: {id: number, val: string} [] = [];
  resourceList: ResourceInterface[] = [];
  works: WorksPlantsViewInstance[] = [];

  selectedTabId: number = 1;

  customValidationDict = {
    required: 'common.inputErrors.required',
    min: 'common.inputErrors.min'
  };
  
  constructor(
    private readonly _fb: FormBuilder,
    private readonly _company: CompanyService,
    private readonly _font: FontService,
    private readonly _product: ProductService,
    private readonly _translate: TranslateService,
    private readonly _spinner: OverlaySpinnerService,
    private readonly _notification: NotificationService,
    private readonly _navbar: NavbarService,
    private readonly _products: ProductService,
    private readonly _sidebar: SidebarService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _orderService: OrdersService,
    private readonly _works: WorksService,
    private readonly _resourcesService: ResourcesService,
  ) { 
    this._font.addIcon(faMinus, faPlus, faSearch, faTimes);
  }

  async ngOnInit(): Promise<void> {

    this.id = parseInt(this._route.snapshot.paramMap.get('id'), 10);
    this.editMode = this._route.snapshot.data.editMode;

    if (this.editMode) {
      if (isNaN(this.id)) {
        this._translate.stream([
          'common.error',
          'orders.createEdit.loadError',
        ]).subscribe(async (translations) => {
          const title = translations['common.error']
          const message = translations['orders.createEdit.loadError'];
          this.createToast('error', title, message);
          return;
        });
      }
      this._spinner.show();
    }

    this.companyId = parseInt(localStorage.getItem('companyId'));

    this.formOrderData = this._fb.group({
      status: [null, [Validators.required]],
      code: [null, [Validators.required]],
      date: [null, [Validators.required]],
      target: [null, [Validators.required, Validators.min(0)]],
      product: [null, [Validators.required]],
    });

    this.formCycleData = this._fb.group({
      phasesArray: this._fb.array([])
    });

    const translateSubscription =
    this._translate.stream([
      'orders.createEdit.order',
      'orders.createEdit.workcycle',
      'orders.createEdit.orderData',
      'orders.createEdit.workcycleData',
      'orders.createEdit.statusOrder',
      'orders.createEdit.status',
      'orders.createEdit.association',
      'orders.createEdit.product',
      'orders-list.status.draft',
      'orders-list.status.planned',
      'resources.workcenter',
      'resources.device',
      'resources.department'
    ]).subscribe((translations) => {
      this._navbar.setTitle(translations['orders-list.title']);
      setTimeout(() => this._sidebar.setSelected('orders-list'));

      this.stepList = [{
          currentStep: "1",
          description: translations['orders.createEdit.orderData']
      }, {
          currentStep: "2",
          description: translations['orders.createEdit.workcycleData']
      }];

      this.selectStatusOrderData = [
          {
              id: OrderStatus.draft,
              val: translations['orders-list.status.draft'],
          },
          {
              id: OrderStatus.planned,
              val: translations['orders-list.status.planned'],
          },
      ];

      this.selectResourceTypeData = [
          {
            id: ResourceType.device,
            val: translations['resources.device'],
          },        
          {
            id: ResourceType.department,
            val: translations['resources.department'],
          },
          {
            id: ResourceType.workCenter,
            val: translations['resources.workcenter'],
          },            
      ];

    });
    this._subscriptions.push(translateSubscription);

    this.additionalInfoForm = this._fb.group({
      keyValueArray: this._fb.array([])
    });

    const plantId = Number(localStorage.getItem('plantId'));
    this.productList = await this._products.getProducts(undefined, plantId, true, true, ['id', 'label']);
    const prods = this.productList.map((product: ProductInstance) => this.mapProductToUi(product))
      .sort((a, b) => {
        const valA = a.val.toLowerCase();
        const valB = b.val.toLowerCase();
        
        if (valA < valB) {
          return -1;
        }
        if (valA > valB) {
          return 1;
        }
        return 0;
      });
    this.orderDataComponent.productAutocomplete.data$ = of(prods);

    const resourceTree = await this._resourcesService.getResources(plantId);

    resourceTree.forEach(rT => {
      if (rT.resource == ResourceType.department) {
        const d = rT as DepartmentResource;
        if (
          d.Devices?.length > 1 || 
          d.WorkCenters?.find(rTW => rTW.Devices?.length > 1)
        ) {
          this.resourceList.push({
            id: d.id,
            val: d.description,
            type: ResourceType.department
          })
        }

        d.WorkCenters.forEach(wc => {
          if (wc?.Devices?.length > 0) {
            this.resourceList.push({
              id: wc.id,
              val: wc.code,
              type: ResourceType.workCenter
            })
          }
          wc.Devices.forEach(wcd => {
            this.resourceList.push({
              id: wcd.id,
              val: wcd.label,
              type: ResourceType.device
            })
          })
        });

        d.Devices.forEach(dev => {
          this.resourceList.push({
            id: dev.id,
            val: dev.label,
            type: ResourceType.device
          })
        });
      }
      if (rT.resource == ResourceType.workCenter) {
        const wc = rT as WorkCenterResource;
        if (wc?.Devices?.length > 0) {
          this.resourceList.push({
            id: wc.id,
            val: wc.description,
            type: ResourceType.workCenter
          })
        }
        wc.Devices.forEach(dev => {
          this.resourceList.push({
            id: dev.id,
            val: dev.label,
            type: ResourceType.device        
          })
        })
      }
      if (rT.resource == ResourceType.device) {
        const dev = rT as DeviceResource;
        this.resourceList.push({
          id: dev.id,
          val: dev.label,
          type: ResourceType.device
        });
      }
    });

    this.works = (await this._works.getWorks(this.companyId))
      .sort((a, b) => {
        const valA = a.description.toLowerCase();
        const valB = b.description.toLowerCase();
        
        if (valA < valB) {
        return -1;
        }
        if (valA > valB) {
        return 1;
        }
        return 0;
    });

    this.quantityForm = this._fb.group({
        quantityArray: this._fb.array([])
    });
    const translationSubscription =
      this._translate.stream([
        'products.search',
        'products.columns.code',
        'products.columns.product-description',
        'products.columns.unit',
        'products.columns.family',
      ]).subscribe((translations) => {
        const defaultColumn: TsTreeListColumn = {
            dataField: '',
            headerPlaceholder: translations['products.search'],
            allowFiltering: true,
            allowEditing: false,
            allowSorting: true        
        };
        this.productsColumns =
            [
                {
                  dataField: '',
                  allowFiltering: false,
                  allowEditing: false,
                  allowSorting: false,  
                  showColumn: true,
                  width: 12,
                },
                { // id
                    caption: 'id',
                    dataField: 'id',
                    showColumn: false,
                },
                { // code
                    ...defaultColumn,
                    caption: translations['products.columns.code'],
                    dataField: 'code',
                    dataType: 'string',
                    width: 150,
                },
                { // description
                    ...defaultColumn,
                    caption: translations['products.columns.product-description'],
                    dataField: 'description',
                    dataType: 'string',
                },
                { // unit
                    ...defaultColumn,
                    caption: translations['products.columns.unit'],
                    dataField: 'ProductionUnit.unit',
                    dataType: 'object',
                    lkpDataSource: this.unitOptions,
                    lkpKeyField: 'id',
                    lkpResultField: 'label',
                    width: 150,
                },
                { // family
                    ...defaultColumn,
                    caption: translations['products.columns.family'],
                    dataField: 'ProductsFamily.code',
                    dataType: 'string',
                    width: 170,
                },
                { // actions
                    ...defaultColumn,
                    caption: '',
                    fixedPosition: 'right',
                    alignment: 'center',
                    width: 135,
                    allowFiltering: false,
                    allowResizing: false,
                    allowFixing: false,
                    allowSorting: false,
                    cellTemplate: 'tplActions',
                },
            ];
      });
      this._subscriptions.push(translationSubscription);

    this.selectedCompanyId = Number(localStorage.getItem('companyId'));
    if (this.isAdmin) {
      await this.companyChanged(await this._company.getCompany(this.selectedCompanyId));
    } else {
        this.selectedCompany = await this._company.getCompany(this.selectedCompanyId);
        const selectedPlantId = parseInt(localStorage.getItem('plantId'));
        if (isNaN(selectedPlantId)) await this._router.navigate(['orders-list']);
        const selectedPlant = this.selectedCompany?.Plants?.find(p => p.id === selectedPlantId) || this.selectedCompany.Plants?.[0];
        await this.getProducts(selectedPlant, this.editMode ? false : true);
        this.plantHasComponentsListModule = !!selectedPlant.PlantModule?.rawMaterial;
    }

    const companyChangeSubscription = this._company.changeCompanyEmitted$.subscribe(company => {
      this.companyChanged(company);
    });
    this._subscriptions.push(companyChangeSubscription);

    if (this.editMode) {
      this._spinner.removeOverlay();
      await this.loadData();
    }
  }

  private mapProductToUi(product?: ProductInstance) {
    if (product) {
        return {
            id: product.id,
            code: product.code,
            name: product.name,
            devices: product.Devices,
            ProductionUnit: product.ProductionUnit,
            val: `${product.code} - ${product.name}`,
        };
    } else {
        return null;
    }
  }

  checkTargetValue () {
    if (Number.isNaN(this.numberWithComma(this.formOrderData.get('target').value))) {
      return true;
    } else {
      return false;
    }
  }

  get f() {
    return this.formOrderData.controls;
  }

  get keyValueArray() {
    return this.additionalInfoForm.get('keyValueArray') as FormArray;
  }

  get phasesArray() {
    return this.formCycleData.get('phasesArray') as FormArray;
  }

  toggleAdditionalInfo () {
    this.additionalInfoOpen = !this.additionalInfoOpen;
  }


  handleNewInfoButtonClicked () {
    this.infoItems = [...this.infoItems, {description: '', value: ''}];
    this.keyValueArray.push(this._fb.group({
        key: ['', Validators.required],
        value: ['', Validators.required]
    }));
  }

  handleRemoveInfoButtonClicked (i) {
    this.infoItems = [...this.infoItems.slice(0,i), ...this.infoItems.slice(i+1)];
    this.keyValueArray.removeAt(i);
  }

  numberWithComma (value: any): number {
    let valueToReturn = Number(value);
    if (isNaN(valueToReturn)){
      valueToReturn = Number(`${value}`.replace(/\./g, '').replace(/,/g, '.'));
    }
    return valueToReturn;
  }

  calculateNewSequence (index?: number) {
    if (index === null) {
      return 10;
    }
    if (!this.editMode) {
      if (index !== this.phasesList?.length - 1) {
        for(let i = index + 1; i < this.phasesList?.length; i++) {
          const newN = this.phasesList[i].sequence + 10
          this.phasesList[i].sequence = newN;
          this.phasesArray.controls[i].patchValue({ sequence: newN });
        }
      }
      return this.phasesList[index].sequence + 10;
    } else {
      if (index === this.phasesList?.length - 1) {
        return this.phasesList[index].sequence + 10;
      } else {
        return parseInt(((this.phasesList[index].sequence + this.phasesList[index + 1].sequence) / 2).toFixed(0));
      }
    }
  }

  async handleNewPhaseClicked (index?: number) {
    const newSequence = this.calculateNewSequence(index);
    const newPhaseForList = {
      sequence: newSequence,
      workName: null,
      workId: null,
      resourceType: this.selectResourceTypeData?.[0],
      selectResourceData: this.resourceList.filter(r => r.type == ResourceType.device),
      lastSelectedResourceType: ResourceType.device,
      resourceIds: null,
      setupTime: null,
      totalWorkTime: null,
      startDateTime: null,
      endDateTime: null,
      notes: null,
      componentsList: []
    };
    const newPhaseForForms = this._fb.group({
      sequence: [newSequence, [Validators.required]],
      workId: [null, [Validators.required]],
      resourceType: [this.selectResourceTypeData?.[0], [Validators.required]],
      resourceIds: [null, [Validators.required]],
      setupTime: [null, [Validators.required]],
      totalWorkTime: [null, []],
      startDateTime: [null, []],
      endDateTime: [null, []],
      notes: [null, []],
    })
    // Se l'index è diverso dall'ultimo elemento
    if (index !== undefined && index !== this.phasesList.length - 1) {
      // Inserisci nella posizione corretta
      this.phasesList.splice(index + 1, 0, newPhaseForList);
      this.phasesArray.insert(index + 1, newPhaseForForms);
    } else {
      this.phasesList = [
        ...this.phasesList,
        newPhaseForList
      ];
      this.phasesArray.push(newPhaseForForms);
    }

    this.cleanUpAndRestoreVaporSelect();
  }

  cleanUpAndRestoreVaporSelect () {
    setTimeout(async () => {
      const works = this.works.map(w => {return {id: w.id, val: w.code ? `${w.description} (${w.code})` : w.description }});
      const worksAutocompleteComponent = this.orderDataComponent.worksAutocompleteList.toArray()[this.orderDataComponent.worksAutocompleteList.toArray().length - 1]; 
      worksAutocompleteComponent.data$ = of(works);
      for (let i = 0; i < this.orderDataComponent.worksAutocompleteList.toArray().length; i++) {
        const worksAutocompleteComponent = this.orderDataComponent.worksAutocompleteList.toArray()[i];
        worksAutocompleteComponent.data$ = of(works);
        const workId = this.phasesArray.controls[i].get('workId').value;
        if (workId) this.phasesArray.controls[i].patchValue({ workId: works.find(x => x.id === workId.id) })
        const resourceType = this.phasesArray.controls[i].get('resourceType').value;
        if (resourceType) this.phasesArray.controls[i].patchValue({ resourceType: resourceType })
        const resourceIds: ResourceInterface[] | null = this.phasesArray.controls[i].get('resourceIds').value;
        if (resourceIds) {
          const foundResourceIds = this.orderDataComponent.resourcesSelectList.toArray()[i].data.filter(x => resourceIds?.some(y => y.id === x.id));
          this.phasesArray.controls[i].patchValue({ resourceIds: null })
          this.orderDataComponent.resourcesSelectList.toArray()[i].setValue(
            foundResourceIds
          );
          this.phasesArray.controls[i].patchValue({ resourceIds: foundResourceIds })
        }
      }
    }, 100)
  }

  handleRemovePhaseClicked (i) {
    this.phasesList = [...this.phasesList.slice(0,i), ...this.phasesList.slice(i+1)];
    this.phasesArray.removeAt(i);
  }

  async loadData(): Promise<void> {
    try {
      this._spinner.show();

      this.order = (await this._orderService.getOrderOperationV2(this.id));
      if (this.order) {
        //STEP 1 patch
        const productOrder = await this._product.getProduct(this.order.productId);
        this.formOrderData.patchValue({
            status: this.selectStatusOrderData.find(x => x.id === this.order.status),
            code: this.order.code,
            date: this.order.deliveryDate,
            target: this.order.target,
            product: { ...productOrder, val: `${productOrder.code} - ${productOrder.name}` },
        });
        if (this.order.jsonInfo) {
          const tempObj = JSON.parse(`${this.order.jsonInfo}`);
          this.keyValueArray.clear();
          for(let i = 0; tempObj && i < Object.keys(tempObj).length; i++){
              const key = `${Object.keys(tempObj)[i]}`;
              const value = `${tempObj[key]}`;
              this.keyValueArray.push(this._fb.group({
                  key: [key, Validators.required],
                  value: [value, Validators.required]
              }));
          }
        }
        if (this.order?.Variation1?.code) {
          this.variation = `${this.order?.Variation1?.code} - ${this.order?.Variation1?.description}`
        }

        //STEP 2 patch
        if (this.order.Operations?.length > 0) {
          this.phasesList = [];

          for (let i = 0; i < this.order.Operations?.length; i++) {
            const op = this.order.Operations[i];
            const resources = (op as any)?.OperationsResources as OperationsResourcesAttribute[];
            let resourceIds = [];
            let resourceType;
            
            if (resources?.length) {
              if(resources?.[0]?.deviceId){
                resourceType = this.selectResourceTypeData.find(x => x.id === ResourceType.device);      
                resourceIds = this.resourceList.filter( rl =>  resources.find(r => {return r.deviceId == rl.id})); 
              } else if (resources?.[0]?.workCenterId){
                resourceType = this.selectResourceTypeData.find(x => x.id === ResourceType.workCenter);      
                resourceIds = this.resourceList.filter( rl =>  resources.find(r => {return r.workCenterId == rl.id})); 
              } else if (resources?.[0]?.departmentId) {
                resourceType = this.selectResourceTypeData.find(x => x.id === ResourceType.department);      
                resourceIds = this.resourceList.filter( rl =>  resources.find(r => {return r.departmentId == rl.id})); 
              }
            }

            this.phasesList.push({
              id: op.id,
              sequence: op.sequence,
              workName: this.works.find(w => w.id === op.workId).code ? `${this.works.find(w => w.id === op.workId).description} (${this.works.find(w => w.id === op.workId).code})` : this.works.find(w => w.id === op.workId).description,
              workId: { id: op.workId, val: '' },
              resourceType: resourceType,
              setupTime: op.setupTime,
              totalWorkTime: op.totalWorkTime,
              selectResourceData: this.resourceList.filter(r => r.type == resourceType.id),
              lastSelectedResourceType: resourceType.id,
              resourceIds: resourceIds,
              startDateTime: op.startDate,
              endDateTime: op.endDate,
              notes: op.note,
              componentsList: []
            });
  
            this.phasesArray.push(this._fb.group({
              sequence: [op.sequence, [Validators.required]],
              workId: [{ id: op.workId, val: '' }, [Validators.required]],
              resourceType: [resourceType, [Validators.required]],
              resourceIds: [resourceIds, [Validators.required]],
              totalWorkTime: [this.convertToCentennialMinutes(op.totalWorkTime)],
              setupTime: [this.convertToCentennialMinutes(op.setupTime),[Validators.required]],
              startDateTime: [op.startDate, []],
              endDateTime: [op.endDate, []],
              notes: [op.note, []],
            }))
          
            //STEP 3 patch
            if ((op as any)?.Components?.length > 0) {
              for (let j = 0; j < (op as any)?.Components?.length; j++) {
                const component = (op as any).Components[j];
                this.phasesList[i].componentsList.push({
                  id: component.id,
                  product: await this._product.getProduct(component.productId),
                  quantity: component.requestQuantity,
                  Variation1: component.Variation1,
                  sortNumber: component.sortNumber
                })
              }
            }
          }
        }

      }
    } catch (err) {
      this.catchError(this._translate.instant('orders.createEdit.loadError'), err);
    } finally {
      this._spinner.removeOverlay();
    }
  }

  async companyChanged(company: CompanyInstance) {
    if (!company) {
        return;
    }
    this.selectedCompany = company;
    this.selectedCompanyId = company.id;
    const selectedPlantId = parseInt(localStorage.getItem('plantId'));
    if (isNaN(selectedPlantId)) await this._router.navigate(['orders-list']);
    const selectedPlant = this.selectedCompany?.Plants?.find(p => p.id === selectedPlantId) || this.selectedCompany.Plants?.[0];
    await this.getProducts(selectedPlant, true);
    this.plantHasComponentsListModule = !!selectedPlant.PlantModule?.rawMaterial;
  }

  async getProducts(plant: PlantInstance, showSpinner: boolean) {

    if (showSpinner) this._spinner.show();
    localStorage.setItem('plantId', plant.id.toString());
    try {
      const products = await this._product.getProductsV2(this.selectedCompanyId);
      this.products = products.map(
        productInstance => {
          const product: ProductInterface = {
            ...productInstance,
            deviceLabels: productInstance.Devices?.map((device: DeviceInstance) => device.label)
          };
          return product;
        }
      );

      const uniqueProductionUnits = [...new Set(this.products.map(item => item.ProductionUnit.unit))].map(x=>{ return { "id": x, "label": x }; });
      const unitOptions = {
          lkpDataSource: uniqueProductionUnits
      }
      this.productsColumns = this.productsColumns.map(col=>{ 
        return { 
          ...col,
          ...col.dataField == "ProductionUnit.unit" ? unitOptions : {}
        };
      });
    } catch(err) {
      console.warn(err);
    } finally {
      if (showSpinner) this._spinner.removeOverlay(); 
    }
  }

  onResourceTypeSelected(data: { id: number; val: string; index: number; }) {
    if (this.phasesList[data?.index].lastSelectedResourceType == data?.id) return;
    this.phasesList[data?.index].lastSelectedResourceType = data?.id;
    this.phasesArray.controls[data?.index].patchValue({resourceIds: []})
    this.phasesList[data?.index].selectResourceData = this.resourceList.filter(r => r.type === data?.id);
  }

  async selectedTabChange(index: number) {
    this.selectedTabId = index;
    if(index === 2) {
      this.handleNextClicked();
    }
  }

  handleNextClicked() {
    this.selectedTabId = 2;
    if (!this.phasesList || this.phasesList?.length === 0) {
      this.handleNewPhaseClicked(null);
    }
    this.orderEditorPage.nativeElement.scrollTo(0, 0);
    this.cleanUpAndRestoreVaporSelect();
  }

  async handleSaveClicked() {
    if (!this.formCycleData.valid) {
      this.formCycleData.markAllAsTouched();
      return;
    }

    let orderOperation: ApiOrderOperationData;
    
    try {
      const operations: OperationImport[] = [];
      for (let i = 0; i < this.phasesArray.controls.length; i++) {
        const phase = this.phasesArray.controls[i];
        const phaseId = this.phasesList[i].id;
        const operation: OperationImport = {
          sequence: Number(phase.get('sequence').value),
          //TODO: subSequence required, for now set to 0
          subSequence: 0,
          //TODO: printed required, for now set to 0
          printed: 0,
          workId: Number(phase.get('workId').value?.id),
          managed: true,
          ...(phase.get('startDateTime').value ? {startDate: phase.get('startDateTime').value} : null),
          ...(phase.get('endDateTime').value ? {endDate: phase.get('endDateTime').value} : null),
          ...(phase.get('setupTime').value ? {setupTime: Math.floor(phase.get('setupTime').value * 60000)} : null),
          ...(phase.get('totalWorkTime').value ? {totalWorkTime: Math.floor(phase.get('totalWorkTime').value * 60000)} : 0),
          note: phase.get('notes').value || '',
          resourceType: Number(phase.get('resourceType').value?.id),
          resoucesIds: phase.get('resourceIds')?.value?.map((entry: SelectEntry) => entry.id),
          Components: this.phasesList[i].componentsList.map(component => {
            const componentToReturn: ComponentsInstance = {
              sortNumber: component.sortNumber,
              productId: Number(component.product.id),
              //TODO: for now every component is active
              status: 1, 
              requestQuantity: this.numberWithComma(component.quantity)
            }
            return (!!component.id) ? { id: component.id, ...componentToReturn } : componentToReturn;
          })
        };
        operations.push((phaseId) ? {id: phaseId, ...operation } : operation);
      }

      const jsonInfo = {};
      const jsonInfoRaw = this.keyValueArray.controls.map(c => c.getRawValue());
      jsonInfoRaw.forEach(j => jsonInfo[j.key] = j.value);

      orderOperation = {
        code: this.formOrderData.get('code').value,
        productId: Number(this.formOrderData.get('product').value?.id),
        plantId: Number(localStorage.getItem('plantId')),
        target: this.numberWithComma(this.formOrderData.get('target').value),
        deliveryDate:  this.formOrderData.get('date').value,
        jsonInfo: jsonInfo || null,
        status: Number(this.formOrderData.get('status').value?.id),
        manualCreation: true,
        Operations: operations
      };

    } catch (error) {
      console.error(error)
    }

    if (!this.editMode) {
      try {
        this._spinner.show();
        const result = await this._orderService.createOrderOperationV2(orderOperation);
        this.createToast('check', '', this._translate.instant('orders.createEdit.createSuccess', { code: result.code }));
        await this._router.navigate(['orders-list']);
        return;
      } catch (ex) {
        console.error('Error creating new order: ');
        console.error(ex);
        console.error(ex?.error?.message);        
        if (ex?.status == 409) {
          this.createToast('error', '', this._translate.instant('orders.createEdit.orderExists', { code: orderOperation.code }));
        } else {
          this.createToast('error', '', this._translate.instant('orders.createEdit.createError', { code: orderOperation.code }));
        }
      } finally {
        this._spinner.removeOverlay()
      }
      
    } else {

      //removing code, plantId, productId from PUT object (not allowed)
      orderOperation = 
        Object.keys(orderOperation)
        .filter(objKey => (objKey !== 'code' && objKey !== 'plantId' && objKey !== 'productId'))
        .reduce((newObj: ApiOrderOperationData, key) =>
          {
              newObj[key] = orderOperation[key];
              return newObj;
          }, {}
      );
      try {
        this._spinner.show();
        const result = await this._orderService.editOrderOperationV2(this.order.id, orderOperation);
        this.createToast('check', '', this._translate.instant('orders.createEdit.editSuccess', { code: result.code }));
        await this._router.navigate(['orders-list']);
        return;
      } catch (ex) {
        console.error('Error updating order (' + this.order?.id + '): ');
        console.error(ex);
        console.error(ex?.error?.message);
        this.createToast('error', '', this._translate.instant('orders.createEdit.editError', { code: this.formOrderData?.get('code')?.value }));
      } finally {
        this._spinner.removeOverlay()
      }
    }
  }

  async handleCancelClicked() {
    if (this.selectedTabId == 2){
      this.selectedTabId = 1;
    } else {
      await this._router.navigate(['orders-list']);
    }
  }

  get quantityArray() {
      return this.quantityForm.get('quantityArray') as FormArray;
  }

  closeComponentsList() {
    this.closeAllModals();
    this.showComponentsList = false;
    this.selectedPhaseIndex = -1;
  }

  trashClick(index: number) {
    this.handleRemovePhaseClicked(index);
  }

  async openComponentsList(index: number) {
    this.componentsListToSave = [];
    this.quantityArray.clear();
    const tempQuantityArray: { productId: number, group: {}}[] = [];
    for (let i = 0; i < this.phasesList[index].componentsList.length; i++) {
      const component = this.phasesList[index].componentsList[i];
      tempQuantityArray.push({
        productId: component.product.id,
        group: this._fb.group({
          productId: [component.product.id, Validators.required],
          quantity: [component.quantity, Validators.required]
        })
      });
      this.componentsListToSave.push({
        id: component.id,
        product: component.product,
        Variation1: component.Variation1,
        sortNumber: component.sortNumber
      })
    }
    this.componentsListToSave.sort((a, b) => {
      return a.sortNumber - b.sortNumber;
    });

    tempQuantityArray.sort((a, b) => {
      const componentA = this.componentsListToSave.find(c => c.product.id === a.productId);
      const componentB = this.componentsListToSave.find(c => c.product.id === b.productId);
      return componentA.sortNumber - componentB.sortNumber;
    });
    tempQuantityArray.forEach(item => {
      this.quantityArray.push(item.group);
    });
    this.showComponentsList = true;
    this.selectedPhaseIndex = index;
  }

  async onAddPhase(index: number) {
    this.handleNewPhaseClicked(index);
  }

  handleCancelComponentsListClicked() {
      this.modalData = {
        title:  this._translate.instant('orders.createEdit.exitComponentsList'),
        message: this.phasesList[this.selectedPhaseIndex].workName ? this._translate.instant('orders.createEdit.exitComponentsListMessage', { phase: this.phasesList[this.selectedPhaseIndex].workName }) : this._translate.instant('orders.createEdit.exitComponentsListMessageNoPhase'),
        mode: GenericDialogComponentModes.exitSave,
      };
    this.showCancelModal = true;
  }

  handleSaveComponentsListClicked() {
    this.closeAllModals();
    if (!this.quantityForm.valid) {
      this.quantityForm.markAllAsTouched();
      return;
    }

    this.modalData = {
      title:  this._translate.instant('orders.createEdit.insertComponentsList'),
      message: this.phasesList[this.selectedPhaseIndex].workName ? this._translate.instant('orders.createEdit.insertComponentsListMessage', { phase: this.phasesList[this.selectedPhaseIndex].workName }) : this._translate.instant('orders.createEdit.insertComponentsListMessageNoPhase'),
      mode: GenericDialogComponentModes.save,
    };

    this.showSaveModal = true;
  }

  saveComponentsList = () => {
    this.phasesList[this.selectedPhaseIndex].componentsList = [];
    for (let i = 0; i < this.quantityArray.controls.length; i++) {
      const qRaw = this.quantityArray.controls[i].getRawValue();
      
      const elToAdd: { id: number, product: ProductInterface, quantity: number, sortNumber: number } = {
        id: this.componentsListToSave.find(x => x.product.id === qRaw.productId).id,
        product: this.componentsListToSave.find(x => x.product.id === qRaw.productId).product,
        quantity: qRaw.quantity,
        sortNumber: this.componentsListToSave.find(x => x.product.id === qRaw.productId).sortNumber
      }

      this.phasesList[this.selectedPhaseIndex].componentsList.push(elToAdd)
    }
    
    const title = this._translate.instant('orders.createEdit.enterComponentListSuccessTitle');
    const message = this.phasesList[this.selectedPhaseIndex].workName ? this._translate.instant('orders.createEdit.enterComponentListSuccessMessage', { phase: this.phasesList[this.selectedPhaseIndex].workName }) : this._translate.instant('orders.createEdit.enterComponentListSuccessMessageNoPhase');
    this.closeComponentsList();
    this.createToast('check', title, message);
  }
  
  closeAllModals () {
    this.showCancelModal = false;
    this.showSaveModal = false;
  }

  productAlreadyAdded = (data: ProductInterface) => {
    let toSkip = false;
    for (let i = 0; i < this.componentsListToSave.length; i++) {
      const el = this.componentsListToSave[i];
      if (el.product.id === data.id) {
        toSkip = true;
        continue;
      }
    }
    return toSkip;
  }

  addProductToComponentsList(data: ProductInterface) {
    if (this.productAlreadyAdded(data)) return;

    this.componentsListToSave.push({ 
      id: null, 
      product: data, 
      sortNumber: this.componentsListToSave?.length > 0 ? (this.componentsListToSave[this.componentsListToSave.length - 1].sortNumber + 1) : 1 
    });

    this.quantityArray.push(this._fb.group({
        productId: [data.id, Validators.required],
        quantity: ['', Validators.required]
    }));
  }

  removeProductToComponentsList(data: ProductInterface) {
    let foundIndex = -1;
    for (let i = 0; i < this.componentsListToSave.length; i++) {
      const el = this.componentsListToSave[i];
      if (el.product.id === data.id) {
        foundIndex = i;
        continue;
      }
    }
    if (foundIndex === -1) return;

    this.componentsListToSave.splice(foundIndex, 1);
    this.quantityArray.removeAt(foundIndex);
  }

  onSearchChange(e: string) {
    this.searchValue = e;
  }

  onSearch() {
  }

  searchMatchesProduct(product: ProductInterface) {
    if (!this.searchValue) return true;
    const foundCode = product.code.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1;
    const foundDescription = product.description.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1;

    if (foundCode || foundDescription) {
      return true;
    }
    return false;
  }

  onWorkIdChange({ index, work }) {
    this.phasesList[index].workName = work;
  }

  intervallTimeChanged(event: {value: number, index: number, intervall: 'setupTime' | 'totalWorkTime'}) {
    event.intervall === 'setupTime' ? this.phasesArray.controls[event.index].patchValue({ setupTime: event.value || 0}) : this.phasesArray.controls[event.index].patchValue({ totalWorkTime: (event.value || 0) });
  }

  ngAfterContentChecked(): void {
    // We do it here because the ngSwitch has already been evaluated
    if (this.productsListRef) {
      const offsetTop = this.productsListRef.nativeElement.offsetTop;
      this.productsListHeight = `calc(100vh - ${offsetTop + 64}px)`;
    }
  }

  private catchError(message: string, error: Error) {
      console.error(error);

      const config: NotificationConfig = {
          content: message,
          type: 'toast',
          style: 'error',
          timeout: 5000,
          position: 'right',
      };
      this._notification.show(config);
  }

  private async createToast(style: NotificationStyle, title: string, message: string) {
    const config: NotificationConfig = {
      title: title,
      content: message,
      type: 'toast',
      style: style,
      timeout: 5000,
      position: 'right',
    };
    await this._notification.show(config);
  }

  convertToCentennialMinutes(milliseconds) {
    // Convertiamo i millisecondi in minuti (1 minuto = 60000 millisecondi)
    const minutes = milliseconds / 60000;
    // Arrotondiamo a 4 decimali per precisione
    const centennialMinutes = parseFloat(minutes.toFixed(4));
    return centennialMinutes;
}

  ngOnDestroy(): void {
    // Unsubscribe all subscriptions to avoid memory leaks
    this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
        subscription.unsubscribe();
    });
  }

}
