<div *ngIf="order.orderWithCycle">
    <ts-tab-panel class="tabs" [panelTabs]="tabs" type="primary" barType="standard" size="small" [(selectedTab)]="selectedTabId"
                    [selectTabOnClick]="true">
    </ts-tab-panel>
</div>
<div class="contents">
    <div *ngIf="selectedTabId === 1">
        <ts-layout mode="flex" class="selectStatusWrapper" tsFxLayout="column" tsFxLayoutAlign="start start" gap="0px 0px" padding="0px 0px">
            <ts-layout-item>
                <div class="label" weight="600">{{'orders.createEdit.statusOrder' | translate}}</div>
            </ts-layout-item>
    
            <ts-layout-item>
                <ts-select class="select" *ngIf="toBeProduced == 1" primaryText="{{'orders-list.selectStatus' | translate}}" 
                        [enableSearch]="false"
                        [clearable]="false"
                        [data]="plannedStatuses" 
                        width="100%"
                        [primaryText]="translate('orders-list.selectStatus')"
                        [value]="plannedStatuses[order?.status-1]"
                        (valueChange)=plannedStatusChanged($event)
                        [enabled] = "!order.groupId">
                </ts-select>
                <ts-select class="select" *ngIf="toBeProduced == 2" primaryText="{{'orders-list.orderState' | translate}}"
                        [enableSearch]="false" 
                        [clearable]="false"
                        [data]="productionStatuses"
                        width="100%"
                        [primaryText]="'Select label'"
                        [value]="productionStatuses[order?.status-3]"
                        (valueChange)=productionStatusChanged($event)
                        [enabled] = "!order.groupId">
                </ts-select>
            </ts-layout-item>
            
            <ts-layout-item>
                <div class="label">{{'orders.createEdit.orderData' | translate}}</div>
            </ts-layout-item>

            <ts-layout-item class="col" gap="0px 0px" margin="0px" padding="0px 0px">
                <ts-layout mode="flex" class="row" tsFxLayout="row" tsFxLayoutAlign="start start" gap="0px 0px" margin="0px 0px" padding="0px 0px">
                    <ts-layout-item>
                        <div class="label-s mdb12">{{'orders.insert-code' | translate}}</div>                    
                        <div class="value-s">{{order.code}}</div>                    
                    </ts-layout-item>

                    <ts-layout-item>
                        <div class="label-s mdb12">{{'orders.createEdit.deliveryDate' | translate}}</div>                    
                        <div class="value-s">{{parseDate(order.deliveryDate)}}</div>                    
                    </ts-layout-item>

                </ts-layout>
                <ts-layout mode="flex" class="row" tsFxLayout="row" tsFxLayoutAlign="start start" gap="0px 0px" margin="0px" padding="0px 0px">
                    <ts-layout-item padding="0px 0px">
                        <div class="label-s mdb12">{{'orders-list.columns.target' | translate }}</div>                    
                        <div class="value-s">{{formatDecimal(order.target,order?.Product?.ProductionUnit?.decimal || 0)}}{{' '}}{{order?.Product?.ProductionUnit?.unit}}</div>                    
                    </ts-layout-item>
                    <ts-layout-item *ngIf="order?.deviceLabels?.length && !order.orderWithCycle">
                        <div class="label-s mdb12">{{'orders-list.columns.devices' | translate}}</div>                    
                        <div *ngFor="let label of order?.deviceLabels">
                            <div class="value-s">{{label}}</div>                    
                        </div>
                    </ts-layout-item>
                    <ts-layout-item *ngIf="order?.Variation1?.code">
                        <div class="label-s mdb12">{{'products.newEditProduct.variation' | translate}}</div>                    
                        <div class="value-s">{{('  ' + order.Variation1.code + ' - ' + order.Variation1.description)}}</div>                    
                    </ts-layout-item>
                </ts-layout>
            </ts-layout-item>
        </ts-layout>

        <ts-layout *ngIf="hasJsonInfo()" class="row" mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start" tsFxLayoutAlign="start start" gap="0px 0px" padding="0px 0px">
            <ts-layout-item  gap="0px 0px" class="additionalInfo">
                <ts-sub-rg>{{'orders-list.columns.info' | translate}}</ts-sub-rg>
                <div class="mdb12"></div>
                <div class="mdb12" *ngFor="let item of toJSON(order?.jsonInfo) | keyvalue">
                    <ts-layout-item class="infoItem">
                        <span class="value-s">{{item.key}}</span>  
                        <span class="value-s">{{item.value}}</span>
                    </ts-layout-item>
                </div>
            </ts-layout-item>
        </ts-layout>

        <ts-layout *ngIf="!order.orderWithCycle"  mode="flex" class="pdl0 pdt0 mdb16" tsFxLayout="column" tsFxLayoutAlign="start start" gap="0px 0px" padding="0px 0px">
            <ts-layout-item>
                <div class="label-s mdb12">{{'orders.createEdit.product' | translate}}</div>
            </ts-layout-item>
            <ts-layout-item class="col" gap="0px 0px" margin="0px" padding="0px 0px">
                <ts-layout mode="flex" class="row" tsFxLayout="row" tsFxLayoutAlign="start start" gap="0px 0px" margin="0px 0px" padding="0px 0px">
                    <ts-layout-item class="w50">
                        <div class="label-s pdb12 ">{{order?.Product?.code}}</div>                    
                        <div class="value-s">{{order?.Product?.description}}</div>                    
                    </ts-layout-item>
                </ts-layout>                
            </ts-layout-item>
        </ts-layout>
    </div>    
    <div *ngIf="selectedTabId === 2">
        <ts-layout mode="flex" class="selectStatusWrapper" tsFxLayout="column" tsFxLayoutAlign="start start" gap="0px 0px" padding="0px 0px">
            <ts-layout-item class="pdl0 pdt0 expand" >
                <div class="label" weight="600">{{'orders.createEdit.WorkCycleData' | translate}}</div>
            </ts-layout-item>
        </ts-layout>

        <ts-layout mode="flex" class="pdl0 pdt0 mdb16" tsFxLayout="column" tsFxLayoutAlign="start start" gap="0px 0px" padding="0px 0px">
            <ts-layout-item class="col" gap="0px 0px" margin="0px" padding="0px 0px">
                <ts-layout mode="flex" class="row" tsFxLayout="row" tsFxLayoutAlign="start start" gap="0px 0px" margin="0px 0px" padding="0px 0px">
                    <ts-layout-item class="w50">
                        <div class="label-s pdb12 ">{{order?.Product?.code}}</div>                    
                        <div class="value-s">{{order?.Product?.description}}</div>                    
                    </ts-layout-item>
                </ts-layout>                
            </ts-layout-item>
        </ts-layout>
        <ts-layout mode="flex" class="selectStatusWrapper" tsFxLayout="column" tsFxLayoutAlign="start start" gap="0px 0px" padding="0px 0px">
            <ts-layout-item class="no-size">
                <div class="label" weight="600">{{'orders.createEdit.workCyclePhases' | translate}}</div>
            </ts-layout-item>
        </ts-layout>
        <ts-layout *ngFor="let op of operations" mode="flex" class="cardsWrp" tsFxLayout="column" tsFxLayoutAlign="start start" margin="0px 0px" gap="0px 0px" padding="0px 0px">
            <ts-layout-item class="card">
                <ts-layout-item class="grey-label">
                    {{formatDecimal(op.target, order?.Product?.ProductionUnit?.decimal || 0)}}{{' '}} {{order?.Product?.ProductionUnit?.unit}} 
                </ts-layout-item>
                <ts-layout-item class="label-big no-size">
                    {{'orders.createEdit.phase' | translate}} {{op.sequence}} {{': '}}{{op.Work.description}}
                </ts-layout-item>
                <ts-layout-item class="no-size">
                    <ts-layout-item class="no-size">
                        <ts-icon name="hourglass-half" type="fad" size="sm" color="#5C6B75"></ts-icon>
                        <span class="value-s grey">{{'orders.createEdit.setup' | translate}}{{': '}}</span>
                        <span class="value-s grey w700">{{getInterval(op.setupTime || 0)}}</span>
                        <div class="px16">{{' '}}</div>
                        <ts-icon name="hourglass-half" type="fad" size="sm" color="#5C6B75"></ts-icon>
                        <span class="value-s grey">{{'orders.createEdit.total' | translate}}{{': '}}</span>
                        <span class="value-s grey w700">{{getInterval(op.totalWorkTime || 0)}}</span>
                    </ts-layout-item>
                </ts-layout-item>
                <ts-layout-item class="no-size">
                    <ts-divider class="divider" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
                </ts-layout-item>
                <ts-layout-item class="row">
                    <div class="openingRow">
                        <span class="value-s grey">{{'orders.createEdit.opening' | translate}}{{': '}} {{'orders.createEdit.free' | translate}}</span>
                        <ts-layout-item class="icon-wrp ">
                            <ts-icon 
                                name="info-square" 
                                type="fad" 
                                size="1x" 
                                color="#003852"></ts-icon> 
                            <div class="opentooltip">
                                <span>
                                    {{'orders-list.freeOpen' | translate}}
                                </span>
                                <span>
                                    {{'orders-list.freeOpenText' | translate}}
                                </span>
                            </div>
                        </ts-layout-item>
                    </div>
                </ts-layout-item>
            </ts-layout-item>
        </ts-layout>        
    </div>
</div>

