<form *ngIf="isEditing" [formGroup]="form" [autocomplete]="false" class="container device-drawer">
    <div class="row">
        <div class="column">
            <ts-text-box primaryText="{{'devices.columns.name' | translate}}"
                         placeholderText="{{'devices.columns.name' | translate}}" formControlName="label"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
            <span class="col label secondary-color" style="margin-top: -20px;">SN. {{ device.Unipi.serial }}</span>
        </div>
        <div class="column">
            &nbsp;
        </div>
    </div>

    <div class="general-info wrapper mt-10">
        <div class="row">
            <h3 class="col header">{{'devices.drawer.general-info' | translate}}</h3>
        </div>
        <div class="row mt-10">
            <span class="col label secondary-color">{{'devices.drawer.device-mode' | translate}}</span>
            <span class="col label secondary-color">{{'devices.drawer.device-type' | translate}}</span>
        </div>
        <div class="row">
            <h4 class="col value">{{getDeviceMode().type}}</h4>
            <h4 class="col value">{{ device.type === 0 ? ('devices.drawer.automatic' | translate) :
                ('devices.drawer.manual' | translate) }}</h4>
        </div>
        <div class="row">
            <span class="col label secondary-color">{{'devices.drawer.stops-detection' | translate}}</span>
        </div>
        <div class="row">
            <h4 class="col value">
                {{getDeviceStopMode().type}}
            </h4>
        </div>
    </div>


    <div class="row mt-10">
        <h3 class="col header">{{'devices.drawer.technical-data' | translate}}</h3>
    </div>

    <div class="row" *ngIf="isAdmin">
        <div class="column">
            <ts-select class="select" [data]="enabledSelectData" [value]="selectedEnabledStatus" [clearable]="false"
                       primaryText="{{'devices.columns.enabled' | translate}}" formControlName="enabled"></ts-select>
        </div>
    </div>
    <div class="row">
        <div class="column" *ngIf="getDeviceStopMode().val === 'from-threshold'">
            <ts-number-box primaryText="{{'devices.columns.startThreshold' | translate}} (pcs)"
                           placeholderText="{{'devices.columns.startThreshold' | translate}}"
                           formControlName="resumeProductionProductsThreshold" [showErrors]="true" width="300px"
                           [validationDict]="customValidationDict | translateJSON | async"></ts-number-box>
        </div>
        <div class="column"
             *ngIf="getDeviceStopMode().val === 'from-threshold' || getDeviceStopMode().val === 'manual-threshold'">
            <ts-number-box primaryText="{{'devices.columns.stopThreshold' | translate}} (sec)"
                           placeholderText="{{'devices.columns.stopThreshold' | translate}}"
                           formControlName="stopProductsThreshold" [showErrors]="true" width="300px"
                           [validationDict]="customValidationDict | translateJSON | async"></ts-number-box>
        </div>
    </div>
    <div class="row" *ngIf="getDeviceMode().val !== 'timer-mode'">
        <div class="column">
            <ts-number-box primaryText="{{'devices.columns.multiplier' | translate}} (pcs)" [step]="0.1"
                           placeholderText="{{'devices.columns.multiplier' | translate}}" formControlName="multiplier"
                           [showErrors]="true" width="300px"
                           [validationDict]="customValidationDict | translateJSON | async"></ts-number-box>
        </div>
        <div class="column">
            <ts-number-box primaryText="{{'devices.columns.scraps-multiplier' | translate}} (pcs)" [step]="0.1"
                           placeholderText="{{'devices.columns.scraps-multiplier' | translate}}"
                           formControlName="scrapsMultiplier" [showErrors]="true" width="300px"
                           [validationDict]="customValidationDict | translateJSON | async"></ts-number-box>
        </div>
    </div>
    <div class="row" *ngIf="getDeviceMode().val === 'count-pcs-delta'">
        <ts-number-box primaryText="{{'products.drawer.product-resources-multi-input' | translate}} (pcs)" [step]="0.1"
                       placeholderText="{{'products.drawer.product-resources-multi-input' | translate}}"
                       formControlName="inputMultiplier" [showErrors]="true" width="300px"
                       [validationDict]="customValidationDict | translateJSON | async"></ts-number-box>
    </div>

    <div class="advanced-info wrapper mt-10" *ngIf="isAdmin">
        <div class="row">
            <h3 class="col header">{{'devices.drawer.advanced-info' | translate}}</h3>
        </div>
        <div class="row mt-10">
            <span class="col label secondary-color">{{'devices.drawer.target-speed-history' | translate}}</span>
            <span class="col label secondary-color">{{'devices.drawer.speed-calc' | translate}}</span>
        </div>
        <div class="row">
            <h4 class="col value">{{ device.targetSpeedHistory === 0 ? 'Disabled' : 'Preserved' }}</h4>
            <h4 class="col value">{{ device.operatorsSpeed ? 'Multiply by number of operators' : 'Standard' }}</h4>
        </div>
        <div class="row">
            <span class="col label secondary-color">{{'devices.drawer.progress-mode' | translate}}</span>
            <span class="col label secondary-color">{{'devices.drawer.target-mode' | translate}}</span>
        </div>
        <div class="row">
            <h4 class="col value">{{ device.progressHints === 0 ?
                ('devices.drawer.manual' | translate) :
                ('devices.drawer.hint-no-pcs-progress' | translate) }}
            </h4>

            <h4 class="col value">{{ device.consumingScraps ?
                ('devices.drawer.consider-sum-pcs-scraps' | translate) :
                ('devices.drawer.consider-good-pcs' | translate) }}
            </h4>
        </div>
    </div>

</form>

<div *ngIf="!isEditing" class="container device-drawer-qr">
    <h1>{{device.label}}</h1>

    <div class="content" *ngIf="device.Unipi.serial">
        <qr-code id="qrImage" [value]="device.Unipi.serial" [size]="300"></qr-code>
    </div>
    <p>{{'devices.columns.serial' | translate}}: {{device.Unipi.serial || 'N/A'}}</p>
</div>