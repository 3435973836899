<ng-container *ngIf="tplKey === 'tplDevices'">
    <ts-tree-list-cell *ngIf="data?.Devices?.length == 1" [cellText]="data?.Devices[0].label" [hyperlinkEnabled]="false"
                       [buttonIsVisible]="false"></ts-tree-list-cell>
    <ts-tree-list-cell *ngIf="data?.Devices?.length > 1"
                       cellText="{{ 'orders-list.cells.devices' | translate:{ first: data?.Devices[0].label, others: data?.Devices?.length - 1 } }}"
                       [hyperlinkEnabled]="false" [buttonIsVisible]="false"></ts-tree-list-cell>
</ng-container>

<span *ngIf="tplKey === 'tplActions'">
    <ts-tree-list-button icon="window-maximize" title="Info" (click)="operatorInfo(data)"></ts-tree-list-button>
    <ts-tree-list-button icon="pencil" title="Edit" (click)="editOperator(data.id)"></ts-tree-list-button>
    <ts-tree-list-button icon="trash-alt" [isDanger]="true" title="Delete"
                         (click)="deleteOperator(data.id)"></ts-tree-list-button>
</span>