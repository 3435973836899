<ts-layout class="dataWrapper" mode="flex" tsFxLayout="column" tsFxLayoutAlign="start start" padding="16px"
           gap="0px 0px">
    <ts-layout-item class="centralInfo" padding="0px 0px">
        <ts-layout mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start">
            <ts-layout-item class="infoItem" padding="0px 0px">
                <ts-sub-rg>{{'users.columns.firstName' | translate}}</ts-sub-rg>
                <span>{{user.firstName}}</span>
            </ts-layout-item>
            <ts-layout-item class="infoItem">
                <ts-sub-rg>{{'users.columns.lastName' | translate}}</ts-sub-rg>
                <span>{{user.lastName}}</span>
            </ts-layout-item>
        </ts-layout>
        <ts-layout mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start">
            <ts-layout-item class="infoItem" padding="0px 0px">
                <ts-sub-rg>{{'users.columns.email' | translate}}</ts-sub-rg>
                <span>{{user.email}}</span>
            </ts-layout-item>
            <ts-layout-item class="infoItem">
                <ts-sub-rg>{{'users.columns.role' | translate}}</ts-sub-rg>
                <span>{{getUserRole(user)}}</span>
            </ts-layout-item>
        </ts-layout>
        <ts-layout mode="flex" tsFxLayout="row" tsFxLayoutAlign="start start">
            <ts-layout-item class="infoItem" padding="0px 0px">
                <ts-sub-rg>Plant</ts-sub-rg>
                <span>{{user.Plants.length > 0 ? user.Plants[0].description : 'companies.all-plants' |
                    translate}}</span>
            </ts-layout-item>
        </ts-layout>

    </ts-layout-item>
</ts-layout>