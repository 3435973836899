import { Component, Input, OnInit } from '@angular/core';
import { ErrorCategoryInstance } from '../../../models/error-category.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-category-drawer',
  templateUrl: './category-drawer.component.html',
  styleUrls: ['./category-drawer.component.scss']
})
export class CategoryDrawerComponent implements OnInit {

  @Input() categoryError?: ErrorCategoryInstance;

  form: FormGroup;

  customValidationDict = {
    required: 'common.inputErrors.required',
    min: 'common.inputErrors.min'
  };

  constructor(private readonly _fb: FormBuilder) {
    this.form = this._fb.group({
      name: [null, [Validators.required]]
    })
  }

  get formValid(): boolean {
    return this.form.valid;
  }

  ngOnInit(): void {

    if (this.categoryError) {
      this.form.reset();
      this.form.patchValue({
        name: this.categoryError.name
      })
    }
  }

  onSubmit() {

    const data = {
      ...this.form.value
    }

    if (this.categoryError) {
      data['id'] = this.categoryError.id;
    }

    return data
  }

}
