<form [formGroup]="form" [autocomplete]="false" class="container tags-drawer">
    <div class="row">
        <div class="column">
            <ts-text-box primaryText="{{'devices.columns.name' | translate}}"
                         placeholderText="{{'devices.columns.name' | translate}}" formControlName="name"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
        <div class="column">
            <ts-text-box primaryText="{{'tags.columns.n_errors' | translate}}"
                         placeholderText="{{'tags.columns.n_errors' | translate}}" width="300px" [readOnly]="true"
                         [value]="tag ? tag.Errors.length : 0"></ts-text-box>
        </div>
    </div>
</form>