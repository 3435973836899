import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DeviceModuleInstance } from '../../../models/device-module.model';
import { PlantModuleInstance } from '../../../models/plant-module.model';
import { OrderModuleConfigInstance } from '../../../models/order-module-config.model';

@Component({
  selector: 'app-company-drawer',
  templateUrl: './company-drawer.component.html',
  styleUrls: ['./company-drawer.component.scss']
})
export class CompanyDrawerComponent implements OnInit {

  @Input() plantModule?: PlantModuleInstance;
  @Input() plantDescription?: string;
  @Input() deviceModule?: DeviceModuleInstance;
  @Input() deviceId?: number;

  @Input() isDevice: boolean;
  @Input() type: 'device' | 'plant' | 'new-plant';

  plantId: number;
  companyId: number;

  form: FormGroup;


  customValidationDict = {
    required: 'common.inputErrors.required',
    min: 'common.inputErrors.min'
  };

  constructor(private readonly _fb: FormBuilder) {
    this.form = this._fb.group({
      description: [null],
      performances: [true],
      orders: [false],
      qualityControls: [false],
      rawMaterial: [false],
      parameters: [false],
      operatorsLogin: [false],
      OrderModuleConfig: this._fb.group({
        isOnSatellite: [false],
        autoProgress: [false],
        validateProgress: [false],
        validateProgressQuality: [false],
        workProcess: [false],
        deltaTarget: [false],
        targetScrapsDiff: [false],
        showOrderList: [false],
        multiOrder: [false],
        batch: [false],
        batchRawMaterial: [false],
        scanMaterial: [false],
        pdfDocs: [false],
        startOrder: [false],
        endOrder: [false]
      })
    })
  }

  get f() {
    return this.form.controls;
  }

  get formValid(): boolean {
    return this.form.valid;
  }

  get isDeviceModule(): boolean {
    return this.isDevice;
  }

  ngOnInit(): void {

    this.plantId = Number(localStorage.getItem('plantId'));
    this.companyId = Number(localStorage.getItem('companyId'));

    if (this.isDevice) {
      this.deviceModule = this.formatObjectValuesFromNumberToBoolean(this.deviceModule);
      this.updateForm(this.deviceModule);
    } else {
      this.plantModule = this.formatObjectValuesFromNumberToBoolean(this.plantModule);
      this.updateForm(undefined, this.plantModule);
    }
  }

  onSubmit(): {
    type: 'device' | 'plant' | 'new-plant',
    orderData: OrderModuleConfigInstance,
    description?: string,
    deviceData?: DeviceModuleInstance,
    plantData?: PlantModuleInstance
  } {
    if (!this.form.valid) {
      return;
    }

    const { OrderModuleConfig, description, ...otherData } = this.form.value
    let data = {
      orderData: OrderModuleConfig,
      type: this.type
    };

    if (this.isDevice) {
      data['deviceData'] = {
        deviceId: this.deviceId,
        plantId: this.plantId,
        ...otherData
      };
    } else {
      data['plantData'] = {
        ...otherData,
        plantId: this.plantId
      };

      data['description'] = description;
    }

    return data;
  }

  updateForm(device?: DeviceModuleInstance, plant?: PlantModuleInstance) {

    if (device) {
      this.form.patchValue({
        performances: device.performances,
        orders: device.orders,
        qualityControls: device.qualityControls,
        rawMaterial: device.rawMaterial,
        parameters: device.parameters,
        operatorsLogin: device.operatorsLogin,
        OrderModuleConfig: {
          isOnSatellite: device.OrderModuleConfig?.isOnSatellite,
          autoProgress: device.OrderModuleConfig?.autoProgress,
          validateProgress: device.OrderModuleConfig?.validateProgress,
          validateProgressQuality: device.OrderModuleConfig?.validateProgressQuality,
          workProcess: device.OrderModuleConfig?.workProcess,
          deltaTarget: device.OrderModuleConfig?.deltaTarget,
          targetScrapsDiff: device.OrderModuleConfig?.targetScrapsDiff,
          showOrderList: device.OrderModuleConfig?.showOrderList,
          multiOrder: device.OrderModuleConfig?.multiOrder,
          batch: device.OrderModuleConfig?.batch,
          batchRawMaterial: device.OrderModuleConfig?.batchRawMaterial,
          scanMaterial: device.OrderModuleConfig?.scanMaterial,
          pdfDocs: device.OrderModuleConfig?.pdfDocs,
          startOrder: device.OrderModuleConfig?.startOrder,
          endOrder: device.OrderModuleConfig?.endOrder
        }
      })
    } else {
      this.form.patchValue({
        description: this.plantDescription,
        performances: plant?.performances ?? false,
        orders: plant?.orders ?? false,
        qualityControls: plant?.qualityControls ?? false,
        rawMaterial: plant?.rawMaterial ?? false,
        parameters: plant?.parameters ?? false,
        operatorsLogin: plant?.operatorsLogin ?? false,
        OrderModuleConfig: {
          isOnSatellite: plant?.OrderModuleConfig?.isOnSatellite ?? false,
          autoProgress: plant?.OrderModuleConfig?.autoProgress ?? false,
          validateProgress: plant?.OrderModuleConfig?.validateProgress ?? false,
          validateProgressQuality: plant?.OrderModuleConfig?.validateProgressQuality ?? false,
          workProcess: plant?.OrderModuleConfig?.workProcess ?? false,
          deltaTarget: plant?.OrderModuleConfig?.deltaTarget ?? false,
          targetScrapsDiff: plant?.OrderModuleConfig?.targetScrapsDiff ?? false,
          showOrderList: plant?.OrderModuleConfig?.showOrderList ?? false,
          multiOrder: plant?.OrderModuleConfig?.multiOrder ?? false,
          batch: plant?.OrderModuleConfig?.batch ?? false,
          batchRawMaterial: plant?.OrderModuleConfig?.batchRawMaterial ?? false,
          scanMaterial: plant?.OrderModuleConfig?.scanMaterial ?? false,
          pdfDocs: plant?.OrderModuleConfig?.pdfDocs ?? false,
          startOrder: plant?.OrderModuleConfig?.startOrder ?? false,
          endOrder: plant?.OrderModuleConfig?.endOrder ?? false
        }
      })
    }

  }

  formatObjectValuesFromNumberToBoolean(object) {
    for (const property in object) {
      if (property !== "id" && property !== "deviceId" && property !== "plantId" && property !== "deviceModuleId") {
        if (typeof object[property] === "object") {
          object[property] = this.formatObjectValuesFromNumberToBoolean(object[property]);
        } else {
          object[property] = !!object[property]
        }
      }
    }
    return object;
  }

}
