import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AutocompleteComponent, FontService } from '@vapor/angular-ui';
import { faPlusCircle, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Subscription, of } from 'rxjs';

import { OrderStatus } from '../../../models/order-device.model';
import { ProductInstance } from '../../../models/product.model';
import { ProductService } from '../../../services/product.service';
import { DeviceInstance } from '../../../models/device.model';
import { OrdersCoreService } from '../../../services/ordersCore.service';
import { OrderCoreInstance } from '../../../models/order-core.model';
import { OrderListInterface } from '../../../models/orders-groups-view.model';
import { JsonTranslatorPipe } from '../../../pipes/json-translator.pipe';
import { DeviceService } from '../../../services/device.service';

interface ProductInterface {
    id: number;
    code: string;
    name: string;
    devices: DeviceInstance[];
    val: string;
}

interface SelectEntry {
    id: number;
    val: string;
}

interface JsonInfo {
    key: string;
    value: string;
}

@Component({
    selector: 'app-order-drawer',
    templateUrl: './order-drawer.component.html',
    styleUrls: ['./order-drawer.component.scss'],
    providers: [
        JsonTranslatorPipe
    ],    
})
export class OrderDrawerComponent implements OnInit, AfterViewInit {

    @ViewChild('product', { static: false }) productAutocomplete: AutocompleteComponent;

    statuses = [];
    products: ProductInstance[] = [];
    devices: DeviceInstance[] = [];
    additionalInfoOpen = true;

    form: FormGroup;

    @Input() order?: OrderListInterface;
    @Input() isEditing?: boolean;

    private _subscriptions: Subscription[] = [];
    customValidationDict = {
        required: 'common.inputErrors.required',
        min: 'common.inputErrors.min'
    };
    constructor(
        private readonly _products: ProductService,
        private readonly _ordersCore: OrdersCoreService,
        private readonly _translate: TranslateService,
        private readonly _font: FontService,
        private readonly _fb: FormBuilder,
        private readonly _devices: DeviceService,
    ) {
        this._font.addIcon(faPlusCircle, faTrash);

        this.form = this._fb.group({
            status: [null, [Validators.required]],
            code: [null, [Validators.required]],
            date: [null, [Validators.required]],
            target: [null, [Validators.required, Validators.min(0)]],
            targetChangeOver: [null, [Validators.min(0)]],
            product: [null, [Validators.required]],
            devices: [null, [Validators.required]],
            jsonInfoArray: this._fb.array([]),
        });
    }

    get formValid(): boolean {
        return this.form.valid;
    }

    get f() {
        return this.form.controls;
    }

    get jsonInfoArray(): FormArray {
        return this.form.get('jsonInfoArray') as FormArray;
    }

    async ngOnInit(): Promise<void> {
        const translationSubscription =
            this._translate.stream([
                'orders-list.status.draft',
                'orders-list.status.planned',
            ]).subscribe((translations) => {
                this.statuses = [
                    {
                        id: OrderStatus.draft,
                        val: translations['orders-list.status.draft'],
                    },
                    {
                        id: OrderStatus.planned,
                        val: translations['orders-list.status.planned'],
                    },
                ];
            });
        this._subscriptions.push(translationSubscription);
    }

    async ngAfterViewInit(): Promise<void> {
        // Load data
        await this.loadData();
        if (this.order) {
            this.reset();
            this.updateForm(this.order);
        }
        // Populate autocomplete
        const prods = this.products.map((product: ProductInstance) => this.mapProductToUi(product))
            .sort((a, b) => {
                const valA = a.val.toLowerCase();
                const valB = b.val.toLowerCase();
                
                if (valA < valB) {
                return -1;
                }
                if (valA > valB) {
                return 1;
                }
                return 0;
            });
        this.productAutocomplete.data$ = of(prods);
    }

    ngOnDestroy(): void {
        // Unsubscribe all subscriptions to avoid memory leaks
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    async loadData() {
        const plantId = Number(localStorage.getItem('plantId'));
        const companyId = Number(localStorage.getItem('companyId'));
        if (!this.products.length) {
            this.products = await this._products.getProducts(undefined, plantId, true, true, ['id', 'label']);
        }
        const devices = await this._devices.getDevicesByPlant(companyId, plantId);
        this.devices = devices.map((device: DeviceInstance) => this.mapDeviceToUi(device));
    }

    reset() {
        this.form.reset();
        this.jsonInfoArray.clear();
        this.additionalInfoOpen = true;
    }

    addNewJsonInfo() {
        const entryGroup = this._fb.group({
            key: '',
            value: '',
        });
        this.jsonInfoArray.push(entryGroup);
    }

    deleteJsonInfo(index: number) {
        if (!this.jsonInfoArray.length) {
            return;
        }
        this.jsonInfoArray.removeAt(index);
    }

    onAdditionalInfoToggle(open: boolean) {
        this.additionalInfoOpen = open;
    }

    async create(): Promise<OrderCoreInstance> {
        if (this.form.valid) {
            const plantId = Number(localStorage.getItem('plantId'));
            const code = this.form.get('code')?.value;
            const productId = this.form.get('product')?.value?.id;
            const deviceIds = this.form.get('devices')?.value?.map((entry: SelectEntry) => entry.id);
            const target = this.form.get('target')?.value;
            const targetChangeOver = this.form.get('targetChangeOver')?.value;
            const date = this.form.get('date')?.value;
            const status = this.form.get('status')?.value.id;
            const jsonInfo = this.createJsonInfoObject();

            // Make sure these are positive numbers, even though the form validation already
            // in place will prevent the user from reaching this point
            if (target < 0)
                throw new Error('Target must be a positive number');
            if (targetChangeOver < 0)
                throw new Error('Target must be a positive number');

            return await this._ordersCore.create(
                code, null, plantId, productId, null, deviceIds,
                target, targetChangeOver, date, undefined, jsonInfo, status, true
            );
        } else {
            throw new Error('New order form is not valid');
        }
    }

    async update(): Promise<OrderCoreInstance> {
        if (this.form.valid && this.order) {
            const plantId = Number(localStorage.getItem('plantId'));
            const code = this.form.get('code')?.value;
            const productId = this.form.get('product')?.value.id;
            const deviceIds = this.form.get('devices')?.value.map((entry: SelectEntry) => entry.id);
            const target = this.form.get('target')?.value;
            const targetChangeOver = this.form.get('targetChangeOver')?.value;
            const date = this.form.get('date')?.value;
            const status = this.form.get('status')?.value.id;
            const jsonInfo = this.createJsonInfoObject();

            return await this._ordersCore.update(
                this.order.id, code, null, plantId, productId, null, deviceIds,
                target, targetChangeOver, date, undefined, jsonInfo, status
            );
        } else {
            throw new Error('Edit order form is not valid');
        }
    }

    private createJsonInfoObject(): object {
        if (!this.jsonInfoArray?.length) {
            return {};
        }

        let object = {};

        const value = this.jsonInfoArray?.value || [];
        value.forEach((el: JsonInfo) => {
            if (el.key !== "" || el.key) {
                object[el.key] = el.value;
            }
        })

        if (Object.keys(object).length) {
            return object;
        }
        return {};
    } 

    private updateForm(order?: OrderListInterface) {
        // The Product field of order do not contain devices, so we get that from the products list
        const product = this.products.find((product: ProductInstance) => product.id === order?.Product?.id);
        // Devices selected in this order
        const hasDevice = (id: number) => {
            return order?.Devices?.find((device: DeviceInstance) => device.id === id);
        };
        const selectedDevices = this.devices.filter((entry: SelectEntry) => hasDevice(entry.id));

        this.form.patchValue({
            status: this.mapStatusToUi(order?.status),
            code: order?.code,
            date: order?.date,
            target: order?.target,
            targetChangeOver: order?.targetChangeOver,
            product: this.mapProductToUi(product),
            devices: selectedDevices,
            jsonInfoArray: [],
        });

        this.setJsonInfo(this.createJsonInfoArray(order));
    }

    private cloneObject(object: any) {
        return Object.assign({}, object);
    }

    private createJsonInfoArray(order?: OrderListInterface): JsonInfo[] {
        if (!order) {
            return [];
        }

        let jsonInfoParse = null;
        if (typeof order.jsonInfo === "object") {
            jsonInfoParse = this.cloneObject(order.jsonInfo);
        } else {
            jsonInfoParse = JSON.parse(order.jsonInfo || null);
        }
        let arr: JsonInfo[] = [];
        if (jsonInfoParse !== "{}" && jsonInfoParse && Object.keys(jsonInfoParse).length) {
            for (let key in jsonInfoParse) {
                arr.push({
                    key: key,
                    value: jsonInfoParse[key]
                })
            }
        }
        return arr;
    }

    private setJsonInfo(entries: JsonInfo[]) {
        entries.forEach((entry: JsonInfo) => {
            const entryGroup = this._fb.group({
                key: entry.key,
                value: entry.value,
            });
            this.jsonInfoArray.push(entryGroup);
        });
    }

    private mapStatusToUi(status?: OrderStatus): SelectEntry {
        return status ? this.statuses.find(entry => entry.id === status) : null;
    }

    private mapProductToUi(product?: ProductInstance): ProductInterface {
        if (product) {
            return {
                id: product.id,
                code: product.code,
                name: product.name,
                devices: product.Devices,
                val: `${product.code} - ${product.name}`,
            };
        } else {
            return null;
        }
    }

    private mapDeviceToUi(device?: DeviceInstance): SelectEntry {
        if (device) {
            return {
                id: device.id,
                val: device.label
            };
        } else {
            return null;
        }
    }

}
