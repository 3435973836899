import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceInstance } from '../../../models/device.model';
import { CompanyInstance } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { PlantInstance } from '../../../models/plant.model';

type DeviceType = 'new' | 'thirdparty' | 'virtual';

@Component({
  selector: 'app-new-device-drawer',
  templateUrl: './new-device-drawer.component.html',
  styleUrls: ['./new-device-drawer.component.scss']
})

export class NewDeviceDrawerComponent implements OnInit {

  @Input() device?: DeviceInstance;
  @Input() unipi?: number;
  @Input() deviceType: DeviceType;

  companies: CompanyInstance[];
  plants: PlantInstance[];
  selectedCompany: CompanyInstance | null;
  selectedPlant: PlantInstance;

  sessionTypes: any;
  companyTypes = ['Existing company', 'New company'];
  companyValue = -1;

  form: FormGroup;

  customValidationDict = {
    required: 'common.inputErrors.required',
    min: 'common.inputErrors.min'
  };

  constructor(private readonly _fb: FormBuilder, private _company: CompanyService) {
    this.form = this._fb.group({
      _unipi: [null, this.deviceType === 'new' && [Validators.required]],
      label: [null, [Validators.required]],
      __companyType: [null, [Validators.required]],
      company: [null],
      companyName: [null],
      plant: [{ value: null, disabled: true }],
      sessionType: [null],
    })
  }

  get f() {
    return this.form.controls;
  }

  get formValid(): boolean {
    return this.form.valid;
  }

  async ngOnInit() {
    this.companies = await this._company.getCompanies();

    if (this.deviceType === 'new' || this.deviceType === 'virtual') {
      this.updateSessionType(this.deviceType)
    }

    this.updateForm(this.deviceType);
  }


  onSubmit() {
    if (!this.form.valid) {
      return
    }

    const _device = {
      label: this.form.value.label,
      __companyType: this.companyValue === 0 ? 'existing' : 'new',
    };

    if (this.deviceType === 'new') {
      _device['unipi'] = this.form.get('_unipi').value;
    }

    if (this.deviceType === 'virtual' || this.deviceType === 'new') {
      _device['sessionType'] = this.form.value.sessionType.id;
    }

    if (this.companyValue === 0) {
      // Existing Company
      _device['plant'] = this.selectedPlant;
      _device['company'] = this.selectedCompany;
    } else {
      // New Company
      _device['company'] = { name: this.form.get('companyName').value };
      _device['provider'] = 2; // Hardcoded for Team System
    }

    return {
      device: _device,
      type: this.deviceType
    }

  }

  updateForm(deviceType: DeviceType) {
    if (deviceType === 'new') {
      this.form.patchValue({
        _unipi: this.unipi
      })
      this.form.get('_unipi')?.disable();
    }
  }

  updateSessionType(type: DeviceType) {
    if (type === 'new') {
      this.sessionTypes = [
        { id: 1, val: 'Start with product' },
        { id: 2, val: 'Timer Mode' },
        { id: 3, val: 'Timer Mode with threshold' },
        { id: 4, val: 'Instant change over' },
      ]
    }

    if (type === 'virtual') {
      this.sessionTypes = [
        { id: 1, val: 'Start with product' },
        { id: 2, val: 'Timer Mode' },
      ]
    }
  }

  handleCompanyChange(company: CompanyInstance) {
    if (!company) {
      return;
    }

    this.selectedCompany = company;
    this.plants = company.Plants;
    this.form.get('plant')?.enable();

    if (this.selectedPlant && !this.plants.some(plant => plant.id === this.selectedPlant.id)) {
      this.selectedPlant = null;
    }
  }

  handlePlantChange(plant: PlantInstance) {
    this.selectedPlant = plant;
  }

  handleCompanyTypeChange(type: number) {
    // type = 0 => Existing Company
    // type = 1 => New Company
    
    this.companyValue = type;

    // Reset Values when type changes

    this.selectedPlant = null;
    this.selectedCompany = null;

    if (type === 0) {
      this.form.get('company')?.setValidators([Validators.required]);
      this.form.get('plant')?.setValidators([Validators.required]);
      this.form.get('companyName')?.clearValidators();
    } else {
      this.form.get('companyName')?.setValidators([Validators.required]);
      this.form.get('company')?.clearValidators();
      this.form.get('plant')?.clearValidators();
    }

    // Existing Company
    this.form.get('company')?.reset();
    this.form.get('plant')?.reset();
    // New Company
    this.form.get('companyName')?.reset();
  }

}
