import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceInstance } from '../../../models/device.model';
import { ErrorInstance } from '../../../models/error.model';
import { DelayInstance } from '../../../models/delay.model';

interface SelectEntry {
  id: number;
  val: string;
}

@Component({
  selector: 'app-errors-drawer',
  templateUrl: './errors-drawer.component.html',
  styleUrls: ['./errors-drawer.component.scss']
})
export class ErrorsDrawerComponent implements OnInit {

  @Input() error?: ErrorInstance;
  @Input() delay?: DelayInstance;
  @Input() type: number; // 1 - Planned / 0 - Unplanned
  @Input() isEditing: boolean;

  @Input() devices: DeviceInstance[];
  @Input() selectedDevice: DeviceInstance;

  @Input() errorCategories: SelectEntry[];
  @Input() errorTags: SelectEntry[];

  tmpDevices: SelectEntry[] = [];
  selectedCategory: SelectEntry;
  selectedGroup: SelectEntry;

  form: FormGroup;

  customValidationDict = {
    required: 'common.inputErrors.required',
    min: 'common.inputErrors.min'
  };

  enabledSelectData: { id: number, val: string }[] = [
    { id: 0, val: 'NO' },
    { id: 1, val: 'YES' }
  ]

  selectedEnabledStatus: { id: number, val: string };

  constructor(private readonly _fb: FormBuilder) {
  }

  private initializeForm(): void {
    if (this.type === 0 || this.type === 1) {
      this.form = this._fb.group({
        code: [null, [Validators.required]],
        text: [null, [Validators.required]],
        target: [null],
        categoryId: [null, [Validators.required]],
        tagId: [null, [Validators.required]],
        Devices: [null],
        enabled: [false],
      });
    } else {
      this.form = this._fb.group({
        text: [null, [Validators.required]],
        Devices: [null],
        enabled: [true],
      });
    }
  }

  get f() {
    return this.form.controls;
  }

  get formValid(): boolean {
    return this.form.valid;
  }

  ngOnInit(): void {

    this.initializeForm();

    this.tmpDevices = this.devices.map((device) => this.mapDeviceToUi(device));

    this.selectedEnabledStatus = this.enabledSelectData[1]; // Default value 'YES'

    if (!this.isEditing && this.selectedDevice) {
      this.form.patchValue({
        Devices: [{
          id: this.selectedDevice.id,
          val: this.selectedDevice.label
        }]
      });
    }

    if (this.error) {
      this.form.reset();
      this.updateErrorForm(this.error);
    }

    if (this.delay) {
      this.form.reset();
      this.updateDelayForm(this.delay);
    }
  }

  onSubmit() {
    if (!this.formValid) {
      return;
    }

    let data = {
      type: this.type
    };

    if (this.type === 0 || this.type === 1) {
      const { enabled, tagId, categoryId, target, ...otherFormValues } = this.form.value

      data = {
        enabled: enabled.id === 1 ? true : false,
        tagId: tagId.id ?? tagId,
        categoryId: categoryId.id ?? categoryId,
        ...data,
        ...otherFormValues,
      }

      if (this.error) {
        data['id'] = this.error.id;
      }

      if (this.type === 1) { // Planned
        data['target'] = target ?? 0;
      }
    } else {
      const { enabled, text, ...otherFormValues } = this.form.value

      data = {
        enabled: enabled.id === 1 ? true : false,
        description: text,
        ...data,
        ...otherFormValues,
      }

      if (this.delay) {
        data['id'] = this.delay.id;
      }
    }

    return {
      isEditing: this.isEditing,
      data: data,
      type: this.type
    };
  }


  private updateErrorForm(error: ErrorInstance) {

    const selectedDevices = this.tmpDevices.filter((entry: SelectEntry) => this.hasDevice(entry.id));

    this.selectedCategory = this.errorCategories.find((category) => category.id === error.categoryId);
    this.selectedGroup = this.errorTags.find((tag) => tag.id === error.tagId);

    this.form.patchValue({
      code: error.code,
      text: error.text,
      target: error.target,
      categoryId: error.categoryId,
      tagId: error.tagId,
      Devices: selectedDevices,
      enabled: error.enabled ? this.enabledSelectData[1] : this.enabledSelectData[0]
    })

    this.selectedEnabledStatus = error.enabled ? this.enabledSelectData[1] : this.enabledSelectData[0];
  }

  private updateDelayForm(delay: DelayInstance) {
    const selectedDevices = this.tmpDevices.filter((entry: SelectEntry) => this.hasDevice(entry.id));

    this.form.patchValue({
      text: delay.description,
      Devices: selectedDevices,
      enabled: delay.enabled ? this.enabledSelectData[1] : this.enabledSelectData[0]
    })

    this.selectedEnabledStatus = delay.enabled ? this.enabledSelectData[1] : this.enabledSelectData[0]
  }

  private mapDeviceToUi(device?: DeviceInstance): SelectEntry {
    if (device) {
      return {
        id: device.id,
        val: device.label
      }
    } else {
      return null
    }
  }

  private hasDevice(id: number) {
    if (this.type === 0 || this.type === 1) {
      return this.error?.Devices?.find((device) => device.id === id) !== undefined;
    } else {
      return this.delay.Devices?.find((device) => device.id === id) !== undefined;
    }
  }
}
