<div class="users-page">
    <div class="users-page-content">
        <div class="header">
            <div class="column">
                <h2>{{'users.title' | translate}}</h2>
            </div>
        </div>
        <ng-container>
            <div class="ts-vapor ts-layout layout-container">
                <div class="new-operator-row">
                    <div class="column">
                        <div class="button-group-end">
                            <ts-button text="{{'users.addNew' | translate}}" type="primary medium blue"
                                       (buttonClicked)="showCreateOrUpdateUser()"></ts-button>
                        </div>
                    </div>
                </div>

                <ts-tree-list #usersList [data]="tmpUsers" [columns]="columns" parentKey="groupId" key="id"
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              [enablePaging]="false" [showSearchBar]="false" [hideActionsColumn]="true"
                              [showRowLines]="true" [showBorders]="false" [allowRowDeleting]="false"
                              [allowRowInserting]="false" [allowRowUpdating]="false" [wordWrapEnabled]="true">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-rowIndex let-level="level">
                        <app-users-list-cell [tplKey]="tplKey" [data]="data" (userInfoClicked)="onUserInfoClicked(data)"
                                             (editUserClicked)="showCreateOrUpdateUser(data.id)"
                                             (deleteUserClicked)="deleteUser(data)"></app-users-list-cell>
                    </ng-template>
                </ts-tree-list>
            </div>
        </ng-container>
    </div>
</div>


<ng-template #userInfoTemplate>
    <app-user-info [user]="selectedUser"></app-user-info>
</ng-template>

<ng-template #newUserTemplate>
    <app-user-drawer #userDrawer [plants]="plants"></app-user-drawer>
</ng-template>

<ng-template #editUserTemplate>
    <app-user-drawer #userDrawer [user]="selectedUser" [plants]="plants"></app-user-drawer>
</ng-template>

<ng-template #userDrawerDrawerBottomTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.save-button' | translate}}" (closePage)="onUserFormSubmit()"
                   [disableExit]="!userDrawer?.formValid">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>