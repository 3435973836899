import { Component, Input, OnInit } from '@angular/core';
import { OperatorInstance } from '../../../models/operator.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-operator-info',
  templateUrl: './operator-info.component.html',
  styleUrls: ['./operator-info.component.scss']
})
export class OperatorInfoComponent implements OnInit {

  @Input() operator?: OperatorInstance;

  constructor(private readonly _translate: TranslateService) { }

  ngOnInit(): void {
  }

}
