<ng-container *ngIf="tplKey === 'tplPlants'">
    <ts-tree-list-cell *ngIf="data?.Plants?.length === 0" cellText="{{ 'companies.all-plants' | translate}}"
                       [hyperlinkEnabled]="false" [buttonIsVisible]="false"></ts-tree-list-cell>
    <ts-tree-list-cell *ngIf="data?.Plants?.length > 0" [cellText]="data.Plants[0].description"
                       [hyperlinkEnabled]="false" [buttonIsVisible]="false"></ts-tree-list-cell>
</ng-container>

<ng-container *ngIf="tplKey === 'tplRole'">
    <ts-tree-list-cell *ngIf="data?.role === 3" cellText="Manager" [hyperlinkEnabled]="false"
                       [buttonIsVisible]="false"></ts-tree-list-cell>
    <ts-tree-list-cell *ngIf="data?.role === 1" cellText="Supervisor" [hyperlinkEnabled]=" false"
                       [buttonIsVisible]="false"></ts-tree-list-cell>
</ng-container>

<span *ngIf="tplKey === 'tplActions'">
    <ts-tree-list-button icon="window-maximize" title="Info" (click)="userInfo(data)"></ts-tree-list-button>
    <ts-tree-list-button icon="pencil" title="Edit" (click)="editUser(data.id)"></ts-tree-list-button>
    <ts-tree-list-button icon="trash-alt" [isDanger]="true" title="Delete"
                         (click)="deleteUser(data)"></ts-tree-list-button>
</span>