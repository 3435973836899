<form [formGroup]="form" [autocomplete]="false" class="container operator-drawer">
    <div class="row">
        <div class="column">
            <ts-text-box primaryText="{{'users.columns.firstName' | translate}}" placeholderText="{{'users.columns.firstName' | translate}}" formControlName="name"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
        <div class="column">
            <ts-text-box primaryText="{{'users.columns.lastName' | translate}}" placeholderText="{{'users.columns.lastName' | translate}}" formControlName="surname"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <ts-text-box primaryText="{{'orders-list.columns.code' | translate}}" placeholderText="{{'orders-list.columns.code' | translate}}" formControlName="code" [showErrors]="true"
                         width="300px" [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
        <div class="column">
            <div class="ts-input-container" width="300px">
                <div class="ts-input-container__header">
                    <div class="ts-input-container__header__primary">
                        <span class="ts-input-container__header__primary__text">
                            {{'orders-list.columns.devices' | translate}}
                        </span>
                    </div>
                    <span class="ts-input-container__header__optional"></span>
                </div>
                <div class="ts-input-container__data">
                    <!--
                            Vapor sets the ts-invalid class when showErrors is true regardless of whether the
                            control is invalid and touched like other components, such as ts-autocomplete,
                            do: the long term solution would be to fix Vapor, meanwhile we check the control
                            ourselves
                        -->
                    <ts-simple-select [data]="tmpDevices" [value]="isEditing && devices[0]" [multiSelect]="true"
                                      [clearable]="true" placeholderText="{{'orders-list.columns.devices' | translate}}" formControlName="devices"
                                      [validationDict]="customValidationDict | translateJSON | async"></ts-simple-select>
                </div>
            </div>

        </div>
    </div>
</form>