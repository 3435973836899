import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnFilterOperation, ColumnFilterTargetValues, TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { Subscription } from 'rxjs';

import { DeviceInstance } from '../../../models/device.model';
import { OrderListInterface } from '../../../models/orders-groups-view.model';
import { OrdersListService } from '../../../services/ordersList.service';

@Component({
    selector: 'app-add-orders-to-group',
    templateUrl: './add-orders-to-group.component.html',
    styleUrls: ['./add-orders-to-group.component.scss']
})
export class AddOrdersToGroupComponent implements OnInit {

    @Input() groupsList?: OrderListInterface[];
    @Input() selectedOrders?: OrderListInterface[];

    tabs = [];
    selectedTabId: number = 1;

    rows: OrderListInterface[];
    columns: TsTreeListColumn[] = [];

    selectedGroup?: OrderListInterface;

    private _subscriptions: Subscription[] = [];

    constructor(
        private readonly _translate: TranslateService,
        private readonly _ordersList: OrdersListService,
    ) { }

    ngOnInit(): void {
        const translateSubscription =
            this._translate.stream([
                'orders-list.group-association.group-selection',
                'orders-list.group-association.selected-orders',
                'orders-list.columns.code',
                'orders-list.columns.target',
                'orders-list.columns.product-description',
                'orders-list.columns.date',
                'orders-list.columns.devices',
            ]).subscribe((translations) => {
                this.tabs = [{
                    id: 1,
                    text: translations['orders-list.group-association.group-selection'],
                    disabled: false,
                }, {
                    id: 2,
                    text: translations['orders-list.group-association.selected-orders'],
                    disabled: false,
                }];

                const defaultColumn: TsTreeListColumn = {
                    dataField: '',
                    headerPlaceholder: translations['orders-list.search'],
                    allowFiltering: true,
                    allowEditing: false,
                    allowSorting: false,
                };
                this.columns =
                    [
                        { // id
                            caption: 'id',
                            dataField: 'id',
                            showColumn: false,
                        },
                        {
                            // selection
                            ...defaultColumn,
                            allowFiltering: false,
                            cellTemplate: 'tplRadioSelection',
                            width: 80,
                        },
                        { // code
                            ...defaultColumn,
                            caption: translations['orders-list.columns.code'],
                            dataField: 'lkpCode',
                            dataType: 'string',
                            cellTemplate: 'tplCode',
                            width: 150,
                        },
                        { // target
                            ...defaultColumn,
                            caption: translations['orders-list.columns.target'],
                            dataField: 'target',
                            dataType: 'number',
                            alignment: 'right',
                            width: 120,
                        },
                        { // product
                            ...defaultColumn,
                            caption: translations['orders-list.columns.product-description'],
                            dataField: 'productLabel',
                            dataType: 'string',
                        },
                        { // date
                            ...defaultColumn,
                            caption: translations['orders-list.columns.date'],
                            dataField: 'date',
                            dataType: 'date',
                            format: 'shortDate',
                            width: 135,
                        },
                        { // devices
                            ...defaultColumn,
                            caption: translations['orders-list.columns.devices'],
                            dataField: 'deviceLabels',
                            dataType: 'object',
                            width: 200,
                            cellTemplate: 'tplDevices',
                            calculateFilterExpression: this._plannedOrdersDevicesFilter,
                        },
                    ];
            });
        this._subscriptions.push(translateSubscription);
    }

    ngOnDestroy(): void {
        // Unsubscribe all subscriptions to avoid memory leaks
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
        });
    }

    reset(): void {
        this.selectedGroup = undefined;
        this.selectedTabId = 1;
    }

    onRadioSelected(order: OrderListInterface) {
        this.selectedGroup = order;
    }

    concatDevices(devices: DeviceInstance[]) {
        return devices.map((device: DeviceInstance) => device.label).filter((label: string) => label !== undefined).join(', ');
    }

    async add() {
        if (this.selectedOrders && this.selectedGroup) {
            const selectedOrderIds: number[] = this.selectedOrders!.map((order: OrderListInterface) => order.id);
            return await this._ordersList.association(selectedOrderIds, this.selectedGroup!.id);
        } else {
            throw new Error('No orders and/or group selected');
        }
    }

    private _plannedOrdersDevicesFilter(filterValue: unknown,
        selectedFilterOperation: ColumnFilterOperation,
        target: ColumnFilterTargetValues) {
        return ["deviceLabels", "contains", filterValue];
    }

}
