<form [formGroup]="form" [autocomplete]="false" class="container new-device-drawer">
    <div class="row" *ngIf="deviceType ==='new'">
        <div class="column">
            <ts-text-box primaryText="Unipi" placeholderText="Unipi" formControlName="_unipi" width="300px"
                         [showErrors]="true"></ts-text-box>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <ts-text-box primaryText="Label" placeholderText="Label" formControlName="label" [showErrors]="true"
                         width="300px" [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
        <div class="column">
            &nbsp;
        </div>
    </div>
    <div class="row">
        <div class="column">
            <div class="ts-input-container" width="300px">
                <div class="ts-input-container__header">
                    <div class="ts-input-container__header__primary">
                        <span class="ts-input-container__header__primary__text">
                            Company
                        </span>
                    </div>
                </div>
                <div class="ts-input-container__data">
                    <ts-radio name="__companyType" [items]="companyTypes" [(value)]="companyValue" [showErrors]="true"
                              formControlName="__companyType" (valueChange)="handleCompanyTypeChange($event)"
                              [validationDict]="customValidationDict | translateJSON | async"></ts-radio>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="companyValue === 0">
        <div class="column">
            <ts-select primaryText="Company ID" [data]="companies" placeholderText="Company ID" [clearable]="false"
                       [showErrors]="true" formControlName="company" [value]="selectedCompany" labelKey="name"
                       (valueChange)="handleCompanyChange($event)"
                       [validationDict]="customValidationDict | translateJSON | async"></ts-select>
        </div>
        <div class="column">
            <ts-select primaryText="Plant" [data]="plants" placeholderText="Plant" formControlName="plant"
                       [clearable]="false" [showErrors]="true" [value]="selectedPlant" labelKey="description"
                       (valueChange)="handlePlantChange($event)"
                       [validationDict]="customValidationDict | translateJSON | async"></ts-select>
        </div>
    </div>

    <div class="row" *ngIf="companyValue === 1">
        <div class="column">
            <ts-text-box primaryText="Company Name" placeholderText="Company Name" formControlName="companyName"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
    </div>

    <div class="row" *ngIf="deviceType === 'new' || deviceType === 'virtual'">
        <div class="column">
            <ts-select primaryText="Session Type" [data]="sessionTypes" placeholderText="Session Type"
                       formControlName="sessionType"
                       [validationDict]="customValidationDict | translateJSON | async"></ts-select>
        </div>
    </div>
</form>