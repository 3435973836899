import { Component, Input, OnInit } from '@angular/core';
import { UserInstance } from '../../../models/user.model';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  @Input() user: UserInstance;

  constructor() { }

  ngOnInit(): void {

  }

  getUserRole(user: UserInstance): string {
    return this.user.role === 1 ? 'Supervisor' : 'Manager';
  }

}
