<div class="loader-container" *ngIf="lockRequest">
    <img src={{getLoaderSrc()}}>
</div>
<div class="plugin-page">

    <div class="plugin-page-content">
        <div class="header">
            <div class="column">
                <h2>{{'plugin.title' | translate}}</h2>
            </div>
            <div class="column" *ngIf="devices?.length > 0">
                <div class="right">
                    <ng-container>
                        <ts-select [data]="devices" [value]="selectedDevice" labelKey="customLabel"
                                   (valueChange)="deviceChanged($event)" [enableSearch]="true"
                                   [clearable]="false"></ts-select>
                    </ng-container>
                </div>
            </div>
        </div>

        <ng-container>
            <div class="ts-vapor ts-layout layout-container">

                <div class="new-process-row">
                    <div class="column">
                        <div class="button-group-end">
                            <ts-button text="{{'plugin.columns.addNew' | translate}}" type="primary medium blue"
                                       (buttonClicked)="createOrUpdatePlugin()"></ts-button>
                        </div>
                    </div>
                </div>

                <ts-tree-list #pluginList [data]="tmpPlugins" [columns]="columns" parentKey="groupId" key="id"
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              actionsColumnCaption="" groupedActionsTooltipText="" [enablePaging]="false"
                              [showSearchBar]="false" [hideActionsColumn]="true" [showRowLines]="true"
                              [showBorders]="false" [allowRowDeleting]="false" [allowRowInserting]="false"
                              [allowRowUpdating]="false" [wordWrapEnabled]="true" [height]="pluginListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data>
                        <span *ngIf="tplKey === 'tplActions'">

                            <ts-tree-list-button icon="pencil" title="Edit"
                                                 (click)="createOrUpdatePlugin(data)"></ts-tree-list-button>
                        </span>
                    </ng-template>
                </ts-tree-list>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #newPluginTemplate>
    <app-plugin-drawer #pluginDrawer></app-plugin-drawer>
</ng-template>

<ng-template #editPluginTemplate>
    <app-plugin-drawer #pluginDrawer [plugin]="selectedPlugin"></app-plugin-drawer>

</ng-template>

<ng-template #pluginDrawerBottomTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.save-button' | translate}}" (closePage)="onFormSubmit()"
                   [disableExit]="!pluginDrawer?.formValid">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>