<form [formGroup]="form" [autocomplete]="false" class="container plugin-drawer">
    <div class="row">
        <div class="column">
            <ts-text-box primaryText="{{'plugin.columns.code' | translate}}"
                         placeholderText="{{'plugin.columns.code' | translate}}" formControlName="code"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
        <div class="column">
            <ts-text-box primaryText="{{'plugin.columns.description' | translate}}"
                         placeholderText="{{'plugin.columns.description' | translate}}" formControlName="description"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <ts-select primaryText="{{'plugin.columns.enabled' | translate}}" [data]="booleanSelectData"
                       placeholderText="{{'plugin.columns.enabled' | translate}}" [clearable]="false"
                       [enableSearch]="true" [showErrors]="true" formControlName="enabled" [value]="selectedEnabled"
                       [validationDict]="customValidationDict | translateJSON | async"></ts-select>
        </div>
        <div class="column">
            <ts-select primaryText="{{'plugin.columns.isOnSatellite' | translate}}" [data]="booleanSelectData"
                       placeholderText="{{'plugin.columns.isOnSatellite' | translate}}" [clearable]="false"
                       [enableSearch]="true" [showErrors]="true" formControlName="isOnSatellite"
                       [value]="selectedSatellite"
                       [validationDict]="customValidationDict | translateJSON | async"></ts-select>
        </div>
    </div>
</form>