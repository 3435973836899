import { Component, Input, OnInit } from '@angular/core';
import { TagInstance } from '../../../models/tag.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-tags-drawer',
  templateUrl: './tags-drawer.component.html',
  styleUrls: ['./tags-drawer.component.scss']
})
export class TagsDrawerComponent implements OnInit {
  @Input() tag?: TagInstance;

  form: FormGroup;

  customValidationDict = {
    required: 'common.inputErrors.required',
    min: 'common.inputErrors.min'
  };

  constructor(private readonly _fb: FormBuilder) {
    this.form = this._fb.group({
      name: [null, [Validators.required]]
    })
  }

  get formValid(): boolean {
    return this.form.valid;
  }

  ngOnInit(): void {
    if (this.tag) {
      this.form.reset();

      this.form.patchValue({
        name: this.tag.name
      })
    }
  }

  onSubmit() {
    return {
      id: this.tag ? this.tag.id : null,
      ...this.form.value
    }
  }


}
