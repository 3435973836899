<div class="categories-page">
    <div class="categories-list-content">
        <div class="header">
            <div class="column">
                <h2>{{'categories.title' | translate}}</h2>
            </div>
            <div class="column">
                <div class="right">
                    <ng-container *ngIf="companies?.length > 1 && isAdmin">
                        <ts-select [data]="companies" [value]="selectedCompany.name" [clearable]="false"
                                   [enableSearch]="true" (valueChange)="companyChanged($event)"
                                   labelKey="name"></ts-select>
                    </ng-container>
                </div>
            </div>
        </div>

        <ts-tab-panel [panelTabs]="tabs" type="primary" barType="standard" size="small" [(selectedTab)]="selectedTabId"
                      [selectTabOnClick]="true" (selectedTabChange)="handleTabChange($event)">
        </ts-tab-panel>

        <ng-container [ngSwitch]="selectedTabId">
            <div class="ts-vapor ts-layout layout-container">
                <div class="new-category-row">
                    <div class="column">
                        <div class="button-group-end">
                            <ts-button text="{{'users.addNew' | translate}}" type="primary medium blue"
                                       (buttonClicked)="showCreateOrUpdateError()"></ts-button>
                        </div>
                    </div>
                </div>
                <!-- Planned -->
                <ts-tree-list *ngSwitchCase="1" #categoriesList [data]="tmpPlannedList" [columns]="tmpPlannedColumns"
                              parentKey="groupId" key="id"
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              actionsColumnCaption="" groupedActionsTooltipText="" emptyStateSecondaryText=""
                              emptyStateSecondaryLink="" [enablePaging]="false" [showSearchBar]="false"
                              [hideActionsColumn]="true" [showRowLines]="true" [showBorders]="false"
                              [allowRowDeleting]="false" [allowRowInserting]="false" [allowRowUpdating]="false"
                              [wordWrapEnabled]="true" [height]="categoriesListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
                        <span *ngIf="tplKey === 'tplActions' && data.companyId">

                            <ts-tree-list-button icon="pencil" title="Edit"
                                                 (click)="showCreateOrUpdateError(data)"></ts-tree-list-button>
                            <ts-tree-list-button icon="trash-alt" title="Delete" [isDanger]="true"
                                                 (click)="delete(data)"></ts-tree-list-button>
                        </span>
                    </ng-template>
                </ts-tree-list>

                <!-- Unplanned -->
                <ts-tree-list *ngSwitchCase="0" #categoriesList [data]="tmpUnPlannedList"
                              [columns]="tmpUnPlannedColumns" parentKey="groupId" key="id"
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              actionsColumnCaption="" groupedActionsTooltipText="" emptyStateSecondaryText=""
                              emptyStateSecondaryLink="" [enablePaging]="false" [showSearchBar]="false"
                              [hideActionsColumn]="true" [showRowLines]="true" [showBorders]="false"
                              [allowRowDeleting]="false" [allowRowInserting]="false" [allowRowUpdating]="false"
                              [wordWrapEnabled]="true" [height]="categoriesListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
                        <span *ngIf="tplKey === 'tplActions' && data.companyId">

                            <ts-tree-list-button icon="pencil" title="Edit"
                                                 (click)="showCreateOrUpdateError(data)"></ts-tree-list-button>
                            <ts-tree-list-button icon="trash-alt" title="Delete" [isDanger]="true"
                                                 (click)="delete(data)"></ts-tree-list-button>
                        </span>
                    </ng-template>
                </ts-tree-list>
            </div>
        </ng-container>

    </div>
</div>

<ng-template #newErrorsTemplate>
    <app-category-drawer #categoryDrawer></app-category-drawer>
</ng-template>

<ng-template #editErrorsTemplate>
    <app-category-drawer #categoryDrawer [categoryError]="selectedErrorCategory"></app-category-drawer>
</ng-template>

<ng-template #errorsDrawerBottomTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.save-button' | translate}}" (closePage)="onFormSubmit()"
                   [disableExit]="!categoryDrawer?.formValid">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicke()"></ts-button>
    </ts-bottom-bar>
</ng-template>