<div class="operators-page">
    <div class="operators-page-content">
        <div class="header">
            <div class="column">
                <h2>{{'operators.title' | translate}}</h2>
            </div>
            <div class="column" *ngIf="plants?.length > 1">
                <div class="right">
                    <ng-container>
                        <ts-select [data]="plants" [value]="selectedPlant?.description" labelKey="description"
                                   (valueChange)="plantChanged($event)" [clearable]="false"></ts-select>
                    </ng-container>
                </div>
            </div>
        </div>
        <ng-container *ngIf="moduleActivated && !lockRequest">
            <div class="ts-vapor ts-layout layout-container">
                <div class="new-operator-row">
                    <div class="column">
                        <div class="button-group-end">
                            <ts-button text="{{'users.addNew' | translate}}" type="primary medium blue"
                                       (buttonClicked)="showCreateOrUpdateOperatorDrawer()"></ts-button>
                        </div>
                    </div>
                </div>

                <ts-tree-list #operatorsList [data]="tmpOperators" [columns]="columns" parentKey="groupId" key="id"
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              actionsColumnCaption="" groupedActionsTooltipText="" emptyStateSecondaryText=""
                              emptyStateSecondaryLink="" [enablePaging]="false" [showSearchBar]="false"
                              [hideActionsColumn]="true" [showRowLines]="true" [showBorders]="false"
                              [allowRowDeleting]="false" [allowRowInserting]="false" [allowRowUpdating]="false"
                              [wordWrapEnabled]="true" [height]="operatorsListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>

                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
                        <app-operators-list-cell [tplKey]="tplKey" [data]="data"
                                                 (operatorInfoClicked)="onOperatorInfoClicked(data)"
                                                 (deleteOperatorClicked)="deleteOperator(data.id)"
                                                 (editOperatorClicked)="showCreateOrUpdateOperatorDrawer(data.id)"></app-operators-list-cell>
                    </ng-template>
                </ts-tree-list>
            </div>
        </ng-container>
    </div>

    <div *ngIf="!moduleActivated && !lockRequest" class="not-available" style="margin-top: 10px;">
        {{'operators.not-available' | translate}}</div>

</div>


<ng-template #operatorInfoTemplate>
    <app-operator-info [operator]="selectedOperator"></app-operator-info>
</ng-template>

<ng-template #operatorInfoBottomBarTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar [showExit]="false">
        <ts-button type="tertiary blue medium" text="{{'orders-list.orderInfo.cancelButton' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>

<ng-template #newOperatorTemplate>
    <app-operator-drawer #operatorDrawer [operators]="tmpOperators" [devices]="devices"></app-operator-drawer>
</ng-template>

<ng-template #editOperatorTemplate>
    <app-operator-drawer #operatorDrawer [operators]="tmpOperators" [operator]="selectedOperator" [isEditing]="true"
                         [devices]="devices"></app-operator-drawer>
</ng-template>

<ng-template #operatorDrawerBottomTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.save-button' | translate}}" (closePage)="onOperatorFormSubmit()"
                   [disableExit]="!operatorDrawer?.formValid">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>