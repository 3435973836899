import { AfterViewChecked, Component, ElementRef, Input, Output, QueryList, Renderer2, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { AutocompleteComponent, FontService, SimpleSelectComponent } from '@vapor/angular-ui';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { faPlusCircle, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { ProductionUnitInstance } from '../../../models/productionUnit.model';
import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WorksService } from '../../../services/works.service';

@Component({
    selector: 'order-editor-new-order',
    templateUrl: './order-editor-new-order.component.html',
    styleUrls: ['./order-editor-new-order.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OrderEditorNewOrderComponent implements AfterViewChecked{

    @Input() editMode: boolean;

    @Input() step: number;

    @Input() stepList: { currentStep: string, description: string }[];

    @Input() formOrderData: FormGroup;

    @Input() formCycleData: FormGroup;

    @Input() additionalInfoForm: FormGroup;

    @Input() variation: string;

    @Input() selectStatusOrderData: {id: number, val: string} [] = [];

    @Input() selectResourceTypeData: {id: number, val: string} [] = [];

    @Input() f: any;

    @Input() additionalInfoOpen: boolean = false;

    @Input() toggleAdditionalInfo: () => void;
    
    @Input() keyValueArray: any;

    @Input() phasesArray: any;

    @Input() phasesList: any;

    @Input() companyId: number;

    @Input() plantHasComponentsListModule: boolean;

    @Output() handleNewInfoButtonClickedEvent = new EventEmitter<void>();

    @Output() handleRemoveInfoButtonClickedEvent = new EventEmitter<number>();

    @Output() openComponentsListEvent = new EventEmitter<number>();

    @Output() onAddPhaseEvent = new EventEmitter<number>();

    @Output() resourceTypeSelected = new EventEmitter<{ id: number; val: string; index: number; }>();

    @Output() workIdChangeEvent = new EventEmitter<{ index: number, work: string }>();

    @Output() trashClickEvent = new EventEmitter<number>();
    
    @Output() intervallChangeEvent = new EventEmitter<{value: number;index: number, intervall: string}>();

    @ViewChild('product', { static: false }) productAutocomplete: AutocompleteComponent;

    @Input() selectedProductProductionUnit: ProductionUnitInstance;

    @ViewChildren('worksSelect') worksAutocompleteList: QueryList<AutocompleteComponent>;

    @ViewChildren('resourcesSelect') resourcesSelectList: QueryList<SimpleSelectComponent>;

    @ViewChild('numberBox', { static: false }) numberBox: ElementRef;

    addComma: boolean = true;

    customValidationDict = {
        required: 'common.inputErrors.required',
        min: 'common.inputErrors.min'
    };

    private _subscriptions: Subscription[] = [];

    constructor(
        private readonly _font: FontService,
        private readonly _translate: TranslateService,
        private readonly _works: WorksService,
        private renderer: Renderer2
    ) {
        this._font.addIcon(faPlusCircle, faTrashAlt);
    }

    ngAfterViewChecked(): void {
        if (!this.addComma) return;
        const allInputs = document.querySelectorAll('input[type="number"]');
    
        if (allInputs.length === 3) {    
            allInputs.forEach(i => {
                const inputElement = i as HTMLInputElement;    
                let initialValue = inputElement.value;
    
                if (inputElement.getAttribute('type') === 'number') {
                    inputElement.setAttribute('type', 'text');
                }
    
                inputElement.addEventListener('input', (event: any) => {
                    let value = event.target.value;
    
                    value = value.replace(',', '.');
                    value = value.replace('e', '');
                    value = value.replace('-', '.');
                    value = value.replace('+', '.');
                    const regex = /^[0-9]*\.?[0-9]*$/;
                    if (!regex.test(value)) {
                        event.target.value = initialValue;
                        return;
                    }
    
                    const [integer, decimal] = value.split('.');
    
                    if (decimal && decimal.length > 4) {
                        value = `${integer}.${decimal.slice(0, 4)}`;
                    }
                    
                    initialValue = value;
                    event.target.value = value;
                    event.target.dataset.previousValue = value;
                }, {
                    capture: true,
                    once: false,
                    passive: false
                });
            });

            this.addComma = false;
        }
    }
        
    ngOnDestroy(): void {
        // Unsubscribe all subscriptions to avoid memory leaks
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
            
        });
    }

    onOpenComponentsList(index: number) {
        this.openComponentsListEvent.emit(index);
    }

    onAddPhase(index: number) {
        this.onAddPhaseEvent.emit(index);
    }

    onHandleNewInfoButtonClicked() {
        this.handleNewInfoButtonClickedEvent.emit();
    }

    onHandleRemoveInfoButtonClicked(i) {
        this.handleRemoveInfoButtonClickedEvent.emit(i);
    }

    onResourceTypeChange(data: {id: number, val: string}, index: number) {
        this.resourceTypeSelected.emit({ ...data, index });
    }

    trashClick(index: number) {
        this.trashClickEvent.emit(index);
    }

    onWorkIdChange(index: number, data: {id: number, val: string}) {
        this.workIdChangeEvent.emit({ index: index, work: data?.val });
    }

    setupTimeChanged(index: number, event: any) {
        this.intervallChangeEvent.emit({value: event, index: index, intervall: 'setupTime'});
    }

    totalWorkTimeChanged(index: number, event: any) {
        console.log(index, event);
        this.intervallChangeEvent.emit({value: event, index: index, intervall: 'totalWorkTime'});
    }

    padStartCustom(str: string, length: number, padChar: string): string {
        while (str.length < length) {
            str = padChar + str;
        }
        return str;
    }
    
    convertMinutesToHHMMSS(minutes: number): string {
        // Calcola le ore (parte intera dei minuti divisa per 60)
        const hours = Math.floor(minutes / 60);
        
        // Calcola i minuti rimanenti (parte intera dei minuti dopo aver estratto le ore)
        const remainingMinutes = Math.floor(minutes % 60);
        
        // Calcola i secondi dalla parte decimale dei minuti
        const decimalPart = minutes - Math.floor(minutes); // Parte decimale dei minuti
        const seconds = Math.round(decimalPart * 60); // Converte la parte decimale in secondi
      
        // Restituisce il formato HH:MM:SS
        return `${this.padStartCustom(String(hours), 2, '0')}h ${this.padStartCustom(String(remainingMinutes), 2, '0')}m ${this.padStartCustom(String(seconds), 2, '0')}s`;
      }
}
