import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Drawer, FontService, NotificationConfig, NotificationService, OverlaySpinnerService, PopupBodyConfig, PopupConfig, PopupService } from '@vapor/angular-ui';
import { DrawerInstanceInfo } from '@vapor/angular-ui/drawer/drawer-data.model';
import { Subscription } from 'rxjs';

import { AuthService } from '../../../services/auth.service';
import { CompanyService } from '../../../services/company.service';
import { DeviceService } from '../../../services/device.service';
import { ModulesService } from '../../../services/modules.service';
import { NavbarService } from '../../../services/navbar.service';
import { PlantService } from '../../../services/plant.service';
import { SidebarService } from '../../../services/sidebar.service';

import { TranslateService } from '@ngx-translate/core';

import { CompanyInstance } from '../../../models/company.model';
import { DeviceInstance } from '../../../models/device.model';
import { PlantInstance } from '../../../models/plant.model';
import { UserRole } from '../../../models/user.model';
import { DeviceModuleInstance } from '../../../models/device-module.model';

import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';

import { CompanyDrawerComponent } from '../../ui/company-drawer/company-drawer.component';
import { DevicesDrawerComponent } from '../../ui/devices-drawer/devices-drawer.component';

import { faPencil, faPlus, faQrcode, faSave, faWrench } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
  providers: [
    NotificationService,
  ],
  encapsulation: ViewEncapsulation.None
})
export class DevicesComponent implements OnInit {

  isAdmin = false;
  tmpDevices: DeviceInstance[];
  devicesColumns: TsTreeListColumn[] = [];

  selectedDevice: DeviceInstance;

  companyID: number;
  selectedCompany: CompanyInstance;
  plants: PlantInstance[];
  selectedPlant: PlantInstance;
  newPlant: PlantInstance;

  devicesListHeight = '100vh';
  private _subscriptions: Subscription[] = [];

  @ViewChild('devicesList', { static: false, read: ElementRef }) devicesListRef!: ElementRef;
  @ViewChild('devicesDrawerBottomTemplate', { static: false }) devicesDrawerBottomTemplate: TemplateRef<any>;
  @ViewChild('editDeviceDrawerTemplate', { static: false }) editDeviceDrawerTemplate: TemplateRef<any>;
  @ViewChild('showDeviceQRDrawerTemplate', { static: false }) showDeviceQRDrawerTemplate: TemplateRef<any>;
  @ViewChild('companyDeviceDrawerTemplate', { static: false }) companyDeviceDrawerTemplate: TemplateRef<any>;
  @ViewChild('companyPlantDrawerTemplate', { static: false }) companyPlantDrawerTemplate: TemplateRef<any>;
  @ViewChild('newCompanyPlantTemplate', { static: false }) newCompanyPlantTemplate: TemplateRef<any>;
  @ViewChild('companyDrawerBottomTemplate', { static: false }) companyDrawerBottomTemplate: TemplateRef<any>;

  @ViewChild('devicesDrawer', { static: false }) devicesDrawer: DevicesDrawerComponent;
  @ViewChild('companyDrawer', { static: false }) companyDrawer: CompanyDrawerComponent;

  constructor(
    private _device: DeviceService,
    private _navbar: NavbarService,
    private _sidebar: SidebarService,
    private _drawer: Drawer,
    private _popup: PopupService,
    private _notification: NotificationService,
    private _company: CompanyService,
    private _modulesService: ModulesService,
    private _plant: PlantService,
    private _translate: TranslateService,
    private _font: FontService,
    private _spinner: OverlaySpinnerService,
    public auth: AuthService
  ) {
    this._font.addIcon(faPencil, faQrcode, faSave, faWrench, faPlus);
  }

  async ngOnInit() {
    this._spinner.show();
    this._navbar.setTitle('Devices');
    setTimeout(() => this._sidebar.setSelected('devices'));

    this.companyID = Number(localStorage.getItem('companyId'));
    if (this.companyID) {
      this.selectedCompany = await this._company.getCompany(this.companyID, true);
    }

    if (!!this.auth && !!this.auth.user) {
      this.isAdmin = this.auth.user.role === UserRole.admin;
    } else {
      console.error('No auth service/user');
    }

    this._translate.stream([
      'devices.columns.id',
      'devices.columns.name',
      'devices.columns.serial',
      'devices.columns.company',
      'devices.columns.stopThreshold',
      'devices.columns.startThreshold',
      'devices.columns.multiplier',
      'devices.columns.scraps-multiplier',
      'devices.columns.enabled',
      'orders-list.search',
    ]).subscribe((translations) => {

      const defaultColumn: TsTreeListColumn = {
        dataField: '',
        headerPlaceholder: translations['orders-list.search'],
        allowFiltering: true,
        allowEditing: false,
        allowSorting: false,
        alignment: 'left',
        width: '100%'
      };

      this.devicesColumns = [
        {
          // Device Label ( Name )
          ...defaultColumn,
          dataField: 'label',
          caption: translations['devices.columns.name'],
          dataType: 'string',
        },
        {
          // stopThreshold
          ...defaultColumn,
          dataField: "stopProductsThreshold",
          caption: translations['devices.columns.stopThreshold'],
          dataType: 'number',
          allowSorting: true,
          calculateCellValue: (rowData) => this.getStopTreshold(rowData),
          calculateDisplayValue: (rowData) => this.displayUnit(this.getStopTreshold(rowData), 'units.sec')
        },
        {
          // startThreshold
          ...defaultColumn,
          dataField: 'resumeProductionProductsThreshold',
          caption: translations['devices.columns.startThreshold'],
          dataType: 'number',
          allowSorting: true,
          calculateCellValue: (rowData) => this.getStartTreshold(rowData),
          calculateDisplayValue: (rowData) => this.displayUnit(this.getStartTreshold(rowData), 'units.pieces'),
        },
        {
          // multiplier
          ...defaultColumn,
          dataField: 'multiplier',
          caption: translations['devices.columns.multiplier'],
          dataType: 'number',
          allowSorting: true,
          calculateCellValue: (rowData) => this.getMultiplier(rowData),
          calculateDisplayValue: (rowData) => this.displayUnit(this.getMultiplier(rowData), 'units.pieces')
        },
        {
          // scrapsMultiplier
          ...defaultColumn,
          dataField: 'scrapsMultiplier',
          caption: translations['devices.columns.scraps-multiplier'],
          dataType: 'number',
          allowSorting: true,
          calculateCellValue: (rowData) => this.getScrapMultiplier(rowData),
          calculateDisplayValue: (rowData) => this.displayUnit(this.getScrapMultiplier(rowData), 'units.pieces')
        },
        { // actions 
          ...defaultColumn,
          caption: '',
          width: 135,
          allowFiltering: false,
          allowSorting: false,
          cellTemplate: 'tplActions',
          fixedPosition: 'right',
          alignment: 'center',
        },
      ]

      if (this.isAdmin) {
        this.devicesColumns.splice(0, 0, {
          // id
          caption: translations['devices.columns.id'],
          dataField: 'id',
          allowEditing: false,
          allowFiltering: false,
          width: 80,
        });

        this.devicesColumns.splice(2, 0, {
          // serial
          ...defaultColumn,
          dataField: 'Unipi.serial',
          caption: translations['devices.columns.serial'],
          dataType: 'string',
        });

        this.devicesColumns.splice(this.devicesColumns.length - 1, 0, {
          // enabled
          caption: translations['devices.columns.enabled'],
          dataField: 'enabled',
          dataType: 'boolean',
          allowFiltering: false,
          allowEditing: false,
          allowSorting: false,
          width: 70,
          calculateCellValue: (rowData) => this.getEnabledStatus(rowData),
        })

      }
    });

    const companyChangeSubscription =
      this._company.changeCompanyEmitted$.subscribe(company => {
        this.companyChanged(company);
      });

    this._subscriptions.push(companyChangeSubscription);

    try {
      this.selectedCompany = await this._company.getCompany(this.companyID, true);

      if (this.selectedCompany) {
        this.plants = this.selectedCompany.Plants;
        this.selectedPlant = this.plants[0]; // Default Plant
        const _devices = this.filterDevicesByPlantAndCompany(this.selectedPlant.id, this.selectedCompany);
        this.tmpDevices = _devices.sort((a, b) => a.label.localeCompare(b.label)); // Sort devices by label ASC
      }

    } catch (error) {
      console.log("Error while loading table")
      this.showNotification(this._translate.instant('devices.error'), 'error');
    } finally {
      this._spinner.removeOverlay();
    }

  }

  ngAfterContentChecked(): void {
    // We do it here because the ngSwitch has already been evaluated
    if (this.devicesListRef) {
      const offsetTop = this.devicesListRef.nativeElement.offsetTop;
      this.devicesListHeight = `calc(100vh - ${offsetTop}px)`;
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe all subscriptions to avoid memory leaks
    this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
      subscription.unsubscribe();
      array.splice(index, 1);
    });
  }

  // Device table actions

  /**
   * Function which handles to show drawer based on the action clicked
   * @param data DeviceInstance
   * @param edit boolean - default false / if true then edit drawer will be shown else QR code
  */
  onActionClick(data: DeviceInstance, edit: boolean = false) {
    if (this._drawer.isOpened()) {
      this._drawer.close();
    }

    this.selectedDevice = {
      ...data,
      stopProductsThreshold: Number(this.getStopTreshold(data)),
      resumeProductionProductsThreshold: Number(this.getStartTreshold(data)),
      multiplier: Number(this.getMultiplier(data)),
      scrapsMultiplier: Number(this.getScrapMultiplier(data)),
      enabled: this.getEnabledStatus(data)
    };

    // Open drawer
    this._drawer.open({
      title: edit ? this._translate.instant('devices.single') : this._translate.instant('devices.single') + ' - QR',
      subTitle: '',
      expanded: false,
      marginTop: 0,
      marginBottom: 0,
      showPushButton: false,
      showOpenCloseButton: false,
      width: (300 * 2) + (16 * 2) + 32,
      contentTemplate: edit ? this.editDeviceDrawerTemplate : this.showDeviceQRDrawerTemplate,
      bottomBarTemplate: this.devicesDrawerBottomTemplate,
    }).subscribe((drawer: DrawerInstanceInfo) => {
      console.info(`New Operator drawer open: ${drawer.drawerId}`);
      this._drawer.expand();
    });

  }

  newPlantAction() {
    if (this._drawer.isOpened()) {
      this._drawer.close();
    }

    this.newPlant = {
      id: -1,
      description: '',
      companyId: this.selectedCompany.id,
    }

    this._drawer.open({
      title: this._translate.instant('companies.add-plant'),
      subTitle: '',
      expanded: false,
      marginTop: 0,
      marginBottom: 0,
      showPushButton: false,
      showOpenCloseButton: false,
      width: (300 * 2) + (16 * 2) + 32,
      contentTemplate: this.newCompanyPlantTemplate,
      bottomBarTemplate: this.companyDrawerBottomTemplate,
    }).subscribe((drawer: DrawerInstanceInfo) => {
      console.info(`New Plant drawer open: ${drawer.drawerId}`);
      this._drawer.expand();
    });


  }

  onCompanyActionClick(device?: DeviceInstance) {
    if (this._drawer.isOpened()) {
      this._drawer.close();
    }

    if (device) {
      this.selectedDevice = device;
    }

    this._drawer.open({
      title: device ? device.label : this.selectedPlant.description,
      subTitle: '',
      expanded: false,
      marginTop: 0,
      marginBottom: 0,
      showPushButton: false,
      showOpenCloseButton: false,
      width: (300 * 2) + (16 * 2) + 32,
      contentTemplate: device ? this.companyDeviceDrawerTemplate : this.companyPlantDrawerTemplate,
      bottomBarTemplate: this.companyDrawerBottomTemplate,
    }).subscribe((drawer: DrawerInstanceInfo) => {
      console.info(`New Company drawer open: ${drawer.drawerId}`);
      this._drawer.expand();
    });


  }

  async save() {

    const device: DeviceInstance = this.devicesDrawer.onSubmit();

    try {
      await this._device.updateDevice(device.id, {
        label: device.label,
        stopProductsThreshold: device.stopProductsThreshold,
        resumeProductionProductsThreshold: device.resumeProductionProductsThreshold,
        multiplier: device.multiplier,
        scrapsMultiplier: device.scrapsMultiplier,
        enabled: device.enabled,
        inputMultiplier: device.inputMultiplier,
      });
      this.tmpDevices = this.tmpDevices.map((d) => d.id === device.id ? device : d);
      this.showNotification(this._translate.instant('devices.save'), 'check');
    } catch (err) {
      console.error(err);
      this.showNotification(this._translate.instant('devices.error'), 'error');
    } finally {
      this._drawer.close();
    }
  }

  async saveDeviceModule(deviceModuleId: number, data: any) {
    try {
      let result: DeviceModuleInstance;
      if (deviceModuleId) {
        result = await this._modulesService.updateDeviceModule(deviceModuleId, data);
      } else {
        result = await this._modulesService.createDeviceModule(data);
      }
      if (result) {
        if (deviceModuleId) {
          const deviceFound = this.tmpDevices.find(d => d.DeviceModule && d.DeviceModule.id === deviceModuleId);
          if (deviceFound) {
            deviceFound.DeviceModule = result;
          }
        } else {
          const deviceFound = this.tmpDevices.find(d => d.id === data?.deviceData?.deviceId);
          if (deviceFound) {
            deviceFound.DeviceModule = result;
          }
        }

        this._drawer.close();
        this.showNotification(this._translate.instant('companies.deviceModule.alert.updated-successfully'), 'check');
        
      }
    } catch (error) {
      console.log(error)
      this.showNotification(this._translate.instant('companies.error'), 'error');
    }
  }

  async savePlantModule(plantModuleId: number, data: any) {

    const { description, ...otherData } = data; // Extract description, modules API doesn't allow description in the body

    try {
      const result = await this._modulesService.udpatePlantModule(plantModuleId, otherData);

      if (this.selectedPlant.description !== description) {
        this.selectedPlant.description = description;

        this.plants = this.plants.map((p) => p.id === this.selectedPlant.id ? this.selectedPlant : p);

        await this._plant.update(this.selectedPlant.id, this.selectedPlant.description);

      }

      if (result) {
        this.showNotification(this._translate.instant('companies.plantModule.alert.updated-successfully'), 'check');
        this._drawer.close();

        this.plants = this.plants.map((p) => p.PlantModule && p.PlantModule.id === plantModuleId ? {
          ...p, PlantModule: {
            OrderModuleConfig: data.orderData,
            ...data.plantData
          }
        } : p);

        this.selectedPlant = {
          ...this.selectedPlant,
          PlantModule: {
            OrderModuleConfig: data.orderData,
            plantId: this.selectedPlant.id,
            id: plantModuleId,
            ...data.plantData,
          }
        }
      }
    } catch (error) {
      console.log(error)
      this.showNotification(this._translate.instant('companies.error'), 'error');
    }
  }

  async saveNewPlant(plant: any) {
    try {
      delete plant.id; // Remove id from plant object ( id: -1 )
      const res = await this._plant.create(plant);
      if (res) {
        this.plants = [...this.plants, res];

        this.showNotification(this._translate.instant('companies.plantModule.alert.updated-successfully'), 'check');
      } else {
        this.showNotification(this._translate.instant('companies.error'), 'error');
      }
    } catch (error) {
      console.log(error);
      this.showNotification(this._translate.instant('companies.error'), 'error');
    } finally {
      this._drawer.close();
    }
  }


  async deleteDeviceModule() {
    const deviceModuleId = this.selectedDevice.DeviceModule.id;

    const popupConfig: PopupConfig = {
      title: 'Reset Config',
      level: 'error',
      showCloseButton: true,
      visible: true,
      dragEnabled: false,
      bottomButtons: {
        primary: {
          text: this._translate.instant('dialogs.delete.confirm'),
        },
        tertiary: {
          text: this._translate.instant('dialogs.delete.cancel'),
        },
      },
    };
    const bodyConfig: PopupBodyConfig = {
      content: this._translate.instant('categories.dialog.text', { prod: this.selectedDevice.label }),
    };

    this._popup.show(popupConfig, bodyConfig).subscribe(async (result) => {
      try {
        if (result === 'primary') {
          const res = await this._modulesService.deleteDeviceModule(deviceModuleId);

          if (res) {
            this.tmpDevices.find((d) => d.DeviceModule && d.DeviceModule.id === deviceModuleId).DeviceModule = null;

            this.showNotification(this._translate.instant('companies.deviceModule.alert.deleted-successfully'), 'check');

          } else {
            this.showNotification(this._translate.instant('companies.deviceModule.alert.deleted-error'), 'error');
          }
        } else {
          console.log('Canceled');
        }
      } catch (error) {
        console.log(error);
        this.showNotification(this._translate.instant('companies.error'), 'error');
      } finally {
        this._drawer.close();
      }
    })

  }

  onCompanyFormSubmit() {
    const { type: submitType, ...result } = this.companyDrawer.onSubmit();

    if (submitType === 'device') {
      this.saveDeviceModule(this.selectedDevice.DeviceModule?.id, result);
    } else if (submitType === 'plant') {
      this.savePlantModule(this.selectedPlant.PlantModule.id, result);
    } else { // new-plant
      const { plantId, ...otherPlantData } = result.plantData;
      const new_plant = {
        ...this.newPlant,
        description: result.description,
        orderData: result.orderData,
        plantData: otherPlantData,
      }
      this.saveNewPlant(new_plant);
    }
  }

  private async companyChanged(company: CompanyInstance) {
    this._spinner.show();

    this.companyID = company.id;
    this.selectedCompany = await this._company.getCompany(this.companyID, true);

    if (this.selectedCompany) {
      this.plants = this.selectedCompany?.Plants;
      this.selectedPlant = this.plants[0]; // Default Plant
      const _devices = this.filterDevicesByPlantAndCompany(this.selectedPlant.id, this.selectedCompany);
      this.tmpDevices = _devices.sort((a, b) => a.label.localeCompare(b.label)); // Sort devices by label ASC
      localStorage.setItem('plantId', this.selectedPlant.id.toString());
    } else {
      // Clear devices & plants if there is no company
      this.plants = [];
      this.tmpDevices = [];
    }

    this._spinner.removeOverlay();

    localStorage.setItem('companyId', String(this.companyID));

  }

  private filterDevicesByPlantAndCompany(plantId: number, company: CompanyInstance) {
    if (company.Devices) {
      return company.Devices.filter((d) => d.plantId === plantId);
    }

    return []; // No Devices
  }

  async plantChanged(plant?: PlantInstance) {

    if (plant) {
      this.selectedPlant = plant;
    }

    if (this.selectedPlant) {

      localStorage.setItem('plantId', this.selectedPlant.id.toString());
      this._spinner.show();
      const _devices = this.filterDevicesByPlantAndCompany(this.selectedPlant.id, this.selectedCompany);
      this.tmpDevices = _devices.sort((a, b) => a.label.localeCompare(b.label)) // Sort ASC
      this._spinner.removeOverlay();

    }
  }

  cloneArray(array: any[]) {
    return array.map(x => Object.assign({}, x));
  }

  cloneObject(object: any) {
    return Object.assign({}, object);
  }

  getStopTreshold(row: DeviceInstance): string {
    if (row.stopProductsThresholdSec) {
      return '' + row.stopProductsThresholdSec;
    } else {
      return '35';
    }
  }

  getStartTreshold(row: DeviceInstance): string {
    if (row.resumeProductionProductsThreshold) {
      return '' + row.resumeProductionProductsThreshold;
    } else {
      return '30';
    }
  }

  getMultiplier(row: DeviceInstance): string {
    if (row.multiplier) {
      return '' + row.multiplier;
    } else {
      return '1';
    }
  }

  getScrapMultiplier(row: DeviceInstance): string {
    if (row.scrapsMultiplier) {
      return '' + row.scrapsMultiplier;
    } else {
      return '1';
    }
  }

  getEnabledStatus(row: DeviceInstance): boolean {
    if (typeof row.enabled != 'undefined') {
      return row.enabled;
    }

    return true;
  }

  private displayUnit(value: string, unit: string): string {
    const _unit = this._translate.instant(unit);
    return value + ' ' + _unit;
  }

  onCancelDrawerClicked() {
    this._drawer.close();
  }

  private showNotification(message: string, style: 'alert' | 'check' | 'error' | 'generic'): void {
    const config: NotificationConfig = {
      content: message,
      type: 'toast',
      style: style,
      timeout: 5000,
      position: 'right',
    }

    this._notification.show(config);
  }

}

