import { Component, Input, OnInit } from '@angular/core';
import { DeviceInstance } from '../../../models/device.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-devices-drawer',
  templateUrl: './devices-drawer.component.html',
  styleUrls: ['./devices-drawer.component.scss']
})
export class DevicesDrawerComponent implements OnInit {

  @Input() isAdmin: boolean;
  @Input() device: DeviceInstance;
  @Input() isEditing: boolean;

  form: FormGroup;

  customValidationDict = {
    required: 'common.inputErrors.required',
    min: 'common.inputErrors.min'
  };

  enabledSelectData: { id: number, val: string }[] = [
    { id: 0, val: 'NO' },
    { id: 1, val: 'YES' }
  ]

  selectedEnabledStatus: { id: number, val: string };

  constructor(private readonly _fb: FormBuilder, private _translate: TranslateService) {
    this.form = this._fb.group({
      label: [null],
      stopProductsThreshold: [null, [Validators.required]],
      resumeProductionProductsThreshold: [null, [Validators.required]],
      multiplier: [null, [Validators.required]],
      inputMultiplier: [null, [Validators.required]],
      scrapsMultiplier: [null, [Validators.required]],
      enabled: [false, [Validators.required, Boolean]]
    })
  }

  get f() {
    return this.form.controls;
  }

  get formValid(): boolean {
    return this.form.valid;
  }

  ngOnInit(): void {
    this.selectedEnabledStatus = this.enabledSelectData[0];

    if (this.isEditing) {
      this.updateForm();
    }
  }


  onSubmit() {
    if (this.form.invalid) {
      return
    }

    this.device = {
      ...this.device,
      label: this.form.value.label,
      enabled: this.form.value.enabled.id === 1 ? true : false
    }

    if (this.getDeviceStopMode().val === 'from-threshold') {
      this.device = {
        ...this.device,
        resumeProductionProductsThreshold: this.form.value.resumeProductionProductsThreshold,
      }
    }

    if (this.getDeviceStopMode().val === 'from-threshold' || this.getDeviceStopMode().val === 'manual-threshold') {
      this.device = {
        ...this.device,
        stopProductsThreshold: this.form.value.stopProductsThreshold,
      }
    }

    if (this.getDeviceMode().val !== 'timer-mode') {
      this.device = {
        ...this.device,
        multiplier: this.form.value.multiplier,
        scrapsMultiplier: this.form.value.scrapsMultiplier,
      }
    }

    if (this.getDeviceMode().val === 'count-pcs-delta') {
      this.device = {
        ...this.device,
        inputMultiplier: this.form.value.inputMultiplier,
      }
    }

    return this.device;
  }

  updateForm() {
    this.form.patchValue({
      label: this.device.label,
      stopProductsThreshold: this.device.stopProductsThreshold,
      resumeProductionProductsThreshold: this.device.resumeProductionProductsThreshold,
      multiplier: this.device.multiplier,
      scrapsMultiplier: this.device.scrapsMultiplier,
      inputMultiplier: this.device.inputMultiplier,
      enabled: this.device.enabled ? this.enabledSelectData[1] : this.enabledSelectData[0]
    });

    this.selectedEnabledStatus = this.device.enabled ? this.enabledSelectData[1] : this.enabledSelectData[0]
  }

  getDeviceMode(): {
    val: string,
    type: string
  } {

    if ((this.device.sessionType === 0 || this.device.sessionType === 1 || this.device.sessionType === 3) && this.device.Unipi.sensorType === 0) {
      return {
        val: 'count-pcs',
        type: this._translate.instant('devices.drawer.count-pcs')
      }
    } else if (this.device.sessionType === 2) {
      return {
        val: 'timer-mode',
        type: this._translate.instant('devices.drawer.timer-mode')
      }
    } else {
      return {
        val: 'count-pcs-delta',
        type: this._translate.instant('devices.drawer.count-pcs-delta')
      }
    }

  }

  getDeviceStopMode(): {
    val: string,
    type: string
  } {

    const { manualStartStopProduction } = this.device.Unipi;

    if (manualStartStopProduction === 0) {
      return {
        val: 'from-threshold',
        type: this._translate.instant('devices.drawer.from-threshold')
      }
    } else if (manualStartStopProduction === 1) {
      return {
        val: 'manual-device-input',
        type: this._translate.instant('devices.drawer.manual-di')
      }
    } else {
      return {
        val: 'manual-threshold',
        type: this._translate.instant('devices.drawer.manual-threshold')
      }
    }
  }

}
