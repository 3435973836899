import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ConfigService } from './config.service';
import { ProductsFamilyInstance } from '../models/productsFamily.model';

@Injectable({
    providedIn: 'root'
})
export class ProductFamilyService {
    private readonly _baseUrl = 'productsFamily';

    constructor(
        private _http: HttpService,
        private _config: ConfigService,
    ) { }

    async getFamilies(companyId: number): Promise<ProductsFamilyInstance[]> {
        let url = `${this._config.API.urlV2}/${this._baseUrl}/company/${companyId}`;
        const response = (await this._http.get<any>(url)).body;
        return response;
    }

    async getFamily(id: number): Promise<ProductsFamilyInstance> {
        let url = `${this._config.API.urlV2}/${this._baseUrl}/id/${id}`;
        const response = (await this._http.get<any>(url)).body;
        return response;
    }

    async createFamily(family: ProductsFamilyInstance) {
        let url = `${this._config.API.urlV2}/${this._baseUrl}`;
        await this._http.post<ProductsFamilyInstance>(url, family);
    }

    async updateFamily(family: ProductsFamilyInstance) {
        let url = `${this._config.API.urlV2}/${this._baseUrl}/${family.familyId}`;
        await this._http.put<ProductsFamilyInstance>(url, family);
    }

    async deleteFamily(id: number) {
        let url = `${this._config.API.urlV2}/${this._baseUrl}/${id}`;
        let response;
        try {
            response = await this._http.delete(url);
        } catch (error) {
            response = error;
        }
        return response ? response.status : null;        
    }
}
