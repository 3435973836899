<form [formGroup]="form" [autocomplete]="false" class="container operator-drawer">
    <div class="row">
        <div class="column">
            <ts-text-box primaryText="{{'users.columns.firstName' | translate}}"
                         placeholderText="{{'users.columns.firstName' | translate}}" formControlName="firstName"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
        <div class="column">
            <ts-text-box primaryText="{{'users.columns.lastName' | translate}}"
                         placeholderText="{{'users.columns.lastName' | translate}}" formControlName="lastName"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <ts-text-box primaryText="{{'users.columns.email' | translate}}"
                         placeholderText="{{'users.columns.email' | translate}}" formControlName="email"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
        <div class="column">
            <ts-select primaryText="{{'users.columns.role' | translate}}" [data]="roles" [value]="selectedRole"
                       [clearable]="true" placeholderText="{{'users.columns.role' | translate}}" formControlName="role"
                       [validationDict]="customValidationDict | translateJSON | async"></ts-select>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <ts-select primaryText="Plant" [data]="tmpPlants" [value]="selectedPlant" [clearable]="true"
                       placeholderText="Plant" formControlName="plant"
                       [validationDict]="customValidationDict | translateJSON | async"
                       (valueChange)="handlePlantChange($event)"></ts-select>
        </div>
    </div>

    <div class="row">
        <div class="column info-label">
            <span style="white-space: pre-line;">
                {{'users.columns.role-tooltip' | translate}}
            </span>
        </div>
    </div>
</form>