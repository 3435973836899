import { OnInit, Component, ViewEncapsulation, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { UserRole } from '../../../models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../../services/device.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { ProductService } from '../../../services/product.service';
import { AuthService } from '../../../services/auth.service';
import { CompanyInstance } from '../../../models/company.model';
import { DeviceInstance } from '../../../models/device.model';
import { CompanyService } from '../../../services/company.service';
import { ControlIstance } from '../../../models/control.model';
import { ControlsService } from '../../../services/controls.service';
import { ProductInstance } from '../../../models/product.model';
import { Router } from '@angular/router';
import { TaskIstance } from '../../../models/task.model';
import { ControlSchedulingIstance } from '../../../models/control-scheduling.model';
import { PlantInstance } from '../../../models/plant.model';
import { Utility } from '../../../utils/utility';
import { PageWithLoader } from "../page-with-loader";
import { ThemeService } from "../../../services/theme.service";
import { Subscription } from 'rxjs';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { faPencil, faCopy, faTrashAlt, faWindowMaximize } from '@fortawesome/pro-regular-svg-icons';
import { Drawer, FontService, NotificationConfig, NotificationService, PopupBodyConfig, PopupConfig, PopupService } from '@vapor/angular-ui';



@Component({
    selector: 'app-quality-controls',
    templateUrl: './quality-controls.component.html',
    styleUrls: ['./quality-controls.component.scss'],
    providers: [
        NotificationService
    ],
    encapsulation: ViewEncapsulation.None
})


export class QualityControlsComponent extends PageWithLoader implements OnInit {

    lockRequest = false;

    isAdmin: boolean;

    columns: TsTreeListColumn[] = [];

    /** only if is normal user */
    company: CompanyInstance;

    plants: PlantInstance[] = [];

    selectedCompany: CompanyInstance;

    selectedPlant: PlantInstance;

    selectedCompanyId: number;

    selectedPlantId: number;

    controls: ControlIstance[];
    selectedControl: ControlIstance;

    devices: DeviceInstance[];

    products: ProductInstance[];
    selectedProduct: ProductInstance;

    tasks: TaskIstance[];

    private _subscriptions: Subscription[] = [];

    controlsListHeight = '100vh';

    @ViewChild('controlsList', { static: false, read: ElementRef }) controlsListRef!: ElementRef;
    @ViewChild('controlInfoTemplate', { static: true }) controlInfoTemplate: TemplateRef<any>;

    constructor(
        private _products: ProductService,
        private _controls: ControlsService,
        private _devices: DeviceService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _company: CompanyService,
        private _drawer: Drawer,
        private _popup: PopupService,
        private _notification: NotificationService,
        private _translate: TranslateService,
        private _router: Router,
        private _utility: Utility,
        private readonly _font: FontService,
        public auth: AuthService,
        _themeService: ThemeService
    ) {
        super(_themeService);
        this._font.addIcon(faPencil, faCopy, faTrashAlt, faWindowMaximize);
    }

    async ngOnInit(): Promise<void> {

        const translationSubscription =
            this._translate.stream([
                'quality-controls.title',
                'quality-controls.columns.description',
                'quality-controls.columns.schedulings',
                'quality-controls.columns.devices',
                'quality-controls.columns.products',
                'quality-controls.columns.tasks',
                'quality-controls.columns.enabled'
            ]).subscribe((translations) => {
                this._navbar.setTitle(translations['quality-controls.title']);
                setTimeout(() => this._sidebar.setSelected('quality-controls'));

                const defaultColumn: TsTreeListColumn = {
                    dataField: '',
                    allowFiltering: false,
                    allowEditing: false,
                    allowSorting: false,
                    allowResizing: false,
                    width: '100%'
                }

                this.columns = [{
                    ...defaultColumn,
                    dataField: 'description',
                    caption: translations["quality-controls.columns.description"],
                    dataType: 'string',
                    cellTemplate: 'tplDescription'
                }, {
                    ...defaultColumn,
                    dataField: 'customSchedulingLabels',
                    caption: translations["quality-controls.columns.schedulings"],
                    dataType: 'string',
                    cellTemplate: "tplScheduling"
                }, {
                    ...defaultColumn,
                    dataField: 'Devices',
                    caption: translations["quality-controls.columns.devices"],
                    dataType: 'object',
                    cellTemplate: 'tplDevices',
                }, {
                    ...defaultColumn,
                    dataField: 'Products',
                    caption: translations["quality-controls.columns.products"],
                    dataType: 'object',
                    cellTemplate: 'tplProducts'
                }, {
                    ...defaultColumn,
                    dataField: 'Tasks',
                    caption: translations["quality-controls.columns.tasks"],
                    dataType: 'object',
                    cellTemplate: 'tplTasks',
                }, {
                    ...defaultColumn,
                    dataField: 'enabled',
                    caption: translations["quality-controls.columns.enabled"],
                    dataType: 'boolean',
                },
                { // actions
                    ...defaultColumn,
                    caption: '',
                    fixedPosition: 'right',
                    alignment: 'center',
                    width: 200,
                    cellTemplate: 'tplActions',
                }]

            });
        this._subscriptions.push(translationSubscription);

        this.lockRequest = true;
        this.selectedCompanyId = Number(localStorage.getItem('companyId'));
        this.selectedPlantId = Number(localStorage.getItem('plantId'));

        // check if user is Admin
        this.isAdmin = this.auth.user.role === UserRole.admin;

        try {
            if (this.isAdmin) {
                await this.companyChanged(await this._company.getCompany(this.selectedCompanyId));
            } else {
                // if is user get company detailt to get plants
                this.company = await this._company.getCompany(this.selectedCompanyId);
                // set plants
                this.plants = this.company.Plants;
                await this._handleBuildPlantArrayObject(true);
                await this.plantChanged(this.selectedPlant);
            }
        } catch (error) {
            console.log("Error loading data")
            console.log(error)
        }

        const companyChangeSubscription =
            this._company.changeCompanyEmitted$.subscribe(company => {
                if (this.isAdmin) {
                    this.companyChanged(company);
                }
            });
        this._subscriptions.push(companyChangeSubscription);

        this.lockRequest = false;
    }

    ngAfterContentChecked(): void {
        // We do it here because the ngSwitch has already been evaluated
        if (this.controlsListRef) {
            const offsetTop = this.controlsListRef.nativeElement.offsetTop;
            this.controlsListHeight = `calc(100vh - ${offsetTop}px)`;
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe all subscriptions to avoid memory leaks
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe(); 
        });
    }

    showControlInfo(control: ControlIstance) {

        if (this._drawer.isOpened()) {
            this._drawer.close();
        }

        this.selectedControl = control;

        // Open drawer
        this._drawer.open({
            title: this._translate.instant('quality-controls.title'),
            subTitle: this.selectedControl.description,
            expanded: false,
            marginTop: 0,
            marginBottom: 0,
            showPushButton: false,
            showOpenCloseButton: false,
            width: (300 * 2) + (16 * 2) + 32,
            contentTemplate: this.controlInfoTemplate,
            // bottomBarTemplate: this.operatorDrawerBottomTemplate,
        }).subscribe(() => {
            this._drawer.expand();
        });
    }

    // only if user is admin can change company
    async companyChanged(company: CompanyInstance) {
        this.selectedCompany = company;
        this.selectedCompanyId = company.id;
        this.plants = company.Plants;
        const isSelectedPlantInCurrentCompany = this.selectedPlantId ?
            company.Plants.find((p) => p.id === this.selectedPlantId) : false;
        await this._handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany ? true : false);
        await this.plantChanged(this.selectedPlant);
    }

    async _handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany: boolean) {
        // set plant
        if (!this.selectedPlantId || !isSelectedPlantInCurrentCompany) {
            this.selectedPlant = this.plants[0];
        } else if (this.plants.length > 0) {
            this.selectedPlant = this.plants.find((p) => p.id === this.selectedPlantId);
        }
    }

    async plantChanged(plant: PlantInstance) {
        this.selectedPlant = plant;

        if (this.selectedPlant) {
            this.selectedPlantId = this.selectedPlant.id;
            localStorage.setItem('plantId', this.selectedPlantId.toString());

            // get check for selected company
            const controls = await this._controls.getControls(this.selectedCompanyId, this.selectedPlantId);
            this.controls = this._cleanControlFromDisableTask(controls);


            // get devices for selected plant
            this.devices = await this._devices.getDevicesByPlant(this.selectedCompanyId, this.selectedPlantId);

            // get product for selected plant
            const res = await this._products.getProducts(null, this.selectedPlantId);
            const updated_products = res.map((product) => ({
                ...product,
                name: `${product.name} - ${product.code}`
            }));

            this.products = [{
                // Adding 'All Products' option with the id -1 for filtering purposes
                id: -1,
                code: this._translate.instant('quality-controls.all-controls'),
                companyId: this.selectedCompanyId,
                Devices: [],
                enabled: true,
                name: this._translate.instant('quality-controls.all-controls'),
            }, ...updated_products];

            this.selectedProduct = this.products[0];
        }
    }

    // if product change fetch new controls
    async productChanged(product: ProductInstance) {
        this.selectedProduct = product;
        // if value is -1 all products are selected so fetch all controls for this company
        if (this.selectedProduct.id === -1) {
            // get all controls for this company
            const res = await this._controls.getControls(this.selectedCompanyId, this.selectedPlantId);
            this.controls = this._mapControls(res);
        } else {
            // get all controls for given product
            const res = await this._controls.getControls(this.selectedCompanyId, this.selectedPlantId, this.selectedProduct.id);
            this.controls = this._mapControls(res);
        }
    }

    // add control, create new control then open controls detail page
    async addControl() {
        // HTTP reques to create new controls
        const check = await this._controls.create(this.selectedCompanyId, this.selectedPlantId);
        if (check) {
            this._router.navigate(['/quality-controls', check.id, 'details']);
        } else {
            this.showNotification(this._translate.instant('quality-controls.created-error'), 'error');
        }
    }

    // deleting control
    async deleteControl(controlId: number) {
        if (!controlId) return;

        const control = this.controls.find(c => c.id === controlId);

        try {
            const popupConfig: PopupConfig = {
                title: this._translate.instant('categories.dialog.text', { prod: control.description }),
                level: 'error',
                showCloseButton: true,
                visible: true,
                dragEnabled: false,
                bottomButtons: {
                    primary: {
                        text: this._translate.instant('dialogs.delete.confirm'),
                    },
                    tertiary: {
                        text: this._translate.instant('dialogs.delete.cancel'),
                    },
                },
            };

            const bodyConfig: PopupBodyConfig = {
                content: this._translate.instant('categories.dialog.text', { prod: control.description })
            };

            this._popup.show(popupConfig, bodyConfig).subscribe(async (result) => {

                if (result === 'primary') {
                    const res = await this._controls.delete(controlId);
                    if (res.status === 200) {
                        this.controls = this._mapControls(this.controls.filter(c => c.id !== controlId));
                        this.showNotification(this._translate.instant('quality-controls.deleted-successfully'), 'check');
                    } else {
                        this.showNotification(this._translate.instant('quality-controls.deleted-error'), 'error');
                    }

                    this.controls = this._mapControls(this._utility.cloneArray(this.controls));
                } else {
                    return;
                }
            })
        } catch (error) {
            this.showNotification(this._translate.instant('quality-controls.deleted-error'), 'error');
            console.log(error)
        }


    }

    async duplicateControl(controlId: number) {

        if (controlId) {
            const res = await this._controls.duplicate(controlId);
            if (res) {
                this.controls = this._mapControls([...this.controls, res]);
                // this.controls.push(res);
                this.showNotification(this._translate.instant('quality-controls.duplicated-successfully'), 'check');
            } else {
                this.showNotification(this._translate.instant('quality-controls.duplicated-error'), 'error');
            }
        }

        this.controls = this._utility.cloneArray(this.controls);
    }


    // change enabled proprerty of control, passing controlId
    async enablingControl(controlId: number) {

        if (controlId) {
            const data = { enabled: !this.controls.find(c => c.id === controlId).enabled };
            const res = await this._controls.update(controlId, data);
            if (res) {
                this.controls[this.controls.findIndex(c => c.id === controlId)].enabled = res.enabled;
            } else {
                // TO-DO Messaggio di errore
                this.showNotification(this._translate.instant('quality-controls.saved-error'), 'error');
            }
        }

        this.controls = this._utility.cloneArray(this.controls);
    }

    async edit(row: ControlIstance) {
        this._router.navigate(['/quality-controls', row.id, 'details']);
    }

    _formattingSchedulingLabel(schedulings: ControlSchedulingIstance[]): string[] {
        const labels: string[] = [];
        const piece = this._translate.instant('units.piece');
        const pieces = this._translate.instant('units.pieces');
        const minute = this._translate.instant('units.minute');
        const minutes = this._translate.instant('units.minutes');

        for (const sche of schedulings) {

            if (sche.type === 2 && sche.quantity === 0) {
                labels.push(this._translate.instant('quality-controls.scheduling.startup',
                    {
                        prod: sche.repeat,
                        pieces1: sche.repeat > 1 ? pieces : piece
                    }));
            } else if (sche.type === 1 && !sche.cycle) {
                labels.push(this._translate.instant('quality-controls.scheduling.after-pieces',
                    {
                        prod: sche.repeat,
                        pieces1: sche.repeat > 1 ? pieces : piece,
                        item: sche.quantity,
                        pieces2: sche.quantity > 1 ? pieces : piece
                    }));
            } else if (sche.type === 1 && sche.cycle) {
                labels.push(this._translate.instant('quality-controls.scheduling.each-pieces',
                    {
                        prod: sche.repeat,
                        pieces1: sche.repeat > 1 ? pieces : piece,
                        item: sche.quantity,
                        pieces2: sche.quantity > 1 ? pieces : piece
                    }));
            } else if (sche.type === 2 && !sche.cycle) {
                labels.push(this._translate.instant('quality-controls.scheduling.after-minutes',
                    {
                        prod: sche.repeat,
                        pieces1: sche.repeat > 1 ? pieces : piece,
                        item: sche.quantity,
                        pieces2: sche.quantity > 1 ? minutes : minute
                    }));
            } else if (sche.type === 2 && sche.cycle) {
                labels.push(this._translate.instant('quality-controls.scheduling.each-minutes',
                    {
                        prod: sche.repeat,
                        pieces1: sche.repeat > 1 ? pieces : piece,
                        item: sche.quantity,
                        pieces2: sche.quantity > 1 ? minutes : minute
                    }));
            } else if (sche.type === 3) {
                labels.push(this._translate.instant('quality-controls.scheduling.after-stop',
                    {
                        prod: sche.repeat,
                        pieces1: sche.repeat > 1 ? pieces : piece
                    }));
            }

        }

        return labels;
    }

    private _cleanControlFromDisableTask(controls: ControlIstance[]) {
        const cleanControls: ControlIstance[] = [];
        for (const control of controls) {
            control.Tasks = control.Tasks.filter((task) => {
                return task.ControlsTasks.enabled;
            });

            cleanControls.push(control);
        }

        return this._mapControls(cleanControls);
    }

    private _mapControls(controls: ControlIstance[]): ControlIstance[] {
        return controls.map((control) => ({
            ...control,
            customSchedulingsLabels: this._formattingSchedulingLabel(control.ControlSchedulings),
        }))
    }

    private showNotification(message: string, style: 'alert' | 'check' | 'error' | 'generic'): void {
        const config: NotificationConfig = {
            content: message,
            type: 'toast',
            style: style,
            timeout: 5000,
            position: 'right',
        }

        this._notification.show(config);
    }
}
