import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserInstance } from '../../../models/user.model';

@Component({
  selector: 'app-users-list-cell',
  templateUrl: './users-list-cell.component.html',
  styleUrls: ['./users-list-cell.component.scss']
})
export class UsersListCellComponent implements OnInit {

  @Input() tplKey: any;
  @Input() data: any;

  @Output() userInfoClicked = new EventEmitter<UserInstance>();
  @Output() editUserClicked = new EventEmitter<number>();
  @Output() deleteUserClicked = new EventEmitter<UserInstance>();

  constructor() { }

  ngOnInit(): void {
  }

  userInfo(data: UserInstance) {
    this.userInfoClicked.emit(data);
  }

  editUser(id: number) {
    this.editUserClicked.emit(id);
  }

  deleteUser(data: UserInstance) {
    this.deleteUserClicked.emit(data);
  }




}
