import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlIstance } from '../../../models/control.model';

@Component({
  selector: 'app-quality-controls-list-cell',
  templateUrl: './quality-controls-list-cell.component.html',
  styleUrls: ['./quality-controls-list-cell.component.scss']
})
export class QualityControlsListCellComponent implements OnInit {

  @Input() tplKey: any;
  @Input() data: ControlIstance[];

  @Output() controlInfo = new EventEmitter<ControlIstance>();
  @Output() deleteControl = new EventEmitter<number>();
  @Output() duplicateControl = new EventEmitter<number>();
  @Output() editControl = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  qualityControlInfo(control: ControlIstance) {
    this.controlInfo.emit(control);
  }

  deleteQualityControl(controlId: number) {
    this.deleteControl.emit(controlId);
  }

  duplicateQualityControl(controlId: number) {
    this.duplicateControl.emit(controlId);
  }

  editQualityControl(control: ControlIstance) {
    this.editControl.emit(control);
  }

}
