<ng-container *ngIf="tplKey === 'tplDescription'">
    <span *ngIf="data?.description && data?.description !== ''">{{data?.description}}</span>
    <span *ngIf="!data?.description || data?.description === ''">
        {{'quality-controls.columns.no-description' | translate}}
    </span>
</ng-container>
<ng-container *ngIf="tplKey === 'tplScheduling'">
    <ts-tree-list-cell *ngIf="data?.customSchedulingsLabels?.length == 1" [cellText]="data?.customSchedulingsLabels[0]"
                       [hyperlinkEnabled]="false" [buttonIsVisible]="false"></ts-tree-list-cell>
    <ts-tree-list-cell *ngIf="data?.customSchedulingsLabels?.length > 1"
                       cellText="{{ 'orders-list.cells.devices' | translate:{ first: data?.customSchedulingsLabels[0], others: data?.customSchedulingsLabels.length - 1 } }}"
                       [hyperlinkEnabled]="false" [buttonIsVisible]="false"></ts-tree-list-cell>
    <span
          *ngIf="data?.customSchedulingsLabels?.length === 0 && (data.description === '' || data.description.includes('copy'))">
        {{'quality-controls.columns.none' | translate}}
    </span>
</ng-container>

<ng-container *ngIf="tplKey === 'tplDevices'">
    <ts-tree-list-cell *ngIf="data?.Devices?.length == 1" [cellText]="data?.Devices[0].label" [hyperlinkEnabled]="false"
                       [buttonIsVisible]="false"></ts-tree-list-cell>
    <ts-tree-list-cell *ngIf="data?.Devices?.length > 1"
                       cellText="{{ 'orders-list.cells.devices' | translate:{ first: data?.Devices[0].label, others: data?.Devices?.length - 1 } }}"
                       [hyperlinkEnabled]="false" [buttonIsVisible]="false"></ts-tree-list-cell>
    <span *ngIf="data?.Devices?.length === 0 && (data.description === '' || data.description.includes('copy'))">
        {{'quality-controls.columns.none' | translate}}
    </span>
    <span *ngIf="data?.Devices?.length === 0 && data.description !== '' && !data.description.includes('copy')">
        {{'control.all-devices' | translate}}
    </span>
</ng-container>

<ng-container *ngIf="tplKey === 'tplProducts'">
    <ts-tree-list-cell *ngIf="data?.Products?.length == 1" [cellText]="data?.Products[0].label"
                       [hyperlinkEnabled]="false" [buttonIsVisible]="false"></ts-tree-list-cell>
    <ts-tree-list-cell *ngIf="data?.Products?.length > 1"
                       cellText="{{ 'orders-list.cells.devices' | translate:{ first: data?.Products[0].label, others: data?.Products?.length - 1 } }}"
                       [hyperlinkEnabled]="false" [buttonIsVisible]="false"></ts-tree-list-cell>
    <span *ngIf="data?.Products?.length === 0 && (data.description === '' || data.description.includes('copy'))">
        {{'quality-controls.columns.none' | translate}}
    </span>
    <span *ngIf="data?.Products?.length === 0 && data.description !== '' && !data.description.includes('copy')">
        {{'control.all-products' | translate}}
    </span>
</ng-container>

<ng-container *ngIf="tplKey === 'tplTasks'">
    <ts-tree-list-cell *ngIf="data?.Tasks?.length == 1" [cellText]="data?.Tasks[0].description"
                       [hyperlinkEnabled]="false" [buttonIsVisible]="false"></ts-tree-list-cell>
    <ts-tree-list-cell *ngIf="data?.Tasks?.length > 1"
                       cellText="{{ 'orders-list.cells.devices' | translate:{ first: data?.Tasks[0].description, others: data?.Tasks?.length - 1 } }}"
                       [hyperlinkEnabled]="false" [buttonIsVisible]="false"></ts-tree-list-cell>
    <span *ngIf="data?.Tasks?.length < 1">
        {{'quality-controls.columns.none' | translate}}
    </span>
</ng-container>

<span *ngIf="tplKey === 'tplActions'">
    <ts-tree-list-button icon="window-maximize" title="Info" (click)="qualityControlInfo(data)"></ts-tree-list-button>
    <ts-tree-list-button icon="copy" title="Duplicate" (click)="duplicateQualityControl(data.id)"></ts-tree-list-button>
    <ts-tree-list-button icon="pencil" title="Edit" (click)="editQualityControl(data)"></ts-tree-list-button>
    <ts-tree-list-button icon="trash-alt" [isDanger]="true" title="Delete"
                         (click)="deleteQualityControl(data.id)"></ts-tree-list-button>
</span>