import { Component, Input, OnInit } from '@angular/core';
import { ControlIstance } from '../../../models/control.model';

@Component({
  selector: 'app-quality-control-drawer',
  templateUrl: './quality-control-drawer.component.html',
  styleUrls: ['./quality-control-drawer.component.scss']
})
export class QualityControlDrawerComponent implements OnInit {

  @Input() control: ControlIstance;

  constructor() { }

  ngOnInit(): void {
  }

}
