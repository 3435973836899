import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PluginInstance } from '../../../models/plugin.model';

@Component({
  selector: 'app-plugin-drawer',
  templateUrl: './plugin-drawer.component.html',
  styleUrls: ['./plugin-drawer.component.scss']
})
export class PluginDrawerComponent implements OnInit {

  @Input() plugin?: PluginInstance;
  form: FormGroup;

  selectedEnabled: { id: number, val: string };
  selectedSatellite: { id: number, val: string };

  customValidationDict = {
    required: 'common.inputErrors.required',
    min: 'common.inputErrors.min'
  };

  booleanSelectData: { id: number, val: string }[] = [
    { id: 0, val: 'NO' },
    { id: 1, val: 'YES' }
  ]

  constructor(private readonly _fb: FormBuilder) {
    this.form = this._fb.group({
      code: [null, [Validators.required]],
      description: [null, [Validators.required]],
      enabled: [false],
      isOnSatellite: [false],
    })
  }

  get formValid(): boolean {
    return this.form.valid;
  }

  ngOnInit(): void {
    this.selectedEnabled = this.booleanSelectData[0];
    this.selectedSatellite = this.booleanSelectData[0];

    if (this.plugin) {
      this.form.reset();
      this.updateForm(this.plugin);
    }
  }

  onSubmit() {
    const data = {
      ...this.form.value,
      enabled: this.form.value.enabled.id === 1 ? true : false,
      isOnSatellite: this.form.value.isOnSatellite.id === 1 ? true : false,
    }

    if (this.plugin) {
      data['id'] = this.plugin.id
    }

    return data;
  }


  private updateForm(plugin: PluginInstance) {
    this.form.patchValue({
      code: plugin.code,
      description: plugin.description,
      enabled: plugin.PluginsDevices.enabled,
      isOnSatellite: plugin.PluginsDevices.isOnSatellite,
    })

    this.selectedEnabled = this.booleanSelectData.find(x => x.id === (plugin.PluginsDevices.enabled ? 1 : 0));
    this.selectedSatellite = this.booleanSelectData.find(x => x.id === (plugin.PluginsDevices.isOnSatellite ? 1 : 0));

  }

}
