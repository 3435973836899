import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation, Inject, LOCALE_ID, ElementRef } from '@angular/core';

import { DeviceService } from '../../../services/device.service';
import { UiService } from '../../../services/ui.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';

import { UnipiInstance } from '../../../models/unipi.model';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { Drawer, FontService, OverlaySpinnerService } from '@vapor/angular-ui';

import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { DrawerInstanceInfo } from '@vapor/angular-ui/drawer/drawer-data.model';
import { NewDeviceDrawerComponent } from '../../ui/new-device-drawer/new-device-drawer.component';

type DeviceType = 'new' | 'thirdparty' | 'virtual';

@Component({
  selector: 'app-new-device',
  templateUrl: './new-device.component.html',
  styleUrls: ['./new-device.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewDeviceComponent implements OnInit {
  unipis: UnipiInstance[];
  columns: TsTreeListColumn[] = [];
  deviceType: DeviceType;
  selectedUnipi: number;
  devicesListHeight = '100vh';


  // @ViewChild('label', { static: true }) labelTemplate: TemplateRef<any>;
  // @ViewChild('date', { static: true }) dateTemplate: TemplateRef<any>;
  // @ViewChild('devicesTemplate') devicesTemplate: TemplateRef<any>;
  // @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('devicesList', { static: false, read: ElementRef }) devicesListRef!: ElementRef;
  @ViewChild('newDeviceTemplate', { static: true }) newDeviceTemplate: TemplateRef<any>;
  @ViewChild('newThirdPartyDeviceTemplate', { static: true }) newThirdPartyDeviceTemplate: TemplateRef<any>;
  @ViewChild('newVirtualDeviceTemplate', { static: true }) newVirtualDeviceTemplate: TemplateRef<any>;
  @ViewChild('devicesDrawerBottomTemplate', { static: false }) devicesDrawerBottomTemplate: TemplateRef<any>;
  @ViewChild('devicesDrawer') devicesDrawer: NewDeviceDrawerComponent;

  constructor(
    private _device: DeviceService,
    private _ui: UiService,
    private _navbar: NavbarService,
    private _sidebar: SidebarService,
    // private _dialog: MatDialog,
    private _drawer: Drawer,
    private _font: FontService,
    private _spinner: OverlaySpinnerService,
    private _translate: TranslateService
  ) {
    this._font.addIcon(faPlus)
  }

  async ngOnInit() {
    this._navbar.setTitle('Devices');
    setTimeout(() => this._sidebar.setSelected('new-device'));

    this._translate.stream([
      'devices.columns.id',
      'devices.columns.serial'
    ]).subscribe((translations) => {

      const defaultColumn: TsTreeListColumn = {
        dataField: '',
        headerPlaceholder: "Search",
        allowFiltering: true,
        allowEditing: false,
        allowSorting: true,
        width: '100%'
      };

      this.columns = [
        {
          // ID
          ...defaultColumn,
          dataField: 'id',
          caption: translations['devices.columns.id'],
          dataType: 'string',
        },
        {
          // Serial
          ...defaultColumn,
          dataField: 'serial',
          caption: translations['devices.columns.serial'],
          dataType: 'string',
        },
        {
          // Created At
          ...defaultColumn,
          dataField: 'createdAt',
          caption: translations['devices.columns.createdAt'],
          dataType: 'string',
        },
        { // actions 
          ...defaultColumn,
          caption: '',
          width: 135,
          allowFiltering: false,
          allowEditing: false,
          allowSorting: false,
          cellTemplate: 'tplActions',
          fixedPosition: 'right',
          alignment: 'center',
        },
      ]
    });
    this._spinner.show();
    await this.loadDevices();
    this._spinner.removeOverlay();
  }

  ngAfterContentChecked(): void {
    // We do it here because the ngSwitch has already been evaluated
    if (this.devicesListRef) {
      const offsetTop = this.devicesListRef.nativeElement.offsetTop;
      this.devicesListHeight = `calc(100vh - ${offsetTop}px)`;
    }
  }

  async loadDevices() {
    this.unipis = await this._device.getUnipis(false);
    this.unipis.map((value) => {
      value.createdAt = moment(value.createdAt).format('DD/MM/YYYY HH:mm');
    });
  }

  // async newDialog(unipi: UnipiInstance, index: number) {
  //   const ref = this._dialog.open(NewDeviceDialogComponent, {
  //     width: '480px',
  //     data: { unipiId: unipi.id }
  //   });

  //   ref.afterClosed().subscribe(async (result) => {
  //     if (result) {
  //       console.error(result);
  //       this._ui.openSnackBar('An error has occurred while creating a new device');
  //     } else {
  //       this.unipis = this.unipis.filter((item) => item.id !== unipi.id);
  //     }
  //   });
  // }

  // async openVirtualDeviceDialog() {
  //   const ref = this._dialog.open(NewVirtualDeviceDialogComponent, {
  //     width: '480px',
  //     data: {}
  //   });

  //   ref.afterClosed().subscribe((result) => {
  //     // console.log("After closed result", result)
  //     if (result) {
  //       console.error(result);
  //       this._ui.openSnackBar('An error has occurred while creating a new virtual device');
  //     } else {
  //       //TODO: Should redirect to Active devices after creation?
  //     }
  //   });
  // }

  // async openThirdPartyDeviceDialog() {
  //   const ref = this._dialog.open(NewThirdPartyDeviceDialogComponent, {
  //     width: '480px',
  //     data: {}
  //   });

  //   ref.afterClosed().subscribe((result) => {
  //     // console.log("After closed result", result)
  //     if (result) {
  //       console.error(result);
  //       this._ui.openSnackBar('An error has occurred while creating a new third party device');
  //     } else {
  //       //TODO: Should redirect to Active devices after creation?
  //     }
  //   });
  // }

  onActionClick(type: DeviceType, unipi?: UnipiInstance) {
    if (this._drawer.isOpened()) {
      this._drawer.close();
    }

    if (unipi) {
      this.selectedUnipi = unipi.id;
    }

    this.deviceType = type;

    // Open drawer
    this._drawer.open({
      title: "New Device",
      subTitle: '',
      expanded: false,
      marginTop: 0,
      marginBottom: 0,
      showPushButton: false,
      showOpenCloseButton: false,
      width: (300 * 2) + (16 * 2) + 32,
      contentTemplate: this.getContentTemplateAccordingToType(type),
      bottomBarTemplate: this.devicesDrawerBottomTemplate,
    }).subscribe((drawer: DrawerInstanceInfo) => {
      console.info(`New Device drawer open: ${drawer.drawerId}`);
      this._drawer.expand();
    });

  }

  async saveDevice() {
    const result = this.devicesDrawer.onSubmit();
    const device: any = result.device;

    const deviceData = {
      label: device.label
    }

    if (result.type === 'new') {
      deviceData['unipiId'] = device.unipi;
      deviceData['sessionType'] = device.sessionType === 3 ? 2 : device.sessionType === 4 ? 3 : device.sessionType;
    } else if (result.type === 'virtual') {
      deviceData['sessionType'] = device.sessionType;
      deviceData['isManual'] = true;
    } else {
      deviceData['sessionType'] = 1;
      deviceData['thirdPartyType'] = 1;
    }

    if (device.company.id === undefined && !device.company.name) {
      this._ui.openSnackBar('Missing company data');
      return;
    }

    if (device.__companyType === 'existing') {
      deviceData['companyId'] = device.company.id;
      deviceData['plantId'] = device.plant ? device.plant.id : device.company.Plants[0].id;
    } else {
      deviceData['company'] = {
        name: device.company.name,
        provider: device.provider
      };
    }

    if (result.type === 'new') {
      this.unipis = this.unipis.filter((item) => item.id !== device.unipi);
      await this._device.createDevice(deviceData)
    } else if (result.type === 'virtual') {
      await this._device.createDevice(deviceData);
    } else {
      await this._device.createThirdPartyDevice(deviceData);
    }

    this._drawer.close();
    this._ui.openSnackBar('Device created successfully');

  }

  onCancelDrawerClicked() {
    this._drawer.close();
  }

  private getContentTemplateAccordingToType(type: DeviceType) {
    if (type === 'new') {
      return this.newDeviceTemplate;
    } else if (type === 'thirdparty') {
      return this.newThirdPartyDeviceTemplate;
    } else {
      return this.newVirtualDeviceTemplate;
    }
  }
}
