import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OperatorInstance } from '../../../models/operator.model';

@Component({
  selector: 'app-operators-list-cell',
  templateUrl: './operators-list-cell.component.html',
  styleUrls: ['./operators-list-cell.component.scss']
})
export class OperatorsListCellComponent implements OnInit {

  @Input() tplKey: any;
  @Input() data: any;

  @Output() operatorInfoClicked = new EventEmitter<OperatorInstance>();
  @Output() deleteOperatorClicked = new EventEmitter<number>();
  @Output() editOperatorClicked = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  operatorInfo(operator: OperatorInstance) {
    this.operatorInfoClicked.emit(operator);
  }

  deleteOperator(operatorId: number) {
    this.deleteOperatorClicked.emit(operatorId);
  }

  editOperator(operatorId: number) {
    this.editOperatorClicked.emit(operatorId);
  }

}
