import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserInstance } from '../../../models/user.model';
import { PlantInstance } from '../../../models/plant.model';
import { TranslateService } from '@ngx-translate/core';

type SelectType = { id: number; val: string };

@Component({
  selector: 'app-user-drawer',
  templateUrl: './user-drawer.component.html',
  styleUrls: ['./user-drawer.component.scss']
})
export class UserDrawerComponent implements OnInit {

  @Input() user?: UserInstance;
  @Input() plants: PlantInstance[];

  tmpPlants: SelectType[];
  roles: SelectType[] = [{ id: 1, val: 'Supervisor' }, { id: 3, val: 'Manager' }];

  selectedPlant: SelectType;
  selectedRole: any;

  form: FormGroup;

  customValidationDict = {
    required: 'common.inputErrors.required',
    min: 'common.inputErrors.min'
  };

  constructor(private readonly _fb: FormBuilder, private _translate: TranslateService) {
    this.form = this._fb.group({
      id: [null],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      role: [null, [Validators.required]],
      plant: [null, [Validators.required]]
    })
  }

  get f() {
    return this.form.controls;
  }

  get formValid(): boolean {
    return this.form.valid;
  }

  ngOnInit(): void {
    this.updatePlants();
    if (this.user) {
      this.form.reset();
      this.updateUserRole();
      this.updateForm(this.user);
    }
  }

  onSubmit() {
    if (!this.formValid) {
      return
    }

    const _user = {
      ...this.form.value,
      role: this.form.value.role.id || this.form.value.role,
      plant: { id: this.form.value.plant.id }
    };

    if (this.user) {
      _user['id'] = this.user.id;
    }

    return _user;
  }

  handlePlantChange(plant: { id: number, val: string }) {
    this.selectedPlant = plant;
  }

  private updateUserRole() {
    this.selectedRole = this.roles.find(role => role.id === this.user.role);
  }

  private updatePlants() {
    this.tmpPlants = [
      { id: -1, val: this._translate.instant('companies.all-plants') },
      ...this.plants.map(plant => ({ id: plant.id, val: plant.description }))
    ];

    this.selectedPlant = this.user?.plant
      ? this.tmpPlants.find(plant => plant.id === this.user.plant.id)
      : this.tmpPlants[0];
  }

  private updateForm(user?: UserInstance) {
    this.form.patchValue({
      id: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      role: user?.role,
      plant: this.selectedPlant
    });
  }
}
