import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ErrorInstance } from '../../../models/error.model';
import { DelayInstance } from '../../../models/delay.model';

@Component({
  selector: 'app-errors-list-cell',
  templateUrl: './errors-list-cell.component.html',
  styleUrls: ['./errors-list-cell.component.scss']
})
export class ErrorsListCellComponent implements OnInit {
  @Input() tplKey: any;
  @Input() data: any;
  @Input() priorityErrorId?: number;
  @Input() isDelay?: boolean;

  @Output() editErrorClicked = new EventEmitter<number>();
  @Output() deleteErrorClicked = new EventEmitter<ErrorInstance | DelayInstance>();
  @Output() priorityClicked = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  editError(errorID: number) {
    this.editErrorClicked.emit(errorID);
  }

  deleteError(data: ErrorInstance | DelayInstance) {
    this.deleteErrorClicked.emit(data);
  }

  onPriorityChange(errorId: number, event: any) {
    this.priorityClicked.emit(event ? errorId : 0);
  }

}
